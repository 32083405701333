import React from 'react';
import pptxgen from 'pptxgenjs';
import moment from 'moment';
import colorStar from '../../../assets/images/Picture1.png';
import { findValueView } from '../../../helpers/mediaChoiceTable_helpers';
import { findFlightByPeriod } from '../../../helpers/flightTable_helpers';

export const ExportPPTFlow = React.memo(props => {
  const { selectedMediaAllFlightsWithCurrentNumber, currentMediaNumberArr, flightsMediaArr, flightsChartsData, flightsPeriod, flightsFrequencyArr, flightsCampaignTypesArr, flightArr } = props;

  let pptx = new pptxgen();
  let slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  let arrRows = [];
  let flightsRows = [];

  arrRows.push([
    { text: 'Группа', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true } },
    { text: 'Медиа', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true } },
    { text: 'Янв.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Фев.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Мар.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Апр.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Май', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Июн.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Июл.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Авг.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Сен.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Окт.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Ноя.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Дек.', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true, colspan: 4 } },
    { text: 'Бюджет', options: { fill: '767171', color: 'ffffff', valign: 'middle', fontSize: 9, bold: true } },
  ]);

  selectedMediaAllFlightsWithCurrentNumber &&
    selectedMediaAllFlightsWithCurrentNumber.map((item, i) => {
      const findBudgetValueByMonth = media => {
        const monthBudgetsArr = [];
        flightsChartsData.map(chartData => {
          const indDon = chartData.donutData.labels.indexOf(media);
          const valBudg = indDon > -1 ? chartData.donutData.datasets[0].data[indDon] : null;
          monthBudgetsArr.push(valBudg);

          return chartData;
        });

        const sumMonthBudgetsArr = monthBudgetsArr.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0);
        return `${findValueView(sumMonthBudgetsArr, 'roundBudget')} ₽\n ${media}`;
      };

      const findMonthValue = (media, month) => {
        const flightsAndPeriodsArr = findFlightByPeriod(flightsPeriod, flightsMediaArr, flightArr);

        const findFlightArr = [];
        flightsAndPeriodsArr
          .sort((a, b) => a.currentFlightNumber - b.currentFlightNumber)
          .map(f => {
            const currentMediaNumber = currentMediaNumberArr.find(fl => fl.currentFlightNumber === f.currentFlightNumber && fl.media === media) && currentMediaNumberArr.find(fl => fl.currentFlightNumber === f.currentFlightNumber && fl.media === media).currentNumber;
            const findMonth = f.monthArr.find(ma => ma === month);
            const findMedia = f.mediaArr.find(me => me.media === media);
            findMonth && findMedia ? findFlightArr.splice(currentMediaNumber - 1, 0, f.flight) : findFlightArr.push(0);
            return f;
          });
        const currentValueArr = findFlightArr.map(flight => {
          if (flight === 0) {
            return '';
          } else {
            const currentFlightNumber = flightArr.find(fl => fl.id === flight) && flightArr.find(fl => fl.id === flight).flight;
            const currentFrequency = flightsFrequencyArr.find(frequency => frequency.flight === flight).frequency;
            const currentFlightChartsData = flightsChartsData.find(chartsData => chartsData.flight === flight);
            const indContr = currentFlightChartsData && currentFlightChartsData.contributionOfEachMedia_data.labels.indexOf(media);
            const valReach = indContr > -1 ? currentFlightChartsData && currentFlightChartsData.contributionOfEachMedia_data.datasets[0].data[indContr] : null;
            const indDon = currentFlightChartsData && currentFlightChartsData.donutData.labels.indexOf(media);
            const valBudg = indDon > -1 ? currentFlightChartsData && currentFlightChartsData.donutData.datasets[0].data[indDon] : null;
            return findFlightArr.length !== 0 && `ФЛАЙТ ${currentFlightNumber}\n  Reach ${findValueView(valReach, 'roundCoverage')} %,\n @f ${currentFrequency},\n ${findValueView(valBudg, 'roundBudget')} ₽`;
          }
        });
        // const findCurrentValue = currentValueArr.find(cv=> cv.indexOf(`ФЛАЙТ ${item.number}`)>-1)
        // return findCurrentValue;
        return currentValueArr[item.currentNumber - 1];
      };

      const arr = [
        { text: `${item.group} `, options: { fontSize: 6, rowspan: 1 } },
        { text: item.media, options: { fill: 'e3e9ec', fontSize: 6, rowspan: 1 } },
        { text: findMonthValue(item.media, 'Январь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Январь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Январь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Январь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Февраль1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Февраль2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Февраль3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Февраль4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Март1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Март2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Март3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Март4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Апрель1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Апрель2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Апрель3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Апрель4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Май1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Май2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Май3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Май4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Июнь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июнь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июнь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июнь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Июль1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июль2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июль3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Июль4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Август1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Август2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Август3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Август4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Сентябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Сентябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Сентябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Сентябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Октябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Октябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Октябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Октябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Ноябрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Ноябрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Ноябрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Ноябрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findMonthValue(item.media, 'Декабрь1'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Декабрь2'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Декабрь3'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },
        { text: findMonthValue(item.media, 'Декабрь4'), options: { colspan: 1, fontSize: 6, fill: 'ffffff' } },

        { text: findBudgetValueByMonth(item.media), options: { colspan: 1, fill: '738fa2', fontSize: 6, rowspan: 1 } },
      ];

      const findnewArr = () => {
        let newArr = [...arr];
        const rowWithZeroBudget = arr.find(a => a.text && a.text.indexOf(',\n 0 ₽') > -1);

        for (var b = 0; b < arr.length; b++) {
          if (arr[b].text && arr.filter(l => l.text === arr[b].text).length > 1) {
            const r = arr[b].text !== '' && arr.filter(l => l.text === arr[b].text);
            arr.find(a => a.text === r[0].text).options.colspan = r.length;
            for (var c = 1; c < r.length; c++) {
              newArr = newArr.filter(ar => ar !== r[c]);
            }
          }
        }
        const emptyRowArr = [];
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].text && arr[n].text !== '') {
            arr[n].options.fill = 'd1e2ed';
          }
        }
        return [newArr, emptyRowArr, rowWithZeroBudget];
      };
      !findnewArr()[2] && arrRows.push(findnewArr()[0]);
      return item;
    });
  slide.addText(`Flight Media`, { x: '11%', y: '5%', w: 5.5, h: 0.75, fontSize: 14 });
  slide.addText(`Mix Calculator`, { x: '21%', y: '5%', w: 5.5, h: 0.75, color: '#d32f2f', fontSize: 14 });
  slide.addImage({ y: '8%', w: '100%', h: '8%', data: colorStar });
  slide.addText(`Флоу для спланированных флайтов на год`, { x: 2, y: -0.5, w: 9, h: 3.2, color: '#080808', fontSize: 23, bold: true });

  flightsRows.push(
    flightArr.map((flight, i) => {
      const campaignType = flightsCampaignTypesArr.find(item => item.flight === flight.id) && flightsCampaignTypesArr.find(item => item.flight === flight.id).campaignType;
      const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
      const startDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).startDate;
      const finishDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).finishDate;
      const frequency = flightsFrequencyArr.find(item => item.flight === flight.id) && flightsFrequencyArr.find(item => item.flight === flight.id).frequency;
      const budgetPOST =
        flightsChartsData.find(item => item.flight === flight.id) &&
        flightsChartsData
          .find(item => item.flight === flight.id)
          .donutData.datasets[0].data.reduce(function (a, b) {
            return +a + +b;
          });
      const coveragePOST = flightsChartsData.find(item => item.flight === flight.id) && flightsChartsData.find(item => item.flight === flight.id).optic_media_mix_graph_data.datasets[0].data[4];

      return {
        text: `
    ФЛАЙТ ${flight.flight}
    ${moment.utc(startDate).format('DD.MM')}-${moment.utc(finishDate).format('DD.MM')}
    ${campaignTypePOST}
    Reach ${findValueView(coveragePOST, 'roundCoverage')} % @f ${frequency}
    ${findValueView(budgetPOST, 'roundBudget')} ₽
    `,
        options: { valign: 'middle', fontSize: 8, bold: true },
      };
    })
  );

  slide.addTable(flightsRows, {
    x: 0.5,
    y: 1.5,
    w: 9,
    border: { color: 'CFCFCF' },
    autoPage: true,
    autoPageRepeatHeader: true,
    verbose: false,
  });

  slide.addTable(arrRows, {
    x: 0.5,
    y: 2.5,
    w: 9,
    colW: [0.5, 1.1, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.7],
    border: { color: 'CFCFCF' },
    autoPage: true,
    autoPageRepeatHeader: true,
    autoPageLineWeight: 1.0,
    autoPageCharWeight: -1.5,
    verbose: true,
  });

  pptx.writeFile({ fileName: 'media_mix_calculator_flow.pptx' });

  return <div></div>;
});
