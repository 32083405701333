import React from 'react';
import { useSelector } from 'react-redux';
import pptxgen from 'pptxgenjs';
import { findMaxMinOhvat, round, findName } from '../../../helpers/chart_helpers';
import colorStar from '../../../assets/images/Picture1.png';
import grayBackground from '../../../assets/images/grayBackground.jpg';
import logoStar from '../../../assets/images/logoStar.png';
import frame from '../../../assets/images/frame.png';

export const ExportPPTFlights = React.memo(props => {
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);

  let pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    objects: [{ text: { text: 'Flight Media', options: { x: '11%', y: '7%', w: 5.5, h: 0.75 } } }, { text: { text: 'Mix Calculator', options: { x: '21%', y: '7%', w: 5.5, h: 0.75, color: '#d32f2f' } } }, { image: { y: '8%', w: '100%', h: '8%', data: colorStar } }, { text: { text: 'Источник: https://mediamix.mediainstinctgroup.ru', options: { x: 1, y: '90%', w: 5.5, h: 0.75, fontSize: 11 } } }],
    slideNumber: { x: '94%', y: '9%' },
  });
  let slide = pptx.addSlide({ masterName: 'MASTER' });
  slide.background = { path: grayBackground };
  slide.addImage({ path: logoStar, x: 1, y: 1, w: '16%', h: '11%' });
  slide.addText('Результат планирования', { x: '10%', y: '30%', w: '100%', h: 3.2, color: '#e10500', fontSize: 70, bold: true });
  slide.addText('Flight Media', { x: '45%', y: '60%', w: 6, h: 3.2, color: '#080808', fontSize: 40 });
  slide.addText('Mix Calculator', { x: '67%', y: '60%', w: 6, h: 3.2, color: '#d32f2f', fontSize: 40 });

  flightsChartsData.map(item => {
    const FLIGHT = flightArr.find(i => i.id === item.flight) && flightArr.find(i => i.id === item.flight).flight;
    const FREQUENCY = flightsFrequencyArr.find(i => i.flight === item.flight) && flightsFrequencyArr.find(i => i.flight === item.flight).frequency;
    const donutData = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).donutData;
    const contributionOfEachMedia_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).contributionOfEachMedia_data;
    const optic_media_mix_graph_data = flightsChartsData.find(i => i.flight === item.flight) && flightsChartsData.find(i => i.flight === item.flight).optic_media_mix_graph_data;
    const optic_reachArr = optic_media_mix_graph_data.datasets && optic_media_mix_graph_data.datasets[0].data;
    const maxValueOpt = donutData.datasets[0].data.reduce((a, b) => +a + +b);

    const colorsArray = item.colorsArray;
    const donutAllColorsArray = donutData.labels.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });
    const contrAllColorsArray = contributionOfEachMedia_data.labels.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const opticAllColorsArray = optic_media_mix_graph_data.datasets.filter(item => item.type === 'bar').map(i => i.backgroundColor);

    let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    const dataChartPieStat = [
      {
        name: 'Pie',
        labels: donutData.labels,
        values: donutData.datasets[0].data,
      },
    ];

    slide1.addText(`Распределение бюджета по медиа (Флайт ${FLIGHT})`, { x: 3.5, y: 0, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
    let optsPie = {
      x: 2.5,
      y: 2,
      w: '60%',
      h: '60%',
      legendFontFace: 'Roboto',
      legendFontSize: 16,
      legendPos: 'b',
      showLegend: true,
      showLeaderLines: true,
      legendColor: '808080',
      showPercent: false,
      showValue: true,
      chartColors: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
      dataBorder: { pt: '1', color: 'F1F1F1' },
      dataLabelColor: 'FFFFFF',
      dataLabelFontSize: 12,
      dataLabelFontBold: true,
      dataLabelFormatCode: '#,##0 ₽',
      dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
    };
    slide1.addChart(pptx.charts.DOUGHNUT, dataChartPieStat, optsPie);

    let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    slide2.addText(`Вклад каждого медиа в совокупный охват @f=${FREQUENCY}`, { x: 3.5, y: 0, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
    slide2.addText(`(Флайт ${FLIGHT})`, { x: '50%', y: 0.5, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
    let optsChartBar1 = {
      x: 1.5,
      y: 2.5,
      w: '80%',
      h: '50%',
      catGridLine: { style: 'none' },
      valGridLine: { style: 'none' },
      legendFontSize: 16,
      barDir: 'bar',
      catAxisOrientation: 'maxMin',
      catAxisLabelColor: '808080',
      barGrouping: 'stacked',
      dataLabelColor: 'FFFFFF',
      showValue: true,
      chartColors: contrAllColorsArray.map(i => Object.values(Object.values(i))[0]).map(i => i.substring(1, 7)),
      valAxisLabelColor: '808080',
      dataLabelFormatCode: `0.00 %`,
      dataLabelFontBold: true,

      showValAxisTitle: true,
      valAxisTitle: '% охвата',
      valAxisTitleFontSize: 14,
      valAxisTitleAlign: 'top',

      showCatAxisTitle: true,
      catAxisTitle: 'Медиа',
      catAxisTitleFontSize: 14,
    };
    let single = [
      {
        name: 'Conrtibution',
        labels: contributionOfEachMedia_data.labels,
        values: contributionOfEachMedia_data.datasets[0].data.map(item => item / 100),
      },
    ];

    slide2.addChart(pptx.charts.BAR, single, optsChartBar1);

    let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
    slide3.addText(`Сравнение различных медиамиксов на идентичный бюджет и их влияние на совокупный охват (Флайт ${FLIGHT})`, { x: 2.5, y: 0, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
    function doStackedLine() {
      let opts = {
        x: 1.5,
        y: 2.5,
        w: '80%',
        h: '50%',
        legendFontSize: 16,
        barDir: 'col',
        catGridLine: { style: 'none' },
        valGridLine: { style: 'none' },
        barGrouping: 'stacked',
        chartColors: opticAllColorsArray,
        catAxisLabelColor: '808080',
        catAxisLabelFontFace: 'Roboto',
        catAxisLabelFontSize: 12,
        legendPos: 'b',
        legendFontFace: 'Roboto',
        legendColor: '808080',
        showLegend: true,
        showValue: true,
        dataLabelFormatCode: '0.00;;;',
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 12,
        valAxes: [
          {
            showValAxisTitle: true,
            valAxisTitle: 'Доля в медиамиксе (млн. руб)',
            valAxisTitleFontSize: 12,
            valAxisMaxVal: maxValueOpt,
            valAxisMinVal: 0,
            valAxisDisplayUnit: 'millions',
          },
          {
            showValAxisTitle: true,
            valAxisTitle: 'Совокупный охват (%)',
            valAxisTitleFontSize: 12,
            catAxisOrientation: 'maxMin',
            valAxisMajorUnit: 1,
            valAxisMaxVal: round(findMaxMinOhvat(optic_reachArr)[1]),
            valAxisMinVal: round(findMaxMinOhvat(optic_reachArr)[0]),
            valGridLine: { style: 'none' },
          },
        ],

        catAxes: [
          {
            catAxisTitle: '',
          },
          {
            catAxisHidden: true,
          },
        ],
      };

      const opticDataWithoutReach = optic_media_mix_graph_data.datasets.filter(data => data.type === 'bar');

      let chartTypes = [
        {
          type: pptx.charts.BAR,
          data: opticDataWithoutReach.map(item => ({
            name: item.label,
            labels: optic_media_mix_graph_data.labels,
            values: item.data,
          })),
        },
        {
          type: pptx.charts.LINE,
          data: [
            {
              name: 'Охват',
              labels: optic_media_mix_graph_data.labels,
              values: optic_media_mix_graph_data.datasets.find(data => data.type === 'line').data,
            },
          ],
          options: {
            barGrouping: 'standard',
            chartColors: ['#029E4E'],
            secondaryValAxis: !!opts.valAxes,
            secondaryCatAxis: !!opts.catAxes,
          },
        },
      ];
      slide3.addChart(chartTypes, opts);
      slide3.addImage({ path: frame, x: '49.7%', y: 2.5, w: '7%', h: '44%' });
    }
    doStackedLine();
    return item;
  });
  pptx.writeFile({ fileName: 'media_mix_calculator_charts_part1.pptx' });

  return <div></div>;
});
