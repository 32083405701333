import React, { useState, useEffect } from 'react';
import s from './Select.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const Select = React.memo(props => {
  const { items, value, onChange, location, question, info, name, width, isDisabled } = props;

  const [selectListVisible, setSelectListVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(value);
  const [selectInfoCardVisible, setselectInfoCardVisible] = useState(false);
  const onClickSelect = () => {
    setSelectListVisible(!selectListVisible);
  };
  const onClickFlightsSelect = () => {
    setSelectListVisible(true);
  };
  const closeSelect = () => {
    setSelectListVisible(false);
  };
  const findSelectClassName = () => {
    if (location === 'targetAudience' && !isDisabled) {
      return `${s.select__head} ${s.forTargetAudience}`;
    } else if (location === 'targetAudience' && isDisabled) {
      return `${s.select__head} ${s.forTargetAudience} ${s.disabled}`;
    } else if (location === 'contributionChart') {
      return `${s.select__head} ${s.forContributionChart}`;
    } else if (location === 'coeff') {
      return `${s.select__head} ${s.forCoeff}`;
    } else if (location === 'severalFlights' && isDisabled && value === '') {
      return `${s.select__head_Red} ${s.severalFlights} ${s.disabled}`;
    } else if (location === 'severalFlights' && !isDisabled && value === '') {
      return `${s.select__head_Red} ${s.severalFlights}`;
    } else if (location === 'severalFlights' && isDisabled && value !== '') {
      return `${s.select__head_Green} ${s.severalFlights} ${s.disabled}`;
    } else if (location === 'severalFlights' && !isDisabled && value !== '') {
      return `${s.select__head_Green} ${s.severalFlights}`;
    } else {
      return s.select__head;
    }
  };
  useEffect(() => {
    setSelectedCategories(value);
  }, [value]);
  return (
    <div className={s.select}>
      <input className={s.select__input} type='hidden' name={name} />
      <div className={findSelectClassName()} onClick={location === 'severalFlights' ? onClickFlightsSelect : onClickSelect} style={{ width: width }}>
        {location === 'severalFlights' ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div style={{ width: '90%', lineHeight: '1' }}>{selectedCategories}</div>
            {selectListVisible ? null : <FontAwesomeIcon icon={faSortDown} size='2x' style={{ color: '#c4c4c4', marginTop: '-15px' }} />}
          </div>
        ) : (
          <div>
            {selectListVisible ? null : <FontAwesomeIcon icon={faSortDown} size='2x' style={selectedCategories !== '' ? { display: 'none' } : { color: '#c4c4c4' }} />}
            {selectedCategories}
          </div>
        )}

        {question && (
          <span
            className={s.questionIconWrapper}
            onMouseEnter={() => {
              setselectInfoCardVisible(true);
            }}
            onMouseLeave={() => {
              setselectInfoCardVisible(false);
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} style={{ position: 'absolute', left: '1px', fontSize: '0.8rem', cursor: 'pointer' }} />
          </span>
        )}
        {selectInfoCardVisible && <div className={s.infoCard}>{info}</div>}
      </div>
      <ul className={s.select__list} onPointerLeave={location === 'severalFlights' ? closeSelect : () => {}} style={selectListVisible ? { display: 'inLine' } : { display: 'none' }}>
        {items.map((item, index) => (
          <li
            key={index}
            className={s.select__item}
            onClick={() => {
              setSelectedCategories(item);
              setSelectListVisible(false);
              onChange(item, name);
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
});
