import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './CheckedMedia.module.css';
import { EditableSpan } from '../../../Common/EditableSpan/EditableSpan';
import { setAllMedia, setInputDataChanged } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';
import { Warning } from '../../../Common/Warning/Warning';

export const CheckedMedia = React.memo(props => {
  const [warningActive, setWarningActive] = useState(false);
  const dispatch = useDispatch();
  const media = useSelector(state => state.input.media);
  const checkedMedia = media.filter(item => item.isChecked === true);

  const onChangeStartBudget = (newValue, mediaName) => {
    const updatedMediaState = media.map(item => {
      const newItem = { ...item };
      newItem.startBudget = newValue;
      return item.media === mediaName ? newItem : item;
    });
    dispatch(setAllMedia(updatedMediaState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  const onChangeFinishBudget = (newValue, mediaName) => {
    const updatedMediaState = media.map(item => {
      const newItem = { ...item };
      newItem.finishBudget = newValue;
      return item.media === mediaName ? newItem : item;
    });
    dispatch(setAllMedia(updatedMediaState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  useEffect(() => {
    const warning = media.find(item => (+item.startBudget > +item.finishBudget) || +item.finishBudget===0);
    warning && setWarningActive(true);
  }, [media]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitleStart}>МЕДИА</div>
      <div className={s.blockTitleMiddle}>ОТ</div>
      <div className={s.blockTitleEnd}>ДО</div>
      <div className={s.blockContent}>
        {checkedMedia &&
          checkedMedia.map(media => {
            return (
              <div className={s.item} key={media.media}>
                <div className={s.mediaName}>{media.media}</div>
                <div className={s.editableSpanWrapper}>
                  <EditableSpan value={media.startBudget} name={media.media} onChange={onChangeStartBudget} location={'checkedMedia_budgetСonstraints'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '38px' }}>
                  <EditableSpan value={media.finishBudget} name={media.media} onChange={onChangeFinishBudget} location={'checkedMedia_budgetСonstraints'} />
                </div>
                <Warning text={'Данные введены не корректно!'} warningVisible={warningActive} setWarningVisible={setWarningActive} />
              </div>
            );
          })}
      </div>
    </div>
  );
});
