import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Budget.module.css';
import { Checkbox } from '../../../Common/Checkbox/Checkbox';
import { setBudget_Coverage, setInputDataChanged } from '../../../../../bll/inputParameters_reducer';
import { EditableSpan } from '../../../Common/EditableSpan/EditableSpan';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const Budget = React.memo(props => {
  const { setBudgetIsSelected } = props;
  const dispatch = useDispatch();
  const budget_coverage = useSelector(state => state.input.budget_coverage);
  const handleOnChange = selectedName => {
    const updatedGeoState = budget_coverage.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = !selectedItem.isChecked;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    dispatch(setBudget_Coverage(updatedGeoState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };

  const someBudget_coverageSelected = budget_coverage.find(item => item.isChecked === true);

  const onChangeValue = (newValue, selectedName) => {
    const updatedState = budget_coverage.map(item => {
      const selectedItem = { ...item };
      selectedItem.value = newValue;
      const otherItem = { ...item };
      otherItem.value = 0;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    dispatch(setBudget_Coverage(updatedState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };

  useEffect(() => {
    someBudget_coverageSelected && someBudget_coverageSelected.value > 0 ? setBudgetIsSelected(true) : setBudgetIsSelected(false);
  }, [budget_coverage, setBudgetIsSelected, someBudget_coverageSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        РЕКОМЕНДАЦИИ
        <br /> ПОД:
      </div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('budget');
            }}
            checked={budget_coverage[0].isChecked}
            label={'Бюджет'}
            id={'budget'}
          />
          <div className={budget_coverage[0].isChecked ? `${s.budgetValueWrapper} ${s.visible}` : s.budgetValueWrapper}>
            <EditableSpan value={budget_coverage[0].value} name={budget_coverage[0].name} onChange={onChangeValue} view={'width100'} location={'checkedMedia'} />
          </div>
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('coverage');
            }}
            checked={budget_coverage[1].isChecked}
            label={'Охват'}
            id={'coverage'}
          />

          <div className={budget_coverage[1].isChecked ? `${s.coverageValueWrapper} ${s.visible}` : s.coverageValueWrapper}>
            <EditableSpan value={budget_coverage[1].value} name={budget_coverage[1].name} onChange={onChangeValue} view={'width100'} location={'checkedMedia'} question info={'% от целевой аудитории '} />
          </div>
        </div>
        {/* <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('different_budget');
            }}
            checked={budget_coverage[2].isChecked}
            label={'Разный бюджет'}
            id={'different_budget'}
          />
          <div className={budget_coverage[2].isChecked ? `${s.different_budgetValueWrapper} ${s.visible}` : s.different_budgetValueWrapper}>
            <EditableSpan value={budget_coverage[2].value} name={budget_coverage[2].name} onChange={onChangeValue} width100={true} />
          </div>
        </div> */}
      </div>
    </div>
  );
});
