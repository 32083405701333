import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsBudgetConstraints.module.css';
import { EditableSpan } from '../../Common/EditableSpan/EditableSpan';
import { Warning } from '../../Common/Warning/Warning';
import { setFlightsMediaArr, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsBudgetConstraints = React.memo(props => {
  const { flight } = props;
  const [warningActive, setWarningActive] = useState(false);
  const dispatch = useDispatch();

  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const currentFlightsMedia = flightsMediaArr.find(item => item.flight === flight) && flightsMediaArr.find(item => item.flight === flight).media;
  const checkedMedia = currentFlightsMedia.filter(item => item.isChecked === true);

  const onChangeStartBudget = (newValue, mediaName) => {
    const updatedMediaState = currentFlightsMedia.map(item => {
      const newItem = { ...item };
      newItem.startBudget = newValue;
      return item.media === mediaName ? newItem : item;
    });
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const newItem = { ...item };
      newItem.media = updatedMediaState;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };
  const onChangeFinishBudget = (newValue, mediaName) => {
    const updatedMediaState = currentFlightsMedia.map(item => {
      const newItem = { ...item };
      newItem.finishBudget = newValue;
      return item.media === mediaName ? newItem : item;
    });
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const newItem = { ...item };
      newItem.media = updatedMediaState;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };
  useEffect(() => {
    const warning = currentFlightsMedia.find(item => +item.startBudget > +item.finishBudget);
    warning && setWarningActive(true);
  }, [currentFlightsMedia]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitleStart}>МЕДИА</div>
      <div className={s.blockTitleMiddle}>ОТ</div>
      <div className={s.blockTitleEnd}>ДО</div>
      <div className={s.blockContent}>
        {checkedMedia &&
          checkedMedia.map(media => {
            return (
              <div className={s.item} key={media.media}>
                <div className={s.mediaName}>{media.media}</div>
                <div className={s.editableSpanWrapper}>
                  <EditableSpan value={media.startBudget} name={media.media} onChange={onChangeStartBudget} location={'checkedMedia_budgetСonstraints'} view={'width100'} />
                </div>
                <div className={s.editableSpanWrapper} style={{ marginLeft: '38px' }}>
                  <EditableSpan value={media.finishBudget} name={media.media} onChange={onChangeFinishBudget} location={'checkedMedia_budgetСonstraints'} view={'width100'} />
                </div>
                <Warning text={'Данные введены не корректно!'} warningVisible={warningActive} setWarningVisible={setWarningActive} />
              </div>
            );
          })}
      </div>
    </div>
  );
});
