import React, { useState } from 'react';
import s from './EditableSpanForFlights.module.css';
import { findValueView } from '../../../../helpers/mediaChoiceTable_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const EditableSpanForFlights = React.memo(props => {
  const { value, onChange, name, view, location, question, info, bold, isDisabled } = props;
  const [editMode, setEditMode] = useState(false);
  const [infoCardVisible, setInfoCardVisible] = useState(false);
  const [title, setTitle] = useState(value);
  const activateEditMode = () => {
    setEditMode(true);
    setTitle(value);
  };
  const disactivateEditMode = () => {
    setEditMode(false);
    onChange(title, name);
  };
  const onChangeHandler = (e, prev) => {
    setTitle(+e.currentTarget.value);
  };

  const findSpanClassName = () => {
    if (view === 'width100') {
      return isDisabled ? `${s.span100} ${s.disabled}` : s.span100;
    } else if (view === 'severalFlights' && value === 0) {
      return isDisabled ? `${s.span100Red} ${s.disabled} ${s.severalFlights}` : `${s.span100Red} ${s.severalFlights}`;
    } else if (view === 'severalFlights' && value !== 0) {
      return isDisabled ? `${s.span100Green} ${s.disabled} ${s.severalFlights}` : `${s.span100Green} ${s.severalFlights}`;
    } else {
      return isDisabled ? `${s.span} ${s.disabled}` : s.span;
    }
  };
  const findInputClassName = () => {
    if (view === 'width100') {
      return isDisabled ? `${s.input100} ${s.disabled}` : s.input100;
    } else if (view === 'severalFlights' && title === 0) {
      return isDisabled ? `${s.input100Red} ${s.disabled} ${s.severalFlights}` : `${s.input100Red} ${s.severalFlights}`;
    } else if (view === 'severalFlights' && title !== 0) {
      return isDisabled ? `${s.input100Green} ${s.disabled} ${s.severalFlights}` : `${s.input100Green} ${s.severalFlights}`;
    } else {
      return isDisabled ? `${s.input} ${s.disabled}` : s.input;
    }
  };
  return editMode ? (
    <div>
      {question && (
        <span
          className={s.questionIconWrapper}
          onMouseEnter={() => {
            setInfoCardVisible(true);
          }}
          onMouseLeave={() => {
            setInfoCardVisible(false);
          }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={{ position: 'absolute', left: '1px', fontSize: '0.8rem', cursor: 'pointer' }} />
        </span>
      )}
      <input name={name} autoFocus onFocus={e => e.currentTarget.select()} type='number' min='0' step='1' onChange={onChangeHandler} onBlur={disactivateEditMode} value={ title === 0 ? '' : title} className={findInputClassName()} />
      {infoCardVisible && <div className={s.infoCard}>{info}</div>}
    </div>
  ) : (
    <div>
      {question && (
        <span
          className={s.questionIconWrapper}
          onMouseEnter={() => {
            setInfoCardVisible(true);
          }}
          onMouseLeave={() => {
            setInfoCardVisible(false);
          }}
        >
          <FontAwesomeIcon icon={faQuestionCircle} style={{ position: 'absolute', left: '1px', fontSize: '0.8rem', cursor: 'pointer' }} />
        </span>
      )}
      <span style={bold === 'bold' ? { cursor: 'pointer', fontWeight: '600' } : { cursor: 'pointer' }} onClick={activateEditMode} className={findSpanClassName()}>
        {value === 0 ? '' : findValueView(value, location)}        
      </span>
      {infoCardVisible && <div className={s.infoCard}>{info}</div>}
    </div>
  );
});
