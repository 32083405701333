import React, { useState } from 'react';
import s from './Accordion.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

export const Accordion = React.memo(props => {
  const { flight, isDisabled, children, detailsPosition, selectValue } = props;
  const [isOpen, setOpen] = useState(false);
  const toggleItem = index => {
    setOpen(true);
  };
  const closeAccordion = e => {
    setOpen(false);
  };

  const findHeadClass = () => {
    if (isDisabled && selectValue === '') {
      return `${s.accordionHead} ${s.disabled}`;
    } else if (!isDisabled && selectValue === '') {
      return s.accordionHead;
    } else if (isDisabled && selectValue !== '') {
      return `${s.accordionHeadGreen} ${s.disabled}`;
    } else if (!isDisabled && selectValue !== '') {
      return s.accordionHeadGreen;
    }
  };

  return (
    <div className={s.accordionWrapper}>
      <div className={findHeadClass()} id={flight} onClick={toggleItem}>
        <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', width:'100%' }}>
          <div style={{ width:'90%', lineHeight:'1' }}>
          {selectValue}
          </div> 
          {isOpen ? null : <FontAwesomeIcon icon={faSortDown} size='2x' style={{ color: '#c4c4c4', marginTop: '-15px' }} />}
        </div>
      </div>
      {isOpen && (
        <div onPointerLeave={closeAccordion} className={detailsPosition === 'left' ? `${s.audienceAccordionDetails} ${s.left}` : s.audienceAccordionDetails}>
          <div className={s.audienceAccordionDetailsWrapper}>{children}</div>
        </div>
      )}
    </div>
  );
});
