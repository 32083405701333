import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsMediaChoiceTable.module.css';
import { EditableSpan } from '../Common/EditableSpan/EditableSpan';
import { makeGroupsNames, findFirstMediaInGroup, findQuestion } from '../../../helpers/mediaChoiceTable_helpers';
import { setFlightsMediaArr, setFlightsInputStep2DataChanged, setFlightsMonth } from '../../../bll/flights_reducer';

export const FlightsMediaChoiceTable = React.memo(props => {
  const { selectedMediaAllFlights } = props;
  const groups = makeGroupsNames(selectedMediaAllFlights);
  const firsMediaInGroupArr = findFirstMediaInGroup(groups, selectedMediaAllFlights);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);

  const dispatch = useDispatch();

  const onChangeSrt = (newValue, mediaName) => {
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedMediaState = item.media.map(el => {
        const newItem = { ...el };
        newItem.CPP = newValue;
        return el.media === mediaName ? newItem : el;
      });
      const newMediaArr = { ...item };
      newMediaArr.media = updatedMediaState;
      newMediaArr.copyStartMedia = updatedMediaState;

      return newMediaArr;
    });

    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep2DataChanged(true));
    dispatch(setFlightsMonth('Июнь'));
  };
  const onChangeContactQualityPromo = (newValue, mediaName) => {
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedMediaState = item.media.map(el => {
        const newItem = { ...el };
        newItem.coeff = newValue;
        return el.media === mediaName && el.campaignType === 'Промо' ? newItem : el;
      });
      const newMediaArr = { ...item };
      newMediaArr.media = updatedMediaState;
      newMediaArr.copyStartMedia = updatedMediaState;
      return newMediaArr;
    });
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep2DataChanged(true));
    dispatch(setFlightsMonth('Июнь'));
  };

  const onChangeContactQualityImage = (newValue, mediaName) => {
    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedMediaState = item.media.map(el => {
        const newItem = { ...el };
        newItem.coeff = newValue;
        return el.media === mediaName && el.campaignType === 'Имидж' ? newItem : el;
      });
      const newMediaArr = { ...item };
      newMediaArr.media = updatedMediaState;
      newMediaArr.copyStartMedia = updatedMediaState;
      return newMediaArr;
    });
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep2DataChanged(true));
    dispatch(setFlightsMonth('Июнь'));
  };
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitleStart}>ГРУППА</div>
      <div className={s.blockTitleGroup}>МЕДИА</div>
      <div className={s.blockTitleMiddle}>
         СРТ/СРМ<br />
        <span style={{ color:'black', fontSize:12 }}>(OOH,Radio,TV / Digital)</span>
      </div>
      <div className={s.blockTitleEnd}>
        КАЧЕСТВО КОНТАКТА <br />
        <span style={{ paddingRight: '110px' }}>ПРОМО</span>ИМИДЖ
      </div>

      <div className={s.blockContent}>
        {selectedMediaAllFlights &&
          selectedMediaAllFlights.map(media => {
            return (
              <div className={s.item} key={media.media}>
                <div className={s.checkboxWrapper}>
                  {firsMediaInGroupArr.find(mediaName => mediaName === media.media) ? <div className={s.groupCheckboxWrapper}>{media.group}</div> : <div className={s.groupCheckboxWrapper}></div>}
                  <div className={s.mediaCheckboxWrapper}>{media.media}</div>
                </div>
                <div className={s.editableSpanWrapper}>
                  <EditableSpan value={media.CPP} name={media.media} onChange={onChangeSrt} location={'mediaChoiceTable'} view={'width100'} question info={findQuestion(media.group)}/>
                </div>
                <div className={media.coeffPromo > 0 ? s.editableSpanWrapper : `${s.editableSpanWrapper} ${s.text_muted}`} style={{ marginLeft: '38px' }}>
                  <EditableSpan value={media.coeffPromo} name={media.media} onChange={onChangeContactQualityPromo} location={'mediaChoiceTableCoeff'} view={'width100'} question info={media.group === 'TV' ? 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов. 100% качества для ТВ сейчас взято для 20 секунд' : 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов'}/>
                </div>
                <div className={media.coeffImage > 0 ? s.editableSpanWrapper : `${s.editableSpanWrapper} ${s.text_muted}`} style={{ marginLeft: '38px' }}>
                  <EditableSpan value={media.coeffImage} name={media.media} onChange={onChangeContactQualityImage} location={'mediaChoiceTableCoeff'} view={'width100'} question info={media.group === 'TV' ? 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов. 100% качества для ТВ сейчас взято для 20 секунд' : 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов'}/>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});
