import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step1SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { FlightsTable } from '../../components/FlightsTable/FlightsTable';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Warning } from '../../components/Common/Warning/Warning';
import { FlightsTargetAudience } from '../../components/FlightsInputParam/FlightsTargetAudience/FlightsTargetAudience';
import { Checkbox } from '../../components/Common/Checkbox/Checkbox';
import { FlightsGeo } from '../../components/FlightsInputParam/FlightsGeo/FlightsGeo';
import { setRightFlightsSelectedMedia, setFlightsIsRestrictions, setFlightsInputStep1DataChanged, setFlightsisRestrictionsMediaBudget } from '../../../bll/flights_reducer';
import TA from '../../../assets/images/TA.png';
import GeoNew from '../../../assets/images/GeoNew.png';

export const Step1SeveralFlightsPage = React.memo(props => {
  const { targetAudienceData } = props;
  const [targetAudienceFlightsIsSelected, setTargetAudienceFlightsIsSelected] = useState(false);
  const [flightsGeoIsSelected, setFlightsGeoIsSelected] = useState(false);
  const [mediaChoiceTableWarningActive, setMediaChoiceTableWarningActive] = useState(false);
  const [budgetValueFlightsIsSelected, setBudgetValueFlightsIsSelected] = useState(false);
  const [mediaFlightsIsSelected, setMediaFlightsIsSelected] = useState(false);

  const flightsInputStep1DataChanged = useSelector(state => state.flights.flightsInputStep1DataChanged);
  const targetAudienceFlights = useSelector(state => state.flights.targetAudienceFlights);
  const flightsBudget_CoverageArr = useSelector(state => state.flights.flightsBudget_CoverageArr);
  const flightsGeo = useSelector(state => state.flights.flightsGeo);
  const someGeoSelected = flightsGeo.find(item => item.isChecked === true);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const flightsIsRestrictions = useSelector(state => state.flights.flightsIsRestrictions);
  const flightsisRestrictionsMediaBudget = useSelector(state => state.flights.flightsisRestrictionsMediaBudget);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const onClickNextButton = () => {
    history.push('/flightsStep2');
    flightsInputStep1DataChanged && dispatch(setRightFlightsSelectedMedia());
  };
  const onClickBackButton = () => {
    history.push('/step0');
  };
  const onChangeFlightsIsRestrictions = e => {
    e.target.checked ? dispatch(setFlightsIsRestrictions(1)) : dispatch(setFlightsIsRestrictions(0));
    dispatch(setFlightsInputStep1DataChanged(true));
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);
  useEffect(() => {
    targetAudienceFlights !== '' ? setTargetAudienceFlightsIsSelected(true) : setTargetAudienceFlightsIsSelected(false);
  }, [targetAudienceFlights]);
  useEffect(() => {
    someGeoSelected && someGeoSelected.isChecked ? setFlightsGeoIsSelected(true) : setFlightsGeoIsSelected(false);
  }, [setFlightsGeoIsSelected, someGeoSelected]);

  useEffect(() => {
    const someBudgetNotSelected = [];
    const someBudgetSelected_budgetValueNotSelected = [];
    flightsBudget_CoverageArr.map(item => {
      const itemBudgetNotSelected = item.budget_coverage.filter(i => i.isChecked === false);
      itemBudgetNotSelected.length === 3 && someBudgetNotSelected.push(item);
      const itemBudgetSelected_budgetValueNotSelected = item.budget_coverage.find(i => i.isChecked === true && i.value === 0);
      itemBudgetSelected_budgetValueNotSelected && someBudgetSelected_budgetValueNotSelected.push(item);
      return item;
    });
    someBudgetNotSelected.length !== 0 || someBudgetSelected_budgetValueNotSelected.length !== 0 ? setBudgetValueFlightsIsSelected(false) : setBudgetValueFlightsIsSelected(true);
  }, [flightsBudget_CoverageArr]);
  useEffect(() => {
    const someMediaNotSelected = [];
    flightsMediaArr.map(item => {
      !item.mediaIsSelected && someMediaNotSelected.push(item);
      return item;
    });
    someMediaNotSelected.length !== 0 ? setMediaFlightsIsSelected(false) : setMediaFlightsIsSelected(true);
  }, [flightsMediaArr]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  const onChangeFlightsisRestrictionsMediaBudget = e => {
    e.target.checked ? dispatch(setFlightsisRestrictionsMediaBudget(1)) : dispatch(setFlightsisRestrictionsMediaBudget(0));
    dispatch(setFlightsInputStep1DataChanged(true));
  };
  return (
    <>
      <Header />
      <div className={s.step2Wrapper}>
        <div className={s.wrapper}>
          <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
          <div className={s.mediaChoiceBlock}>
            <h1>Вводные параметры для флайтов</h1>
            <div className={s.generalInputDataBlock}>
              <div className={s.TABlock}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Целевая Аудитория</span>
                  <br />
                  для всех флайтов
                </div>
                <div>
                  <img src={TA} alt='TA' style={{ width: '55px', height: '55px' }} />
                </div>
                <FlightsTargetAudience targetAudienceFlights={targetAudienceFlights} targetAudienceData={targetAudienceData} />
              </div>

              <div className={s.geoBlock}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>ГЕО</span>
                  <br />
                  для всех флайтов
                </div>
                <div>
                  <img src={GeoNew} alt='TA' style={{ width: '50px', height: '50px' }} />
                </div>
                <FlightsGeo isDisabled={false} />
              </div>
            </div>
            <div className={s.checkBoxWrap}>
              <Checkbox
                onChangeChecked={e => {
                  onChangeFlightsIsRestrictions(e);
                }}
                checked={flightsIsRestrictions === 1 ? true : false}
                label={'Учитывать ограничения емкости инвентаря каждого медиаканала для выбранного количества дней'}
                id={'IsRestrictionsFlights'}
              />
              <Checkbox
                onChangeChecked={e => {
                  onChangeFlightsisRestrictionsMediaBudget(e);
                }}
                checked={flightsisRestrictionsMediaBudget === 1 ? true : false}
                label={'Использовать встроенные ограничения на минимально возможный бюджет.'}
                id={'flightsisRestrictionsMediaBudget'}
              />
            </div>
            <div className={s.table}>
              <FlightsTable targetAudienceFlightsIsSelected={targetAudienceFlightsIsSelected} flightsGeoIsSelected={flightsGeoIsSelected} />
            </div>
          </div>
          <StepButton onClick={onClickNextButton} title={'Следующий\n шаг'} isDisabled={budgetValueFlightsIsSelected && mediaFlightsIsSelected ? false : true} />
        </div>
        <Warning text={'Данные введены не корректно!'} warningVisible={mediaChoiceTableWarningActive} setWarningVisible={setMediaChoiceTableWarningActive} />
      </div>
    </>
  );
});
