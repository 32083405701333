import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step4Page.module.css';
import { Header } from '../../components/Header/Header';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { LoadingButton } from '../../components/Common/LoadingButton/LoadingButton';
import { useHistory, useLocation } from 'react-router-dom';
import { OpticMediaMixGraph } from '../../components/Charts/OpticMediaMixGraph/OpticMediaMixGraph';
import { changePieChart, changeContributionChart, changeOpticChart } from '../../../bll/charts_reducer';
import { ToggleSwitch } from '../../components/Common/ToggleSwitch/TiggleSwitch';
import { SaveXLSPart1 } from '../../components/SaveXLSPart1/SaveXLSPart1';
import { PieChart } from '../../components/Charts/PieChart/PieChart';
import { ContributionOfEachMedia } from '../../components/Charts/ContributionOfEachMedia/ContributionOfEachMedia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { Select } from '../../components/Common/Select/Select';
import { Checkbox } from '../../components/Common/Checkbox/Checkbox';
import { ExportPPTPart1 } from '../../components/ExportPPTPart1/ExportPPTPart1';
import { ExportXLSPart1 } from '../../components/ExportXLSPart1/ExportXLSPart1';
import { setUnitDonut, setUnitOptic, setTs_pieChart_unit_checked, setTs_pieChart_grouping_checked, setTs_contribution_chart_unit_checked, setTs_contribution_chart_grouping_checked, setTs_optic_media_mix_chart_unit_checked, setTs_optic_media_mix_chart_grouping_checked, setKnowledgeGeneralCategory, setSeleb, getKnowledge } from '../../../bll/charts_reducer';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { setIsGrowthChartRendered } from '../../../bll/charts_part2_reducer';
import { postGrowth } from '../../../bll/inputParameters_reducer';
import { findValueView } from '../../../helpers/mediaChoiceTable_helpers';

export const Step4Page = React.memo(props => {
  const { chartsData, optic_media_mix_graph_data, donutData, contributionOfEachMedia_data, postData, mainMedia, dataFromXL } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isExportPPT, setIsExportPPT] = useState(false);
  const onClickPowerPointBtn = () => {
    setIsExportPPT(true);
  };
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);

  const knowledgeGeneral = useSelector(state => state.charts.knowledgeGeneral);
  const reachGeneral = useSelector(state => state.charts.reachGeneral);

  const chooseMainMediaArr = (chartsData, dataFromXL) => {
    if (isChartsUpload) {
      const makeMainMediaArr = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mainMediaArr = makeMainMediaArr(dataFromXL.filter(item => item.MainMedia));
      return mainMediaArr;
    } else if (!isChartsUpload && Object.keys(chartsData).length > 0) {
      const mainMediaArr = Object.keys(chartsData.data[0].Incremental_reach);
      return mainMediaArr;
    } else if (!isChartsUpload && Object.keys(chartsData).length === 0) {
      const makeMainMediaArr = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mainMediaArr = makeMainMediaArr(dataFromXL.filter(item => item.MainMedia));
      return mainMediaArr;
    }
  };

  const ts_pieChart_grouping_checked = useSelector(state => state.charts.ts_pieChart_grouping_checked);
  const ts_pieChart_unit_checked = useSelector(state => state.charts.ts_pieChart_unit_checked);
  const ts_contribution_chart_grouping_checked = useSelector(state => state.charts.ts_contribution_chart_grouping_checked);
  const ts_contribution_chart_unit_checked = useSelector(state => state.charts.ts_contribution_chart_unit_checked);
  const ts_optic_media_mix_chart_grouping_checked = useSelector(state => state.charts.ts_optic_media_mix_chart_grouping_checked);
  const ts_optic_media_mix_chart_unit_checked = useSelector(state => state.charts.ts_optic_media_mix_chart_unit_checked);
  const frequency = useSelector(state => state.input.frequency);
  const isGrowthChartRendered = useSelector(state => state.charts2.isGrowthChartRendered);
  const knowledgeGeneralCategory = useSelector(state => state.charts.knowledgeGeneralCategory);
  const seleb = useSelector(state => state.charts.seleb);

  const [loadingAccordionCollapsed, setLoadingAccordionCollapsed] = useState(true);
  const onClickUnLoadBtn = () => {
    setIsExportPPT(false);
    setLoadingAccordionCollapsed(!loadingAccordionCollapsed);
  };

  const unitDonut = useSelector(state => state.charts.unitDonut);
  const unitOptic = useSelector(state => state.charts.unitOptic);

  const onClickTSPie = (e, toggleName) => {
    if (toggleName === 'toggleSwitch_pieChart_ruble_checked') {
      dispatch(setUnitDonut(e.currentTarget.checked ? '₽' : '%'));
      dispatch(setTs_pieChart_unit_checked(e.currentTarget.checked));
      if (ts_pieChart_grouping_checked) {
        dispatch(changePieChart(e.currentTarget.checked ? '₽' : '%', 'all'));
      } else {
        dispatch(changePieChart(e.currentTarget.checked ? '₽' : '%', 'group'));
      }
    } else if (toggleName === 'toggleSwitch_pieChart_all_group_checked') {
      dispatch(setTs_pieChart_grouping_checked(e.currentTarget.checked));
      if (ts_pieChart_unit_checked) {
        dispatch(changePieChart('₽', e.currentTarget.checked ? 'all' : 'group'));
      } else {
        dispatch(changePieChart('%', e.currentTarget.checked ? 'all' : 'group'));
      }
    }
  };
  const onClickTSContribution = (e, toggleName) => {
    if (toggleName === 'toggleSwitch_contribution_Chart_ruble_checked') {
      dispatch(setTs_contribution_chart_unit_checked(e.currentTarget.checked));

      if (ts_contribution_chart_grouping_checked) {
        dispatch(changeContributionChart(e.currentTarget.checked ? '₽' : '%', 'all', mainMedia));
      } else {
        dispatch(changeContributionChart(e.currentTarget.checked ? '₽' : '%', 'group', mainMedia));
      }
    } else if (toggleName === 'toggleSwitch_contribution_Chart_all_group_checked') {
      dispatch(setTs_contribution_chart_grouping_checked(e.currentTarget.checked));
      if (ts_contribution_chart_unit_checked) {
        dispatch(changeContributionChart('₽', e.currentTarget.checked ? 'all' : 'group', mainMedia));
      } else {
        dispatch(changeContributionChart('%', e.currentTarget.checked ? 'all' : 'group', mainMedia));
      }
    }
  };
  const onClickTSOptic = (e, toggleName) => {
    if (toggleName === 'toggleSwitch_optic_media_mix_graph_ruble_checked') {
      dispatch(setUnitOptic(e.currentTarget.checked ? '₽' : '%'));
      dispatch(setTs_optic_media_mix_chart_unit_checked(e.currentTarget.checked));

      if (ts_optic_media_mix_chart_grouping_checked) {
        dispatch(changeOpticChart(e.currentTarget.checked ? '₽' : '%', 'all'));
      } else {
        dispatch(changeOpticChart(e.currentTarget.checked ? '₽' : '%', 'group'));
      }
    } else if (toggleName === 'toggleSwitch_optic_media_mix_graph_all_group_checked') {
      dispatch(setTs_optic_media_mix_chart_grouping_checked(e.currentTarget.checked));
      if (ts_optic_media_mix_chart_unit_checked) {
        dispatch(changeOpticChart('₽', e.currentTarget.checked ? 'all' : 'group'));
      } else {
        dispatch(changeOpticChart('%', e.currentTarget.checked ? 'all' : 'group'));
      }
    }
  };

  const onClickNextButton = () => {
    isGrowthChartRendered === false ? dispatch(postGrowth(history)) : history.push('/step5');
    isGrowthChartRendered === false && dispatch(setIsGrowthChartRendered(true));
  };
  const onClickBackButton = () => {
    history.push('/step3');
  };
  const onChangeMainMedia = selectItem => {
    if (ts_contribution_chart_unit_checked && ts_contribution_chart_grouping_checked) {
      dispatch(changeContributionChart('₽', 'all', selectItem));
    } else if (ts_contribution_chart_unit_checked && !ts_contribution_chart_grouping_checked) {
      dispatch(changeContributionChart('₽', 'group', selectItem));
    } else if (!ts_contribution_chart_unit_checked && ts_contribution_chart_grouping_checked) {
      dispatch(changeContributionChart('%', 'all', selectItem));
    } else if (!ts_contribution_chart_unit_checked && !ts_contribution_chart_grouping_checked) {
      dispatch(changeContributionChart('%', 'group', selectItem));
    }
  };

  const onChangeKnowledgeGeneralCategory = selectItem => {
    dispatch(getKnowledge(selectItem, seleb));
  };
  const onChangeSeleb = e => {
    const newSeleb = e.currentTarget.checked ? 1 : 0;
    dispatch(getKnowledge(knowledgeGeneralCategory, newSeleb));
  };

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step3Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться назад'} />
        <div className={s.chartsWrapper}>
          <div className={s.pieCharts}>
            <div className={s.pieChartWrapper}>
              <h4 className={s.chartTitle}>Рекомендуемый медиа микс</h4>

              <br />
              <div className={s.toggleSwitchWrapper}>
                <div className={s.toggleSwitch_ruble_pie}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSPie(e, 'toggleSwitch_pieChart_ruble_checked');
                    }}
                    checked={ts_pieChart_unit_checked}
                    labelStart={'₽'}
                    labelEnd={'%'}
                    id={'pie_p_%'}
                  />
                </div>

                <div className={s.toggleSwitch_all_group_pie}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSPie(e, 'toggleSwitch_pieChart_all_group_checked');
                    }}
                    checked={ts_pieChart_grouping_checked}
                    labelStart={'медиа'}
                    labelEnd={'группы медиа'}
                    id={'pie_all_group'}
                  />
                </div>
              </div>
              {Object.keys(donutData).length > 0 && <PieChart donutData={donutData} unitDonut={unitDonut} />}
            </div>

            <div className={s.contributionChartWrapper}>
              <h4 className={s.chartTitle}>Вклад каждого медиа </h4>
              <h4 className={s.chartTitle}>в совокупный охват {frequency} </h4>
              <br />
              <div className={s.toggleSwitchWrapper}>
                <p className={s.selectTitle}>Основное медиа: </p>
                {mainMedia !== null && (
                  <div className={s.selectWrapper}>
                    <Select name={'mainMedia'} value={mainMedia} onChange={onChangeMainMedia} items={chooseMainMediaArr(chartsData, dataFromXL)} location={'contributionChart'} />
                  </div>
                )}

                <div className={s.toggleSwitch_all_group_contribution}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSContribution(e, 'toggleSwitch_contribution_Chart_all_group_checked');
                    }}
                    checked={ts_contribution_chart_grouping_checked}
                    labelStart={'медиа'}
                    labelEnd={'группы медиа'}
                    id={'contribution_all_group'}
                  />
                </div>
              </div>
              {Object.keys(contributionOfEachMedia_data).length > 0 && <ContributionOfEachMedia contributionOfEachMedia_data={contributionOfEachMedia_data} />}
            </div>

            {((!isChartsUpload && knowledgeGeneral) || (isChartsUpload && dataFromXL.length > 0)) && (
              <div className={s.knowledgeWrapper}>
                <h4 className={s.knowledgeTitle}>Прогноз</h4>
                <div className={s.knowledgeItem}>
                  <div className={s.knowledgeText}>Охват ЦА {frequency} </div>
                  {findValueView(reachGeneral, 'roundCoverage')} %<div className={s.separator}></div>
                  <div style={{ fontWeight: 'bold' }}>{findValueView(knowledgeGeneral, 'roundCoverage')} %</div>
                  <div className={s.knowledgeText2}>
                    Знание <br />
                    креатива
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={s.opticChartWrapper}>
            <h4 className={s.chartTitle}>Сравнение различных медиамиксов на идентичный </h4>
            <h4 className={s.chartTitle}> бюджет и их влияние на совокупный охват</h4>
            <div className={s.toggleSwitchWrapper}>
              <div className={s.toggleSwitch_ruble}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSOptic(e, 'toggleSwitch_optic_media_mix_graph_ruble_checked');
                  }}
                  checked={ts_optic_media_mix_chart_unit_checked}
                  labelStart={'₽'}
                  labelEnd={'%'}
                  id={'opticMediaMixGraph_p_%'}
                />
              </div>
              <div className={s.toggleSwitch_all_group}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSOptic(e, 'toggleSwitch_optic_media_mix_graph_all_group_checked');
                  }}
                  checked={ts_optic_media_mix_chart_grouping_checked}
                  labelStart={'медиа'}
                  labelEnd={'группы медиа'}
                  id={'opticMediaMixGraph_all_group'}
                />
              </div>
            </div>
            {Object.keys(optic_media_mix_graph_data).length > 0 && <OpticMediaMixGraph optic_media_mix_graph_data={optic_media_mix_graph_data} unitOptic={unitOptic} />}
          </div>
        </div>
        <div>
          <div className={s.presenterBtn}>
            <StepButton onClick={onClickNextButton} title={'Следующий шаг'} />
            <div className={s.knowledgeGeneralCategoryWrapper}>
              <div className={s.blockWrapper}>
                <div className={s.blockTitle}>
                  ДЛЯ РАСЧЕТА <br /> ЗНАНИЯ
                </div>

                <div className={s.blockContent}>
                  <div>
                    Выберите <br /> категорию:
                  </div>
                  <Select name={'knowledgeGeneralCategory'} value={knowledgeGeneralCategory} onChange={onChangeKnowledgeGeneralCategory} items={['Телеком', 'FMCG', 'Товары для детей', 'Банки и финансы', 'Все категории']} width={135} />

                  <div style={{ marginTop: 30 }}>
                    <Checkbox
                      onChangeChecked={e => {
                        onChangeSeleb(e);
                      }}
                      checked={seleb === 1 ? true : false}
                      label={'Селебрити'}
                      id={'seleb'}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={s.slaveBtn}>
              {mainMedia !== null && (
                <div className={s.saveBtn}>
                  <SaveXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
                </div>
              )}
            </div>

            <div className={s.slaveBtn2}>
              <div className={s.loadingBtn}>
                <StepButton onClick={onClickUnLoadBtn} title={'Выгрузить\n расчеты'}>
                  <FontAwesomeIcon icon={faCloudDownloadAlt} className={s.icon} />
                </StepButton>
                {!loadingAccordionCollapsed && (
                  <div className={s.loadingBtnsWrapper}>
                    <div className={s.loadingBtns}>
                      <ExportXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
                      <LoadingButton onClick={onClickPowerPointBtn} title={'PowerPoint'} />
                      {isExportPPT && <ExportPPTPart1 donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} optic_media_mix_graph_data={optic_media_mix_graph_data} />}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
