import React from 'react';
import s from './Question.module.css';

export const Question = React.memo(props => {
  const { question, children } = props;
  return (
    <div className={s.wrapper}>
      <div className={s.question}> {question}</div>
      <div className={s.answer}>{children}</div>
    </div>
  );
});
