import React from 'react';
import s from './InputNumber.module.css';

export const InputNumber = React.memo(props => {
  const { value, onChange, location, isDisabled, ...restProps } = props;

  const findClassName = () => {
    if (location === 'severalFlights') {
      return isDisabled ? `${s.flightsInput} ${s.disabled}` : s.flightsInput;
    } else {
      return isDisabled ? `${s.input} ${s.disabled}` : s.input;
    }
  };
  return (
    <div>
      <input type={'number'} min='1' step='1' onChange={onChange} className={findClassName()} {...restProps} value={value} />
    </div>
  );
});
