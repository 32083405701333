import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './HomePage.module.css';
import { Warning } from '../../components/Common/Warning/Warning';
import { Header } from '../../components/Header/Header';
import { Step } from '../../components/HowItWorksBlock/Step/Step';
import { StartButton } from '../../components/Common/StartButton/StartButton';
import { DownloadButton } from '../../components/Common/DownloadButton/DownloadButton';
import { useHistory, useLocation } from 'react-router-dom';
import { setDataFromXL, updateCharts, setIsChartsUpload } from '../../../bll/charts_reducer';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { setDataFromXL_Flights, setFlightsUpload, updateFlightsCharts } from '../../../bll/flights_charts_reducer';
import * as XLSX from 'xlsx';
import Presentation from '../../../assets/files/Flight Media Mix Calculator.pptx';
import newPr from '../../../assets/images/newPr.png';
import edit from '../../../assets/images/edit.png';
import pres from '../../../assets/images/pres.png';
import arrow from '../../../assets/images/arrow.png';

export const HomePage = React.memo(() => {
  const [downloadBtnsVisible, setDownloadBtnsVisible] = useState(false);
  const [warningFileActive, setWarningFileActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onClickStartButton = () => {
    history.push('/step0');
  };

  const fileRef = React.createRef();
  const fileRefFlights = React.createRef();

  const onImportExcelFlights = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        const correctFile = Object.keys(data[0])[18] === 'seleb';
        !correctFile && setWarningFileActive(true);
        correctFile && dispatch(updateFlightsCharts(data));
        correctFile && dispatch(setDataFromXL_Flights(data));
        correctFile && dispatch(setFlightsUpload(true));

        correctFile && history.push('/flightsStep3');
      } catch (e) {}
    };
    fileReader.readAsBinaryString(files[0]);
  };

  const onImportExcel = file => {
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        const correctFile = Object.keys(data[1])[0] === 'knowledgeGeneral';
        !correctFile && setWarningFileActive(true);
        correctFile && dispatch(updateCharts(data));
        correctFile && dispatch(setDataFromXL(data));
        correctFile && dispatch(setIsChartsUpload(true));

        correctFile && history.push('/step4');
      } catch (e) {}
    };
    fileReader.readAsBinaryString(files[0]);
  };
  const onClickUploadProjectFlights = () => {
    fileRefFlights.current.click();
  };
  const onClickUploadProject = () => {
    fileRef.current.click();
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <div className={s.homeWrapper}>
      <Header />
      <div className={s.howItWorksBlock}>
        <h1>Как это работает</h1>
        <div className={s.steps}>
          <Step stepNumber={1} stepTitle={'Укажи вводные для флайта'} stepDescription={'Параметры целевой аудитории,\n бюджет кампании или желаемый Oхват,\n период кампании,\n частота контакта,\n тип кампании'} />
          <Step stepNumber={2} stepTitle={'Выбери тип медиа'} stepDescription={'Какие из предложенных вариантов\n медиа будут задействованы в кампании'} />
          <Step stepNumber={3} stepTitle={'Получи рекомендации'} stepDescription={'Рекомендованное распределение\n бюджета в % по медиа на флайт с\n учетом медиапотребления аудитории,\n качества контакта и стоимостных \n вводных'} />
        </div>
      </div>

      <div className={s.startNewProjectBtnWrapper}>
        <a className={s.link} href={Presentation} download='Flight Media Mix Calculator.pptx' style={{ marginRight: '50px' }}>
          <StartButton onClick={() => {}}>
            <div className={s.icon}>
              <img src={pres} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Скачать</div>
              <div>
                презентацию <br /> о софте
              </div>
            </div>
          </StartButton>
        </a>

        <div style={{ marginRight: '50px' }}>
          <StartButton onClick={onClickStartButton}>
            <div className={s.icon}>
              <img src={newPr} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Создать</div>
              <div>новый проект</div>
            </div>
          </StartButton>
        </div>

        <div className={s.downloadBtnWrapper}>
          <StartButton
            // onClick={onClickUploadProject}
            onClick={() => {
              setDownloadBtnsVisible(prevState => !prevState);
            }}
          >
            <div className={s.icon}>
              <img src={edit} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div className={s.redTitle}>Загрузить</div>
              <div>сохраненный проект</div>
            </div>
          </StartButton>
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
          <input type='file' accept='.xlsx, .xls' onChange={onImportExcelFlights} ref={fileRefFlights} style={{ display: 'none' }} />
          {downloadBtnsVisible && (
            <div className={s.downloadBtnsBlock}>
              <div className={s.forSeveralFlightsBtn}>
                <DownloadButton onClick={onClickUploadProjectFlights} title={'buttonTitle'}>
                  <div>
                    <div>
                      Медиа микс <span className={s.redTitle}>для нескольких</span>
                    </div>
                    <div>
                      <span className={s.redTitle}>флайтов</span> в течение одного года
                    </div>
                  </div>
                  <img src={arrow} alt='download' style={{ width: '20px', height: '40px', marginLeft: '10px' }} />
                </DownloadButton>
              </div>

              <div className={s.forOneFlightBtn}>
                <DownloadButton onClick={onClickUploadProject} title={'buttonTitle'}>
                  <div>
                    <div>
                      Медиа микс <span className={s.redTitle}>для одного флайта</span>
                    </div>
                  </div>
                  <img src={arrow} alt='download' style={{ width: '20px', height: '40px', marginLeft: '10px' }} />
                </DownloadButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <Warning text={'Загрузите нужный файл'} warningVisible={warningFileActive} setWarningVisible={setWarningFileActive} />
    </div>
  );
});
