import React from 'react';
import s from './Knowledge.module.css';
import { round } from '../../../helpers/chart_helpers';

export const Knowledge = React.memo(props => {
  const { investment_growth_graph_data, knowledgeArr } = props;
  const investment_growth_graph_reach_arr = investment_growth_graph_data.datasets[0].data;

  return (
    <div className={s.knowledgeWrapper}>
      {investment_growth_graph_reach_arr.map((item, i) => {
        return (
          <div key={item} className={s.knowledgeItem}>
            <div className={s.knowledgeText}>Охват ЦА</div>
            {round(item, 1)} %
            <div className={s.separator}></div>
            <div style={{ fontWeight: 'bold' }}>{round(+knowledgeArr[i],1)} %</div>
            <div className={s.knowledgeText}>
              Знание <br />
              креатива
            </div>
          </div>
        );
      })}
    </div>
  );
});
