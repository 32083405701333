import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step2Page.module.css';
import * as XLSX from 'xlsx';
import { Header } from '../../components/Header/Header';
import { MediaChoiceTable } from '../../components/MediaChoice/MediaChoiceTable/MediaChoiceTable';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { setAllMedia, setSelectedMedia, setMonth, setInputDataChanged, setIsMonthVisible, setCustomData, setMediaIsSelected } from '../../../bll/inputParameters_reducer';
import { setCurrentPathname, setError, setStatus } from '../../../bll/app_reducer';
import { Warning } from '../../components/Common/Warning/Warning';
import { Select } from '../../components/Common/Select/Select';
import { setMainMedia, setIsChartsUpload } from '../../../bll/charts_reducer';
import TemplateCustomCbu from '../../../assets/files/шаблон_custom_cbu.xlsx';
const coeff = require('../../../assets/data/coeff.json');

export const Step2Page = React.memo(props => {
  const { media } = props;
  const [warningIncorrectFile, setWarningIncorrectFile] = useState(false);
  const [mediaChoiceTableWarningActive, setMediaChoiceTableWarningActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const someMediaSelected = media.filter(item => item.isChecked === true);
  const mediaIsSelected = useSelector(state => state.input.mediaIsSelected);
  const copySelectedMedia = useSelector(state => state.input.copySelectedMedia);
  const month = useSelector(state => state.input.month);
  const isMonthVisible = useSelector(state => state.input.isMonthVisible);
  const customData = useSelector(state => state.input.customData);

  const onClickNextButton = () => {
    history.push('/step3');
  };
  const onClickBackButton = () => {
    history.push('/step1');
  };
  const returnDefaultMediaData = () => {
    dispatch(setAllMedia(copySelectedMedia));
    dispatch(setIsMonthVisible(1));
    dispatch(setMonth('Июнь'));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setCustomData([]));
  };

  const onChangeMonth = selectItem => {
    const updatedMediaState = copySelectedMedia.map(el => {
      return { ...el };
    });

    updatedMediaState.map(item => {
      const currentCoeff = coeff[item.media].find(i => i[selectItem])[selectItem];
      item.CPP = item.CPP * currentCoeff;
      return item;
    });
    dispatch(setMonth(selectItem));
    dispatch(setAllMedia(updatedMediaState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  useEffect(() => {
    mediaIsSelected === false && dispatch(setSelectedMedia());
  }, [dispatch, mediaIsSelected]);
  useEffect(() => {
    const warning = media.find(item => +item.coeff > 100);
    warning && setMediaChoiceTableWarningActive(true);
  }, [media]);
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  const fileRef2 = useRef(null);

  const onImportExcel = file => {
    dispatch(setStatus('loading'));

    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let dataSheet1 = [];

        const ws = workbook.Sheets['Sheet1'];
        const rows = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet1'], { raw: true });

        if (ws) {
          const correctFile = Object.keys(rows[0])[0] === 'Media' ? true : false;
          if (correctFile) {
            dataSheet1 =
              workbook.Sheets.hasOwnProperty('Sheet1') &&
              dataSheet1
                .concat(XLSX.utils.sheet_to_json(workbook.Sheets['Sheet1'], { raw: true }))
                .filter(item => item.Media !== '')
                .map(item => {
                  return {
                    customMedia: item.Media,
                    TRP1: item.TRP1,
                    Reach1: item.Reach1,
                    TRP2: item.TRP2,
                    Reach2: item.Reach2,
                    CPT: item.CPT,
                    'TA Size': item['TA Size'],
                    'Budget Max': item['Budget Max'],
                  };
                });
            const updateMedia = media.map(item => {
              const currentMediaCustomData = dataSheet1.find(it => it.customMedia === item.media);
              return {
                media: item.media,
                group: item.group,
                CPP: currentMediaCustomData.CPT,
                coeff: +item.coeff,
                finishBudget: +item.finishBudget,
                startBudget: +item.startBudget,
                isChecked: currentMediaCustomData.CPT === '' ? false : true,
                k: +item.k,
              };
            });
            correctFile && dispatch(setCustomData(dataSheet1));
            correctFile && dispatch(setAllMedia(updateMedia));
            correctFile && dispatch(setInputDataChanged(true));
            correctFile && dispatch(setMediaIsSelected(true));
            correctFile && dispatch(setMainMedia(null));
            correctFile && dispatch(setIsChartsUpload(false));
          } else {
            !correctFile ? setWarningIncorrectFile(true) : setWarningIncorrectFile(false);
          }
        } else {
          !ws ? setWarningIncorrectFile(true) : setWarningIncorrectFile(false);
        }
        dispatch(setStatus('success'));
      } catch (error) {
        dispatch(setStatus('success'));
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };

  const onClickUploadCustomCbu = () => {
    document.getElementById('files').value = '';
    fileRef2.current.click();
  };
  return (
    <>
      <Header />
      <div className={s.step2Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться назад'} />
        <div className={s.mediaChoiceBlock}>
          <h1>Выбор медиа</h1>

          <div className={s.table}>
            <MediaChoiceTable media={media} />
          </div>
          <div className={s.charts}></div>
        </div>
        <div style={{ position: 'relative' }}>
          {isMonthVisible === 1 && (
            <div className={s.monthSelectWrapper}>
              <div>
                Месяц для CPT/CPM <br /> по умолчанию:
              </div>
              <div className={s.monthSelect}>
                <Select isDisabled={customData.length !== 0 ? true : false} name={'month'} value={month} onChange={onChangeMonth} items={['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']} location={'targetAudience'} />
              </div>
            </div>
          )}

          <div className={s.presenterBtn}>
            <StepButton onClick={onClickNextButton} title={'Следующий шаг'} isDisabled={someMediaSelected && someMediaSelected.length > 1 ? false : true} />

            <div className={s.slaveBtn}>
              <StepButton onClick={returnDefaultMediaData} title={'Сбросить изменения'} />
            </div>
            <div className={s.slaveBtn2}>
              <StepButton onClick={onClickUploadCustomCbu} title={'Загрузить кастомные\n CBU'} isDisabled={false} />
            </div>
            <a className={s.link} href={TemplateCustomCbu} download='шаблон_custom_cbu.xlsx'>
              <div className={s.slaveBtn3}>
                <StepButton onClick={() => {}} title={'Скачать шаблон\n для кастомных CBU'} />
              </div>
            </a>
          </div>
        </div>
        <Warning text={'Данные введены не корректно!'} warningVisible={mediaChoiceTableWarningActive} setWarningVisible={setMediaChoiceTableWarningActive} />
        <Warning text={'Загрузите нужный файл'} warningVisible={warningIncorrectFile} setWarningVisible={setWarningIncorrectFile} />
        <input name='files' id='files' type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef2} style={{ display: 'none' }} />
      </div>
    </>
  );
});
