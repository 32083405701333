import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { LoadingButton } from '../Common/LoadingButton/LoadingButton';
import { makeArrayGroup, makeArrayBudgetPrGroupBar, makeArrayBudgetGroupBar, makeArrayCPPMedia, makeSumBudget, makeArrayCPPMediaDataFromXL } from '../../../helpers/chart_helpers';
import { makeArrayBudgetGroupDataFromXLForExelSave, makeArrayBudgetGroupPrDataFromXLForExelSave, makeArrayGroupDataFromXL, findMediaRepeat } from '../../../helpers/chart_helpers_data_from_XL';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExportXLSPart1 = props => {
  const { chartsData, mainMedia, postData, dataFromXL } = props;
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const getDataForExportXLSPart1_DataFromXL = dataFromXL => {
    const donutMedia_dataFromXL = dataFromXL.filter(item => item.Media1);
    const mainMediaArr_dataFromXL = findMediaRepeat(dataFromXL.filter(item => item.Media2).map(i => i.MainMedia));
    const donutMediaGroup_DataFromXL = makeArrayGroupDataFromXL(donutMedia_dataFromXL);
    const donutBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXLForExelSave(donutMedia_dataFromXL);
    const donutBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXLForExelSave(donutMedia_dataFromXL);

    const checkedMedia_DataFromXL = dataFromXL
      .filter(item => item.media)
      .map(item => ({
        media: item.media,
        group: item.group,
        CPP: item.CPP === '' ? item.CPP : +item.CPP,
        coeff: +item.coeff * 100,
        finishBudget: +item.finishBudget,
        startBudget: +item.startBudget,
        isChecked: item.isChecked === 'FALSE' || item.isChecked === 'ЛОЖЬ' ? false : true,
      }))
      .filter(media => media.isChecked === true);

    const CPPGroup_DataFromXL = makeArrayCPPMediaDataFromXL(donutMediaGroup_DataFromXL, checkedMedia_DataFromXL, donutMedia_dataFromXL, donutBudgetGroup_DataFromXL);

    const contributionData = dataFromXL.filter(item => item.Media2).filter(media => media.MainMedia === mainMedia);
    const contrGroupBudget_DataFromXL = makeArrayBudgetGroupDataFromXLForExelSave(contributionData);

    const mediaData = dataFromXL.filter(item => item.media);
    const inputData_dataFromXL = dataFromXL.filter(item => item.targetAudience)[0];
    const reach_DataFromXL = +dataFromXL.filter(item => item.Reach)[0].Option5;
    const budget_DataFromXL = +dataFromXL
      .filter(item => item.Media3)
      .map(item => item.Option5)
      .reduce(function (accumulator, currentValue) {
        return accumulator + +currentValue;
      }, 0);
    const dataset_Total_reach_DataFromXL = dataFromXL.filter(item => item.Total_reach)[0];
    const dataset_Total_cprp_DataFromXL = dataFromXL.filter(item => item.Total_cprp)[0];
    const Total_reach_cprp_DataFromXL = [
      [+dataset_Total_reach_DataFromXL['1+'], +dataset_Total_reach_DataFromXL['2+'], +dataset_Total_reach_DataFromXL['3+'], +dataset_Total_reach_DataFromXL['4+'], +dataset_Total_reach_DataFromXL['5+'], +dataset_Total_reach_DataFromXL['6+'], +dataset_Total_reach_DataFromXL['7+'], +dataset_Total_reach_DataFromXL['8+'], +dataset_Total_reach_DataFromXL['9+'], +dataset_Total_reach_DataFromXL['10+']],
      [+dataset_Total_cprp_DataFromXL['1+'], +dataset_Total_cprp_DataFromXL['2+'], +dataset_Total_cprp_DataFromXL['3+'], +dataset_Total_cprp_DataFromXL['4+'], +dataset_Total_cprp_DataFromXL['5+'], +dataset_Total_cprp_DataFromXL['6+'], +dataset_Total_cprp_DataFromXL['7+'], +dataset_Total_cprp_DataFromXL['8+'], +dataset_Total_cprp_DataFromXL['9+'], +dataset_Total_cprp_DataFromXL['10+']],
    ];
    const dataset_Media_group_reach_DataFromXL = dataFromXL
      .filter(item => item.media_group_reach)
      .map(item => ({
        [item.media_group_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
      }));
    const dataset_Media_reach_DataFromXL = dataFromXL
      .filter(item => item.media_reach)
      .map(item => ({
        [item.media_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
      }));

    const sheet1DataDataFromXL = donutMediaGroup_DataFromXL.map((item, i) => {
      const contrGroupBudValue = Object.values(contrGroupBudget_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
      const donutGroupBudValue = Object.values(donutBudgetGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
      const donutGroupBudPrValue = Object.values(donutBudgetPercentGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
      const CPPGroupValue = Object.values(CPPGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
      const ReachGroupVal = Object.values(dataset_Media_group_reach_DataFromXL.find(media => Object.keys(media)[0] === item))[0];
      return [
        {
          value: String(item),
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: contrGroupBudValue / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: donutGroupBudValue,
          style: {
            numFmt: '0₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: donutGroupBudPrValue / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: '',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: '',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          style: {
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: CPPGroupValue,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: ReachGroupVal[0] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[1] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[2] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[3] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[4] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[5] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[6] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[7] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[8] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachGroupVal[9] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        // item, contrGroupBudValue, donutGroupBudValue, donutGroupBudPrValue, '', '', CPPGroupValue, ReachGroupVal[0], ReachGroupVal[1], ReachGroupVal[2], ReachGroupVal[3], ReachGroupVal[4], ReachGroupVal[5], ReachGroupVal[6], ReachGroupVal[7], ReachGroupVal[8], ReachGroupVal[9]
      ];
    });
    const sheet2DataDataFromXL = mainMediaArr_dataFromXL.map((item, i) => {
      const contrBudVal = contributionData.find(media => media.Media2 === item) && +contributionData.find(media => media.Media2 === item).Budget;
      const donutBudVal = donutMedia_dataFromXL.find(media => media.Media1 === item) && +donutMedia_dataFromXL.find(media => media.Media1 === item).Budget;
      const donutBudPrVal = donutMedia_dataFromXL.find(media => media.Media1 === item) && +donutMedia_dataFromXL.find(media => media.Media1 === item).BudgetPr;
      const CPPVal = mediaData.find(media => media.media === item) && +mediaData.find(media => media.media === item).CPP;
      const ReachVal = Object.values(dataset_Media_reach_DataFromXL.find(media => Object.keys(media)[0] === item))[0];
      return [
        {
          value: String(item),
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: contrBudVal / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: donutBudVal,
          style: {
            numFmt: '0₽',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: donutBudPrVal / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: '',
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: '',
          alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
          style: {
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: CPPVal,
          style: {
            numFmt: '0',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          value: ReachVal[0] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[1] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[2] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[3] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[4] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[5] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[6] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[7] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[8] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          value: ReachVal[9] / 100,
          style: {
            numFmt: '0%',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        // item.Media1, contrBudVal, donutBudVal, donutBudPrVal, '', '', CPPVal, ReachVal[0], ReachVal[1], ReachVal[2], ReachVal[3], ReachVal[4], ReachVal[5], ReachVal[6], ReachVal[7], ReachVal[8], ReachVal[9]
      ];
    });

    return [sheet1DataDataFromXL, sheet2DataDataFromXL, inputData_dataFromXL, reach_DataFromXL, budget_DataFromXL, Total_reach_cprp_DataFromXL];
  };
  const sheet1DataDataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[0];
  const sheet2DataDataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[1];
  const inputData_dataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[2];
  const reach_DataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[3];
  const budget_DataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[4];
  const Total_reach_cprp_DataFromXL = isChartsUpload && getDataForExportXLSPart1_DataFromXL(dataFromXL)[5];

  const getDataForExportXLSPart1_DataFromChartsData = chartsData => {
    if (Object.keys(chartsData).length === 0) {
      const donutMedia_dataFromXL = dataFromXL.filter(item => item.Media1);
      const mainMediaArr_dataFromXL = findMediaRepeat(dataFromXL.filter(item => item.Media2).map(i => i.MainMedia));
      const donutMediaGroup_DataFromXL = makeArrayGroupDataFromXL(donutMedia_dataFromXL);
      const donutBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXLForExelSave(donutMedia_dataFromXL);
      const donutBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXLForExelSave(donutMedia_dataFromXL);

      const checkedMedia_DataFromXL = dataFromXL
        .filter(item => item.media)
        .map(item => ({
          media: item.media,
          group: item.group,
          CPP: item.CPP === '' ? item.CPP : +item.CPP,
          coeff: +item.coeff * 100,
          finishBudget: +item.finishBudget,
          startBudget: +item.startBudget,
          isChecked: item.isChecked === 'FALSE' || item.isChecked === 'ЛОЖЬ' ? false : true,
        }))
        .filter(media => media.isChecked === true);

      const CPPGroup_DataFromXL = makeArrayCPPMediaDataFromXL(donutMediaGroup_DataFromXL, checkedMedia_DataFromXL, donutMedia_dataFromXL, donutBudgetGroup_DataFromXL);
      const contributionData = dataFromXL.filter(item => item.Media2).filter(media => media.MainMedia === mainMedia);
      const contrGroupBudget_DataFromXL = makeArrayBudgetGroupDataFromXLForExelSave(contributionData);
      const mediaData = dataFromXL.filter(item => item.media);
      const reach = +dataFromXL.filter(item => item.Reach)[0].Option5;
      const budget = +dataFromXL
        .filter(item => item.Media3)
        .map(item => item.Option5)
        .reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0);

      const inputData = dataFromXL.filter(item => item.targetAudience);
      const dataset_Total_reach_DataFromXL = dataFromXL.filter(item => item.Total_reach)[0];
      const dataset_Total_cprp_DataFromXL = dataFromXL.filter(item => item.Total_cprp)[0];
      const Total_reach_cprp = [
        [+dataset_Total_reach_DataFromXL['1+'], +dataset_Total_reach_DataFromXL['2+'], +dataset_Total_reach_DataFromXL['3+'], +dataset_Total_reach_DataFromXL['4+'], +dataset_Total_reach_DataFromXL['5+'], +dataset_Total_reach_DataFromXL['6+'], +dataset_Total_reach_DataFromXL['7+'], +dataset_Total_reach_DataFromXL['8+'], +dataset_Total_reach_DataFromXL['9+'], +dataset_Total_reach_DataFromXL['10+']],
        [+dataset_Total_cprp_DataFromXL['1+'], +dataset_Total_cprp_DataFromXL['2+'], +dataset_Total_cprp_DataFromXL['3+'], +dataset_Total_cprp_DataFromXL['4+'], +dataset_Total_cprp_DataFromXL['5+'], +dataset_Total_cprp_DataFromXL['6+'], +dataset_Total_cprp_DataFromXL['7+'], +dataset_Total_cprp_DataFromXL['8+'], +dataset_Total_cprp_DataFromXL['9+'], +dataset_Total_cprp_DataFromXL['10+']],
      ];
      const dataset_Media_group_reach = dataFromXL
        .filter(item => item.media_group_reach)
        .map(item => ({
          [item.media_group_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
        }));
      const dataset_Media_reach = dataFromXL
        .filter(item => item.media_reach)
        .map(item => ({
          [item.media_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
        }));

      const sheet1Data = donutMediaGroup_DataFromXL.map((item, i) => {
        const contrGroupBudValue = Object.values(contrGroupBudget_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
        const donutGroupBudValue = Object.values(donutBudgetGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
        const donutGroupBudPrValue = Object.values(donutBudgetPercentGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
        const CPPGroupValue = Object.values(CPPGroup_DataFromXL.find(media => Object.keys(media)[0] === item))[0][0];
        const ReachGroupVal = Object.values(dataset_Media_group_reach.find(media => Object.keys(media)[0] === item))[0];
        return [
          {
            value: String(item),
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: contrGroupBudValue / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutGroupBudValue,
            style: {
              numFmt: '0₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutGroupBudPrValue / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            style: {
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: CPPGroupValue,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: ReachGroupVal[0] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[1] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[2] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[3] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[4] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[5] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[6] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[7] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[8] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[9] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          // item, contrGroupBudValue, donutGroupBudValue, donutGroupBudPrValue, '', '', CPPGroupValue, ReachGroupVal[0], ReachGroupVal[1], ReachGroupVal[2], ReachGroupVal[3], ReachGroupVal[4], ReachGroupVal[5], ReachGroupVal[6], ReachGroupVal[7], ReachGroupVal[8], ReachGroupVal[9]
        ];
      });
      const sheet2Data = mainMediaArr_dataFromXL.map((item, i) => {
        const contrBudVal = contributionData.find(media => media.Media2 === item) && +contributionData.find(media => media.Media2 === item).Budget;
        const donutBudVal = donutMedia_dataFromXL.find(media => media.Media1 === item) && +donutMedia_dataFromXL.find(media => media.Media1 === item).Budget;
        const donutBudPrVal = donutMedia_dataFromXL.find(media => media.Media1 === item) && +donutMedia_dataFromXL.find(media => media.Media1 === item).BudgetPr;
        const CPPVal = mediaData.find(media => media.media === item) && +mediaData.find(media => media.media === item).CPP;
        const ReachVal = Object.values(dataset_Media_reach.find(media => Object.keys(media)[0] === item))[0];
        return [
          {
            value: String(item),
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: contrBudVal / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutBudVal,
            style: {
              numFmt: '0₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutBudPrVal / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            style: {
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: CPPVal,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: ReachVal[0] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[1] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[2] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[3] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[4] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[5] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[6] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[7] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[8] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[9] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          // item.Media1, contrBudVal, donutBudVal, donutBudPrVal, '', '', CPPVal, ReachVal[0], ReachVal[1], ReachVal[2], ReachVal[3], ReachVal[4], ReachVal[5], ReachVal[6], ReachVal[7], ReachVal[8], ReachVal[9]
        ];
      });

      return [sheet1Data, sheet2Data, inputData, reach, budget, Total_reach_cprp];
    } else {
      const mediaPost = postData.media;
      const checkedMediaPost = postData.media.filter(media => media.isChecked === true);

      const BUDGET = JSON.parse(chartsData.data[0].Budget);
      const INCR = chartsData.data[0].Incremental_reach;
      const mainMediaArr = Object.keys(INCR);

      const donutMediaGroup = makeArrayGroup(BUDGET);
      const donutGroupBudget = makeArrayBudgetGroupBar(BUDGET);
      const donutGroupBudgetPr = makeArrayBudgetPrGroupBar(BUDGET);
      const CPPGroup = makeArrayCPPMedia(donutMediaGroup, checkedMediaPost, BUDGET, donutGroupBudget);
      const INCREACH = JSON.parse(chartsData.data[0].Incremental_reach[mainMedia]);
      const contrGroupBudget = makeArrayBudgetGroupBar(INCREACH);
      const MEDIA_MIX_COMPARE = chartsData.data[0].Media_mix_compare;
      const reach = MEDIA_MIX_COMPARE[4][0];
      const budget = makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]));
      const inputData = postData;
      const Media_group_reach = chartsData.data[0].Media_group_reach;
      const Media_reach = chartsData.data[0].Media_reach;
      const Total_reach_cprp = chartsData.data[0].Total_reach_cprp;

      const sheet1Data = donutMediaGroup.map((item, i) => {
        const contrGroupBudValue = Object.values(contrGroupBudget.find(media => Object.keys(media)[0] === item))[0][0];
        const donutGroupBudValue = Object.values(donutGroupBudget.find(media => Object.keys(media)[0] === item))[0][0];
        const donutGroupBudPrValue = Object.values(donutGroupBudgetPr.find(media => Object.keys(media)[0] === item))[0][0];
        const CPPGroupValue = Object.values(CPPGroup.find(media => Object.keys(media)[0] === item))[0][0];

        const ReachGroupVal = Media_group_reach[item];
        return [
          {
            value: String(item),
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: contrGroupBudValue / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutGroupBudValue,
            style: {
              numFmt: '0₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: donutGroupBudPrValue / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            style: {
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: CPPGroupValue,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: ReachGroupVal[0] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[1] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[2] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[3] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[4] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[5] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[6] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[7] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[8] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachGroupVal[9] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          // item, contrGroupBudValue, donutGroupBudValue, donutGroupBudPrValue, '', '', CPPGroupValue, ReachGroupVal[0], ReachGroupVal[1], ReachGroupVal[2], ReachGroupVal[3], ReachGroupVal[4], ReachGroupVal[5], ReachGroupVal[6], ReachGroupVal[7], ReachGroupVal[8], ReachGroupVal[9]
        ];
      });
      const sheet2Data = mainMediaArr.map((item, i) => {
        const ReachVal = Media_reach[item];
        return [
          {
            value: String(item),
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: INCREACH[item].Budget / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: BUDGET[item].Budget,
            style: {
              numFmt: '0₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: BUDGET[item].BudgetPr / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: '',
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            style: {
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: mediaPost.find(media => media.media === item).CPP,
            style: {
              numFmt: '0',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: ReachVal[0] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[1] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[2] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[3] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[4] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[5] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[6] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[7] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[8] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: ReachVal[9] / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          // item, INCREACH[item].Budget, BUDGET[item].Budget, BUDGET[item].BudgetPr, '', '', mediaPost.find(media => media.media === item).CPP, ReachVal[0], ReachVal[1], ReachVal[2], ReachVal[3], ReachVal[4], ReachVal[5], ReachVal[6], ReachVal[7], ReachVal[8], ReachVal[9]
        ];
      });
      return [sheet1Data, sheet2Data, inputData, reach, budget, Total_reach_cprp];
    }
  };

  const sheet1Data = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[0];
  const sheet2Data = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[1];
  const inputData = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[2];
  const reach = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[3];
  const budget = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[4];
  const Total_reach_cprp = !isChartsUpload && getDataForExportXLSPart1_DataFromChartsData(chartsData)[5];

  const TA = isChartsUpload ? inputData_dataFromXL.targetAudience : inputData.targetAudience;
  const frequency = isChartsUpload ? inputData_dataFromXL.frequency : inputData.frequency;
  const aggregateBudget = isChartsUpload ? budget_DataFromXL : budget;
  const aggregateReach = isChartsUpload ? reach_DataFromXL : reach;
  const Total_reach_cprpAll = isChartsUpload ? Total_reach_cprp_DataFromXL : Total_reach_cprp;

  const sheet1 = [
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Бренд',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          title: '',
          width: { wpx: 170 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            font: { bold: true },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: 'Целевая аудитория',
            style: {
              width: { wpx: 120 },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(TA),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный бюджет',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: aggregateBudget,

            style: {
              numFmt: '0₽',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный Frequency',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(frequency),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный охват 5+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: aggregateReach / 100,
            style: {
              numFmt: '0%',
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Частота',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Совокупный охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPRP',
          font: { bold: true },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: '1+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[0][0] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[1][0],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '2+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][1] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][1],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '3+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][2] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][2],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '4+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][3] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][3],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '5+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][4] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][4],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '6+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][5] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][5],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '7+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][6] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][6],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '8+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][7] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][7],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '9+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][8] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][8],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '10+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][9] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[1][9],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Медиа',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Уникальный вклад в охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Сумма',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Доля в медиа миксе',
          width: { wpx: 130 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Frequency',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'OTS',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPT',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 1+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 2+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 3+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 4+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 5+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 6+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 7+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 8+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 9+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 10+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: isChartsUpload ? sheet1DataDataFromXL : sheet1Data,
    },
  ];
  const sheet2 = [
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Бренд',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },

        {
          title: '',
          width: { wpx: 170 },
          style: {
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            font: { bold: true },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: 'Целевая аудитория',
            style: {
              width: { wpx: 120 },
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(TA),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный бюджет',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: aggregateBudget,

            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              numFmt: '0₽',
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный Frequency',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: String(frequency),
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: 'Совокупный охват 5+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: aggregateReach / 100,
            style: {
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              numFmt: '0%',
              font: { bold: true },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Частота',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Совокупный охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPRP',
          font: { bold: true },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: [
        [
          {
            value: '1+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[0][0] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[1][0],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '2+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][1] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][1],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '3+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][2] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][2],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '4+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][3] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][3],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '5+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][4] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][4],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '6+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][5] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][5],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '7+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][6] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][6],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '8+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][7] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][7],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '9+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][8] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[1][8],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
        [
          {
            value: '10+',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
              alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },

          {
            value: Total_reach_cprpAll[0][9] / 100,
            style: {
              numFmt: '0%',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
          {
            value: Total_reach_cprpAll[1][9],
            style: {
              numFmt: '0₽',
              font: { bold: true },
              alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
              border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
            },
          },
        ],
      ],
    },
    {
      xSteps: 0,
      ySteps: 2,
      columns: [
        {
          title: 'Медиа',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Уникальный вклад в охват',
          width: { wpx: 170 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Сумма',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Доля в медиа миксе',
          width: { wpx: 130 },
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Frequency',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'OTS',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'CPT',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 1+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 2+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 3+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 4+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 5+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 6+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 7+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 8+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 9+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
        {
          title: 'Охват 10+',
          style: {
            font: { bold: true },
            alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
            fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
            border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
          },
        },
      ],
      data: isChartsUpload ? sheet2DataDataFromXL : sheet2Data,
    },
  ];
  return (
    <div>
      <ExcelFile element={<LoadingButton title={'Excel'} />} filename='media_mix_calculator_charts_part1'>
        <ExcelSheet dataSet={sheet1} name='По группам медиа' />
        <ExcelSheet dataSet={sheet2} name='Развернуто по медиа' />
      </ExcelFile>
    </div>
  );
};
