import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './CampaignType.module.css';
import { Checkbox } from '../../../Common/Checkbox/Checkbox';
import { setCampaignType, setMediaIsSelected, setInputDataChanged, setMonth, setIsMonthVisible } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const CampaignType = React.memo(props => {
  const { setCampaignTypeIsSelected } = props;
  const dispatch = useDispatch();
  const campaignType = useSelector(state => state.input.campaignType);

  const handleOnChange = selectedName => {
    const updatedGeoState = campaignType.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = !selectedItem.isChecked;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    dispatch(setCampaignType(updatedGeoState));
    dispatch(setMediaIsSelected(false));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setMonth('Июнь'));
    dispatch(setIsMonthVisible(1));
  };

  const someCampaignTypeSelected = campaignType.find(item => item.isChecked === true);

  useEffect(() => {
    someCampaignTypeSelected && someCampaignTypeSelected.isChecked ? setCampaignTypeIsSelected(true) : setCampaignTypeIsSelected(false);
  }, [campaignType, setCampaignTypeIsSelected, someCampaignTypeSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        ТИП /<br /> КАМПАНИИ
      </div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Промо');
            }}
            checked={campaignType[0].isChecked}
            label={'Промо'}
            id={'promo'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Имидж');
            }}
            checked={campaignType[1].isChecked}
            label={'Имидж'}
            id={'image'}
          />
        </div>
        {/* <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('...');
            }}
            checked={campaignType[2].isChecked}
            label={'...'}
            id={'...'}
          />
        </div> */}
      </div>
    </div>
  );
});
