import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../Common/Select/Select';
import { setFlightsBudget_CoverageArr, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsBudget = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsBudget_CoverageArr = useSelector(state => state.flights.flightsBudget_CoverageArr);
  const currentFlightsBudget_Coverage = flightsBudget_CoverageArr.find(item => item.flight === flight);
  const convertBudgetName = name => {
    if (name === 'budget') {
      return 'Бюджет';
    } else if (name === 'coverage') {
      return 'Охват';
    }
  };
  const currentFlightsBudget_CoverageName = currentFlightsBudget_Coverage.budget_coverage.find(i => i.isChecked === true) ? convertBudgetName(currentFlightsBudget_Coverage.budget_coverage.find(i => i.isChecked === true).name) : '';

  const findBudgetName = selectItem => {
    if (selectItem === 'Бюджет') {
      return 'budget';
    } else if (selectItem === 'Охват') {
      return 'coverage';
    }
  };

  const handleOnChange = (selectedName, flight) => {
    const selectItemName = findBudgetName(selectedName);
    const updatedBudget_CoverageState = currentFlightsBudget_Coverage.budget_coverage.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = true;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectItemName ? selectedItem : otherItem;
    });

    const updatedStateFlightsBudget_CoverageArr = flightsBudget_CoverageArr.map(item => {
      const newItem = { ...item };
      newItem.budget_coverage = updatedBudget_CoverageState;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsBudget_CoverageArr(updatedStateFlightsBudget_CoverageArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  return (
    <Select isDisabled={isDisabled} name={flight} value={currentFlightsBudget_CoverageName} onChange={handleOnChange} items={['Бюджет', 'Охват']} location={'severalFlights'} />
    );
});
