import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditableSpanForFlights } from '../../Common/EditableSpanForFlights/EditableSpanForFlights';
import { setFlightsBudget_CoverageArr, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsBudgetValue = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsBudget_CoverageArr = useSelector(state => state.flights.flightsBudget_CoverageArr);
  const currentFlightsBudget_Coverage = flightsBudget_CoverageArr.find(item => item.flight === flight) && flightsBudget_CoverageArr.find(item => item.flight === flight).budget_coverage;
  const currentBudgetOrCoverage = currentFlightsBudget_Coverage.find(item => item.isChecked === true) && currentFlightsBudget_Coverage.find(item => item.isChecked === true).name;
  const onChangeFlightsBudget_CoverageArrValue = (newValue, selectedName) => {
    const updatedState = currentFlightsBudget_Coverage.map(item => {
      const selectedItem = { ...item };
      selectedItem.value = newValue;
      const otherItem = { ...item };
      otherItem.value = 0;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    const updatedStateFlightsBudget_CoverageArr = flightsBudget_CoverageArr.map(item => {
      const newItem = { ...item };
      newItem.budget_coverage = updatedState;
      return item.flight === flight ? newItem : item;
    });

    dispatch(setFlightsBudget_CoverageArr(updatedStateFlightsBudget_CoverageArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  return <EditableSpanForFlights isDisabled={isDisabled} value={currentBudgetOrCoverage === 'budget' ? currentFlightsBudget_Coverage[0].value : currentFlightsBudget_Coverage[1].value} name={currentBudgetOrCoverage === 'budget' ? currentFlightsBudget_Coverage[0].name : currentFlightsBudget_Coverage[1].name} onChange={onChangeFlightsBudget_CoverageArrValue} view={'severalFlights'} location={currentBudgetOrCoverage === 'budget' ? 'flightBudget' : 'flightCoverage'} />;
});
