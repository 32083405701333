import React from 'react';
import { useDispatch } from 'react-redux';
import { Select } from '../../Common/Select/Select';
import { setTargetAudienceFlights, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsTargetAudience = React.memo(props => {
  const { targetAudienceData, targetAudienceFlights } = props;
  const dispatch = useDispatch();
  const onChangeTargetFlightsAudience = selectItem => {
    dispatch(setTargetAudienceFlights(selectItem));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  return <Select name={'flightTA'} value={targetAudienceFlights} onChange={onChangeTargetFlightsAudience} items={targetAudienceData} location={'severalFlights'} />;
});
