import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsMediaChoice.module.css';
import { Checkbox } from '../../Common/Checkbox/Checkbox';
import { makeGroupsNames, findFirstMediaInGroup } from '../../../../helpers/mediaChoiceTable_helpers';
import { setFlightsSelectedMedia, setFlightsMediaArr, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsMediaChoice = React.memo(props => {
  const { flight } = props;
  const dispatch = useDispatch();

  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const currentMediaArr = flightsMediaArr.find(item => item.flight === flight);
  const media = currentMediaArr.media;

  const groups = makeGroupsNames(media);
  const firsMediaInGroupArr = findFirstMediaInGroup(groups, media);
  const groupCheckedArr = currentMediaArr.groupCheckedArr;
  const mediaIsSelected = currentMediaArr.mediaIsSelected;

  useEffect(() => {
    mediaIsSelected === false && dispatch(setFlightsSelectedMedia(flight));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeGroupCheckbox = (e, groupName) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.isChecked = e.currentTarget.checked;
      return item.group === groupName ? newItem : item;
    });
    const someMediaInCurrentFlightSelected = updatedCheckedState.find(item => item.isChecked === true);

    const updateGroupArrState = groupCheckedArr.map(group => {
      let newGroup = { ...group };
      newGroup[groupName] = e.currentTarget.checked;
      return Object.keys(group)[0] === groupName ? newGroup : group;
    });

    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedCurrentMediaFlight = {
        flight: item.flight,
        mediaIsSelected: someMediaInCurrentFlightSelected ? true : false,
        groupCheckedArr: updateGroupArrState,
        media: updatedCheckedState,
        copyStartMedia: updatedCheckedState,
        defaultMediaData: updatedCheckedState,
      };
      const newItem = updatedCurrentMediaFlight;
      return item.flight === flight ? newItem : item;
    });

    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  const handleOnChange = (mediaName, flight) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.isChecked = !newItem.isChecked;
      return item.media === mediaName ? newItem : item;
    });

    const someMediaInCurrentFlightSelected = updatedCheckedState.find(item => item.isChecked === true);

    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedCurrentMediaFlight = {
        flight: item.flight,
        mediaIsSelected: someMediaInCurrentFlightSelected ? true : false,
        groupCheckedArr: item.groupCheckedArr,
        media: updatedCheckedState,
        copyStartMedia: updatedCheckedState,
        defaultMediaData: updatedCheckedState,
      };
      const newItem = updatedCurrentMediaFlight;
      return item.flight === flight ? newItem : item;
    });

    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  useEffect(() => {
    media.map(media => {
      const updateGroupArrState = groupCheckedArr.map(group => {
        let newGroup = { ...group };
        newGroup[media.group] = false;
        return Object.keys(group)[0] === media.group ? newGroup : group;
      });

      const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
        const someMediaInCurrentFlightSelected = item.media.find(i => i.isChecked === true);
        const updatedCurrentMediaFlight = {
          flight: item.flight,
          mediaIsSelected: someMediaInCurrentFlightSelected ? true : false,
          groupCheckedArr: updateGroupArrState,
          media: item.media,
          copyStartMedia: item.media,
          defaultMediaData: item.media,
        };
        const newItem = updatedCurrentMediaFlight;
        return item.flight === flight ? newItem : item;
      });

      media.isChecked === false && groupCheckedArr.find(group => group[media.group]) && groupCheckedArr.find(group => group[media.group])[media.group] === true && dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
      return media;
    });
  }, [media, dispatch, groupCheckedArr, flightsMediaArr, flight]);

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockContent}>
        {media &&
          media.map(media => {
            const groupName = groupCheckedArr.find(group => Object.keys(group)[0] === media.group);

            return (
              <div className={media.isChecked ? s.item : `${s.item} ${s.text_muted}`} key={media.media}>
                <div className={media.isChecked ? s.checkboxWrapper : `${s.checkboxWrapper} ${s.text_muted_for_checkbox}`}>
                  {firsMediaInGroupArr.find(mediaName => mediaName === media.media) ? (
                    <div className={s.groupCheckboxWrapper}>
                      <Checkbox
                        onChangeChecked={e => {
                          onChangeGroupCheckbox(e, media.group);
                        }}
                        checked={groupName[media.group]}
                        label={media.group}
                        id={media.group + flight}
                      />
                    </div>
                  ) : (
                    <div className={s.groupCheckboxWrapper}></div>
                  )}
                  <div className={s.mediaCheckboxWrapper}>
                    <Checkbox
                      onChangeChecked={() => {
                        handleOnChange(media.media, flight);
                      }}
                      checked={media.isChecked}
                      label={media.media}
                      id={`${media.media}+${media.media}+ ${flight}`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});
