import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './FlightsTable.module.css';
import { FlightsBudgetValue } from '../FlightsInputParam/FlightsBudgetValue/FlightsBudgetValue';
import { FlightsPeriod } from '../FlightsInputParam/FlightsPeriod/FlightsPeriod';
import { Accordion } from '../Common/Accordion/Accordion';
import { FlightsFrequency } from '../FlightsInputParam/FlightsFrequency/FlightsFrequency';
import { FlightsBudgetConstraints } from '../FlightsInputParam/FlightsBudgetConstraints/FlightsBudgetConstraints';
import { FlightsCampaignType } from '../FlightsInputParam/FlightsCampaignType/FlightsCampaignType';
import { FlightsMediaChoice } from '../FlightsInputParam/FlightsMediaChoice/FlightsMediaChoice';
import { FlightsBudget } from '../FlightsInputParam/FlightsBudget/FlightsBudget';
import { StartButton } from '../Common/StartButton/StartButton';
import { setNextValueFlightArr, findCurrentMediaGroupWithoutRepeat } from '../../../helpers/flightTable_helpers';
import { setFlightsPeriod, setFlightArr, setFlightsCampaignTypesArrArr, setFlightsMediaArr, setFlightsFrequencyArr, setFlightsBudget_CoverageArr, setFlightsInputStep1DataChanged } from '../../../bll/flights_reducer';
import add from '../../../assets/images/add.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export const FlightsTable = React.memo(props => {
  const { targetAudienceFlightsIsSelected, flightsGeoIsSelected } = props;

  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsCampaignTypesArr = useSelector(state => state.flights.flightsCampaignTypesArr);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsBudget_CoverageArr = useSelector(state => state.flights.flightsBudget_CoverageArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod); 

  const dispatch = useDispatch();

  const addFlight = () => {
    const arrNum = flightArr.map(item => item.flight);
    const newFlightNumber = flightArr.length === 0 ? 1 : setNextValueFlightArr(arrNum);
    const newFlightId = Math.random();
    const newFlightArr = [...flightArr, { flight: newFlightNumber, id: newFlightId }];

    const newFlightsCampaignTypesArr = [
      ...flightsCampaignTypesArr,
      {
        flight: newFlightId,
        campaignType: [
          { id: 1, name: 'Промо', isChecked: false },
          { id: 2, name: 'Имидж', isChecked: false },
        ],
      },
    ];
    const newFlightsMediaArr = [
      ...flightsMediaArr,
      {
        flight: newFlightId,
        mediaIsSelected: false,
        groupCheckedArr: [],
        media: [],
        copyStartMedia: [],
        defaultMediaData: [],
      },
    ];
    const newFlightsBudget_CoverageArr = [
      ...flightsBudget_CoverageArr,
      {
        flight: newFlightId,
        budget_coverage: [
          { id: 1, name: 'budget', value: 0, isChecked: false },
          { id: 2, name: 'coverage', value: 0, isChecked: false },
          { id: 3, name: 'different_budget', value: 0, isChecked: false },
        ],
      },
    ];
    const newFlightsPeriod = [
      ...flightsPeriod,
      {
        flight: newFlightId,
        period: 0,
        startDate: 0,
        finishDate: 0,
      },
    ];
    const newFlightsFrequencyArr = [
      ...flightsFrequencyArr,
      {
        flight: newFlightId,
        frequency: '',
      },
    ];
    dispatch(setFlightArr(newFlightArr));
    dispatch(setFlightsCampaignTypesArrArr(newFlightsCampaignTypesArr));
    dispatch(setFlightsMediaArr(newFlightsMediaArr));
    dispatch(setFlightsBudget_CoverageArr(newFlightsBudget_CoverageArr));
    dispatch(setFlightsPeriod(newFlightsPeriod));
    dispatch(setFlightsFrequencyArr(newFlightsFrequencyArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  const deleteFlight = (flight, flightId) => {
    const newFlightArr = flightArr.filter(n => n.flight !== flight);
    const indexArr = [];
    for (let i = 1; i < newFlightArr.length + 1; i++) {
      indexArr.push(i);
    }
    newFlightArr.map((item, i) => {
      item.flight = indexArr[i];
      return item;
    });
    const newFlightsCampaignTypesArr = flightsCampaignTypesArr.filter(i => i.flight !== flightId);
    const newFlightsMediaArr = flightsMediaArr.filter(i => i.flight !== flightId);
    const newFlightsBudget_CoverageArr = flightsBudget_CoverageArr.filter(i => i.flight !== flightId);
    const newFlightsPeriod = flightsPeriod.filter(i => i.flight !== flightId);
    const newFlightsFrequencyArr = flightsFrequencyArr.filter(i => i.flight !== flightId);
    dispatch(setFlightArr(newFlightArr));
    dispatch(setFlightsCampaignTypesArrArr(newFlightsCampaignTypesArr));
    dispatch(setFlightsMediaArr(newFlightsMediaArr));
    dispatch(setFlightsBudget_CoverageArr(newFlightsBudget_CoverageArr));
    dispatch(setFlightsPeriod(newFlightsPeriod));
    dispatch(setFlightsFrequencyArr(newFlightsFrequencyArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        <div className={s.title}>ТИП КАМПАНИИ</div>
        <div className={s.title}>ПЕРИОД</div>
        <div className={s.title}>
          <div>ИСПОЛЬЗУЕМЫЕ</div> <div>МЕДИА</div>
        </div>
        <div className={s.title}>
          <div>ЭФФЕКТИВНАЯ</div> <div>ЧАСТОТА</div>
        </div>
        <div className={s.title}>
          <div>ЦЕЛЬ ОПТИМИЗАЦИИ</div> <div>БЮДЖЕТ/ОХВАТ</div>
        </div>
        <div className={s.title}>ЗНАЧЕНИЕ</div>
        <div className={s.title}>
          <div>ОГРАНИЧЕНИЕ</div> <div>БЮДЖЕТА ПО МЕДИА</div>
        </div>
      </div>
      <div className={s.addBtnWrapper}>
      <StartButton onClick={addFlight} view={'add'}>
        <div className={s.icon}>
          <img src={add} alt='add' style={{ width: '20px', height: '20px' }} />
        </div>
        <div className={s.titleBtn}>
          <div>Добавить </div>
          <div> флайт </div>
        </div>
      </StartButton>
    </div>
      <div className={s.blockContent}>
        {flightArr.map(flight => {
          const currentCampaignType = flightsCampaignTypesArr.find(item => item.flight === flight.id);
          const currentCTIsSelected = currentCampaignType && currentCampaignType.campaignType.find(item => item.isChecked === true);

          const currentPeriod = flightsPeriod.find(item => item.flight === flight.id);
          const currentPeriodIsSelected = currentPeriod.period !== 0;

          const currentMediaArr = flightsMediaArr.find(item => item.flight === flight.id);
          const currentMediaSelectedArr = currentMediaArr.media.filter(media=> media.isChecked===true)
          const currentMediaSelectedArrWithoutRepeat = findCurrentMediaGroupWithoutRepeat(currentMediaSelectedArr)
          const currentMediaIsSelected = currentMediaArr && currentMediaArr.mediaIsSelected;

          const currentFrequency = flightsFrequencyArr.find(item => item.flight === flight.id);
          const currentFrequencyIsSelected = currentFrequency.frequency !== '';

          const currentFlightsBudget_Coverage = flightsBudget_CoverageArr.find(item => item.flight === flight.id);
          const currentBudgetIsSelected = currentFlightsBudget_Coverage && currentFlightsBudget_Coverage.budget_coverage.find(item => item.isChecked === true) && currentFlightsBudget_Coverage.budget_coverage.find(item => item.isChecked === true).isChecked;
         
          const currentBudgetValueIsSelected = currentFlightsBudget_Coverage && currentFlightsBudget_Coverage.budget_coverage.find(item => item.isChecked === true) && currentFlightsBudget_Coverage.budget_coverage.find(item => item.isChecked === true).value !== 0;
          const currentMediaStartBudgetChanged = currentMediaArr.media.find(media => media.startBudget !== 0);
          const currentMediaFinishBudgetChanged = currentMediaArr.media.find(media => media.finishBudget !== 999999999);

          return (
            <div className={s.flightBlock} key={flight.id}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={s.flight}>ФЛАЙТ {flight.flight}</div>
                <button
                  className={s.deleteBtn}
                  onClick={() => {
                    deleteFlight(flight.flight, flight.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size='2x' style={{ color: '#c4c4c4' }} />
                </button>
              </div>
              <div className={s.selectBlock}>
                <div className={targetAudienceFlightsIsSelected && flightsGeoIsSelected ? '' : s.text_muted}>                  
                  <FlightsCampaignType flight={flight.id} isDisabled={targetAudienceFlightsIsSelected && flightsGeoIsSelected ? false : true} />                
                </div>

                <div className={currentCTIsSelected ? '' : s.text_muted}>
                  <Accordion flight={flight.id} isDisabled={currentCTIsSelected ? false : true} selectValue={currentPeriod.startDate === 0 ? '' : `${moment.utc(currentPeriod.startDate).format('DD.MM')}- ${moment.utc(currentPeriod.finishDate).format('DD.MM')}`}>
                    <FlightsPeriod isDisabled={currentCTIsSelected ? false : true} flight={flight.id} />
                  </Accordion>
                </div>

                <div className={currentPeriodIsSelected && flightsGeoIsSelected && currentCTIsSelected && targetAudienceFlightsIsSelected ? '' : s.text_muted}>
                  <Accordion flight={flight.id} selectValue={currentMediaIsSelected ? currentMediaSelectedArrWithoutRepeat.map(i=> ` ${i} `) : ''} isDisabled={currentPeriodIsSelected && flightsGeoIsSelected && currentCTIsSelected && targetAudienceFlightsIsSelected ? false : true}>
                    <FlightsMediaChoice flight={flight.id} />
                  </Accordion>
                </div>
                <div className={currentMediaIsSelected ? '' : s.text_muted}>
                  <FlightsFrequency isDisabled={currentMediaIsSelected ? false : true} flight={flight.id} />
                </div>

                <div className={currentFrequencyIsSelected ? '' : s.text_muted}>                  
                  <FlightsBudget flight={flight.id} isDisabled={currentFrequencyIsSelected ? false : true} />                 
                </div>

                <div className={currentBudgetIsSelected ? '' : s.text_muted}>
                  <FlightsBudgetValue isDisabled={currentBudgetIsSelected ? false : true} flight={flight.id} />
                </div>

                <div className={currentBudgetValueIsSelected ? '' : s.text_muted}>
                  <Accordion selectValue={currentMediaStartBudgetChanged || currentMediaFinishBudgetChanged ? 'Ограничения есть' : 'Ограничений нет'} flight={flight.id} isDisabled={currentBudgetValueIsSelected ? false : true} detailsPosition={'left'}>
                    <FlightsBudgetConstraints flight={flight.id} />
                  </Accordion>
                </div>
              </div>
            </div>
          );
        })}      
      </div>
    </div>
  );
});
