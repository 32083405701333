import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { HomePage } from './ui/pages/HomePage/HomePage';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { InstructionPage } from './ui/pages/InstructionPage/InstructionPage';
import { Step0Page } from './ui/pages/Step0Page/Step0Page';
import { Step1Page } from './ui/pages/Step1Page/Step1Page';
import { Step2Page } from './ui/pages/Step2Page/Step2Page';
import { Step3Page } from './ui/pages/Step3Page/Step3Page';
import { Step4Page } from './ui/pages/Step4Page/Step4Page';
import { Step5Page } from './ui/pages/Step5Page/Step5Page';
import { Step1SeveralFlightsPage } from './ui/pages/Step1SeveralFlightsPage/Step1SeveralFlightsPage';
import { Step2SeveralFlightsPage } from './ui/pages/Step2SeveralFlightsPage/Step2SeveralFlightsPage';
import { Step3SeveralFlightsPage } from './ui/pages/Step3SeveralFlightsPage/Step3SeveralFlightsPage';
import { Step4SeveralFlightsPage } from './ui/pages/Step4SeveralFlightsPage/Step4SeveralFlightsPage';
import { Step5SeveralFlightsPage } from './ui/pages/Step5SeveralFlightsPage/Step5SeveralFlightsPage';
import { SigninPage } from './ui/pages/SigninPage/SigninPage';
import { setStartData } from './bll/inputParameters_reducer';
import { Progress } from './ui/components/Common/Progress/Progress';
import { Warning } from './ui/components/Common/Warning/Warning';
// import {  getStartIp } from './bll/app_reducer';

export const App = React.memo(() => {
  const dispatch = useDispatch();
  const media = useSelector(state => state.input.media);
  const targetAudienceData = useSelector(state => state.input.targetAudienceData);
  const status = useSelector(state => state.app.status);
  const error = useSelector(state => state.app.error);
  const inputDataChanged = useSelector(state => state.input.inputDataChanged);
  const [errorWarning, setErrorWarning] = useState(false);

  const chartsData = useSelector(state => state.charts.chartsData);
  const optic_media_mix_graph_data = useSelector(state => state.charts.optic_media_mix_graph_data);
  const donutData = useSelector(state => state.charts.donutData);
  const contributionOfEachMedia_data = useSelector(state => state.charts.contributionOfEachMedia_data);
  const allChartsDataGrowth = useSelector(state => state.charts2.allChartsDataGrowth);
  const allChartsDataCoverage = useSelector(state => state.charts2.allChartsDataCoverage);
  const knowledgeArr = useSelector(state => state.charts2.knowledgeArr);
  const investment_coverage_graph_data = useSelector(state => state.charts2.investment_coverage_graph_data);
  const investment_growth_graph_data = useSelector(state => state.charts2.investment_growth_graph_data);
  const postData = useSelector(state => state.input.postData);
  const mainMedia = useSelector(state => state.charts.mainMedia);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);

  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsCampaignTypesArr = useSelector(state => state.flights.flightsCampaignTypesArr);
  const targetAudienceFlights = useSelector(state => state.flights.targetAudienceFlights);
  const flightsGeo = useSelector(state => state.flights.flightsGeo);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsBudget_CoverageArr = useSelector(state => state.flights.flightsBudget_CoverageArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);

  const isInitialized = useSelector(state => state.app.isInitialized);
  const history = useHistory();

  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem('mixApp'));
  //   const maxData = data && data.timestamp + 86400000;
  //   if (Date.now() < maxData) {
  //     dispatch(setInitialized(true));
  //   } else if (Date.now() > maxData) {
  //     dispatch(setInitialized(false));
  //     localStorage.removeItem('mixApp');
  //   }
  // }, [dispatch]);
  // useEffect(() => {
  //   dispatch(getStartIp());
  // }, [dispatch]);

  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [history, isInitialized]);

  useEffect(() => {
    dispatch(setStartData());
  }, [dispatch]);
  useEffect(() => {
    error !== null && setErrorWarning(true);
  }, [dispatch, error]);
  return (
    <div className='App'>
      <Warning text={error} warningVisible={errorWarning} setWarningVisible={setErrorWarning} />
      {status === 'loading' && <Progress />}
      <Switch>
        <Route exact path={'/'} render={() => <HomePage />} />
        <Route exact path={'/step0'} render={() => <Step0Page />} />
        <Route exact path={'/step1'} render={() => <Step1Page targetAudienceData={targetAudienceData} />} />
        <Route exact path={'/step2'} render={() => <Step2Page media={media} />} />
        <Route exact path={'/step3'} render={() => <Step3Page inputDataChanged={inputDataChanged} />} />
        <Route exact path={'/step4'} render={() => <Step4Page chartsData={chartsData} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} postData={postData} mainMedia={mainMedia} dataFromXL={dataFromXL} />} />
        <Route exact path={'/step5'} render={() => <Step5Page allChartsDataGrowth={allChartsDataGrowth} allChartsDataCoverage={allChartsDataCoverage} investment_coverage_graph_data={investment_coverage_graph_data} investment_growth_graph_data={investment_growth_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} postData={postData} mainMedia={mainMedia} chartsData={chartsData} dataFromXL={dataFromXL} knowledgeArr={knowledgeArr} />} />
        <Route exact path={'/flightsStep1'} render={() => <Step1SeveralFlightsPage targetAudienceData={targetAudienceData} />} />
        <Route exact path={'/flightsStep2'} render={() => <Step2SeveralFlightsPage />} />
        <Route exact path={'/flightsStep3'} render={() => <Step3SeveralFlightsPage flightArr={flightArr} flightsCampaignTypesArr={flightsCampaignTypesArr} targetAudienceFlights={targetAudienceFlights} flightsGeo={flightsGeo} flightsFrequencyArr={flightsFrequencyArr} flightsBudget_CoverageArr={flightsBudget_CoverageArr} flightsPeriod={flightsPeriod} />} />
        <Route exact path={'/flightsStep4/:flightId?'} render={() => <Step4SeveralFlightsPage />} />
        <Route exact path={'/flightsStep5'} render={() => <Step5SeveralFlightsPage />} />
        <Route exact path={'/signin'} render={() => <SigninPage />} />
        <Route exact path={'/instruction'} render={() => <InstructionPage />} />
        <Route path={'/404'} render={() => <h1>404: PAGE NOT FOUND</h1>} />
        <Redirect from={'*'} to={'/'}></Redirect>
      </Switch>
    </div>
  );
});
