import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Question } from '../../components/Question/Question';
import s from './InstructionPage.module.css';
import { Link } from 'react-scroll';
import { ScrollToTop } from '../../components/Common/ScrollToTop/ScrollToTop';
import image1 from '../../../assets/images/image001.png';
import image2 from '../../../assets/images/image002.png';
import image3 from '../../../assets/images/questionAboutKnowledge.png';
import image4 from '../../../assets/images/movieKnowledge.png';
import { Header } from '../../../ui/components/Header/Header';

export const InstructionPage = React.memo(() => {
  const history = useHistory();
  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.background}>
        <div className={s.linkWrapper}>
          <div className={s.link}>
            <Link to='instructionBlock' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
              Инструкция
            </Link>
          </div>
          <div className={s.link}>
            <Link to='questionsBlock' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
              Часто задаваемые вопросы:
            </Link>
          </div>
          <div className={s.questionLinksWrapper}>
            <div className={s.link}>
              <Link to='question1' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Какие данные используются для вычислений и рекомендаций? аудиторные данные, данные для рекомендаций по медиамиксу, данные по потреблению медиа.
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question2' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как система считает пересечения медиа для совокупного охвата и определяет долю уникального охвата для медиа?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question3' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Зачем нужно использовать качество контакта?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question4' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как учитывается качество контакта при расчете совокупного охвата?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question5' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Откуда брать значения для качества контакта?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question6' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как строятся дополнительные варианты на графике сравнения медиа-миксов?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question7' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как рассчитывается знание креатива?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question8' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как проверяли дефолтные расчеты знания креатива?
              </Link>
            </div>
          </div>
        </div>
        <div className={s.wrapper}>
          <div id={'instructionBlock'} className={s.instruction}>
            <h1 className={s.title}>КАК РАБОТАЕТ ИНСТРУМЕНТ </h1>
            <p>
              Инструмент предназначен для планирований медиамикса для одного флайта, <b>а также для нескольких флайтов в течение одного года</b>.
            </p>
            <p>Процесс расчета рекомендованного сплита медиа состоит из пяти шагов:</p>
            <p>На главном экране вы можете скачать слайд с описанием инструмента в клиентском формате, перейти к созданию нового проекта или загрузить ранее произведенные с помощью Flight MediaMix Calculator расчеты и продолжить работу с ними.</p>
            <p>При выборе опции «создать новый проект» вы перейдете в окно, где будет предложено 2 варианта нового проекта: </p>
            <ul className={s.sublist}>
              <li>спланировать медиа микс для одного флайта,</li>
              <li>спланировать медиа микс для нескольких флайтов в течение одного года.</li>
            </ul>
            <p>
              <u>ПЛАНИРОВАНИЕ ОДНОГО ФЛАЙТА:</u>
            </p>
            <ul className={s.stepsList}>
              <li>На первом шаге вы можете задать необходимые параметры целевой аудитории:</li>
              <ul className={s.sublist}>
                <li> выбрать из доступных опций соц.-дем. настройки по полу и возрасту,</li>
                <li>Задать параметры ГЕО (Россия, Москва или город миллионник),</li>
                <li>Выбрать параметры фалйта (промо или имидж). От этой настройки будут зависеть рекомендованные приоритетные медиа и их качество контакта.</li>
              </ul>
              <li>На втором шаге вы выбираете медиа:</li>
              <ul className={s.sublist}>
                <li>Вы можете выбрать группу медиа (Например, при выборе группы «Диджитал» автоматически выберутся все диджитал медиа),</li>
                <li>Задаете стоимость контакта (колонка «СPT / СPM»). По умолчанию стоят дефолтные средние значения CPT/CPM, для месяца, выбранного в правом верхнем углу окна (по умолчанию – июнь).</li>
                {/* <li>Задаете параметр «Качество контакта» по шкале от 0% до 100%. Этот параметр задается на основе вашей экспертной оценки в соответствии с целями флайта. Показатель отражает ваше видение, насколько качество контакта выбранного медиа отвечает заданной цели флайта, например, цели по охвату. Коэффициенты рекомендованные, выведены на базе анализа опыта агентства. 100% качества для TV сейчас взято для 20’’ хроно</li> */}
                <li>Задаете параметр «Качество контакта» по шкале от 0 до 1. Этот параметр задается на основе вашей экспертной оценки в соответствии с целями флайта. Показатель отражает ваше видение, насколько качество контакта выбранного медиа отвечает заданной цели флайта, например, цели по охвату, где 1– это 100% соответствия цели.</li>
              </ul>
              <li>На третьем шаге вы задаете параметры для флайта:</li>
              <ul className={s.sublist}>
                <li>Под какой параметр вы хотите получить рекомендации. Под бюджет – если у вас есть ограничения по бюджету. Под охват – если у вас есть установленный KPI по достижению охвата,</li>
                <li> Эффективная частота – под эту частоту вы получите расчет и реко на графиках,</li>
                <li>Если у вас есть ограничения по бюджету на какие-либо медиа – вы можете задать их на этом шаге и система будет учитывать их в рекомендациях. Например, у вас уже есть TV сделка.</li>
              </ul>
              <li>Шаг 4. После того как вы задали все вводные с 1 по 3 шаг, система предлагает вам расчеты и рекомендации в виде трех графиков:</li>
              <ul className={s.sublist}>
                <li> Рекомендуемый медиамикс – на графике отображаются рекомендации по бюджету или долям бюджета для выбранных медиа, или для выбранных групп медиа. Вы можете задавать настройки для выбора параметров, переключая «ползунок» в правом верхнем углу графика. </li>
                <li> Вклад каждого медиа в совокупный охват – на графике отображается % уникального охвата, от каждого медиа или группы медиа для заданного флайта. Вы можете выбрать «медиа» или «группы медиа» в правом верхнем углу графика.</li>
                <li> Сравнение различных медиамиксов на идентичный бюджет и их влияние на совокупный охват – визуальное отображение нескольких опций медиамикса, в том числе оптимальной рекомендованной.</li>
              </ul>
              <p>На этом этапе вы можете выгрузить полученные расчеты и рекомендации или перейти на следующий этап, где система рассчитает прогноз и рекомендации по изменению медиамикса в зависимости от изменения 2х переменных: бюджета и охвата</p>
              <p>На этом этапе вы можете: </p>
              <ul className={s.sublist}>
                <li>«Cохранить проект» - т.е. выгрузить excel файл с расчетами в техническом виде для последующей загрузки в систему на главном экране для последующей работы с проектом.</li>
                <li>«Выгрузить расчеты» - т.е. выгрузить два файла для подготовки отчета клиенту: ppt c графиками для последующего оформления и excel с расчетами. Данные файлы не подлежат дальнейшей загрузке в систему для последующей работы с проектом. </li>
              </ul>
              <li>Шаг 5. График на этом шаге формирует прогнозные значения с определенным шагом роста охвата и бюджета. На базе расчетов вы можете составить рекомендации по параметрам бюджета, медиамикса и совокупного охвата, если нет конкретных заданных параметров и KPIs на флайт. Так как для построения графиков используется большой массив данных, это занимает достаточно большое количество времени. </li>
              <p>График «Влияние прироста инвестиций в рекламу на медиамикс и совокупный охват» показывает, как изменяется темп набора совокупного охвата и медиамикс при определенном шаге роста бюджета.</p>
            </ul>

            <p>
              <u>ПЛАНИРОВАНИЕ НЕСКОЛЬКИХ ФЛАЙТОВ В ТЕЧЕНИЕ ОДНОГО ГОДА:</u>
            </p>
            <ul className={s.stepsList}>
              <li>На первом шаге вы можете задать количество флайтов и все необходимые вводные для них:</li>
              <ul className={s.sublist}>
                <li>Выбрать из списка доступных опций параметры целевой аудитории (соц. – дем.)</li>
                <li>Задать параметры ГЕО (Россия, Москва или город миллионник)</li>
                <li>Выбрать для каждого флайта типа кампании, период, используемые медиа, эффективную частоту, цель оптимизации и ее значение и ограничение бюджета по каждому медиа при необходимости</li>
                <li>По умолчанию система задает 1 флайт. Вы можете задать желаемое количество флайтов, нажав на кнопку «добавить флайт». Также есть возможность удалить лишние флайты, нажав на иконку корзины напротив соответствующего флайта.</li>
                <li>В нижнем левом углу экрана есть галочка. По умолчанию она включена, и система учитывает заданные ограничения емкости инвентаря каждого медиаканала для выбранного количества дней. Вы можете отключить это ограничение, нажав на эту галочку.</li>
              </ul>
              <p>Все незаполненные поля с параметрами подсвечиваются розовым цветом, заполненные – зеленым.</p>
              <li>На втором шаге необходимо указать параметры для выбранных медиа</li>
              <ul className={s.sublist}>
                <li>CPT / CPM</li>
                <li>Качество контакта</li>
                <li>Чтобы указать CPT/CPM для всех месяцев под флайты вы можете 1) под таблицей параметров медиа выбрать месяц для указанного CPT/CPM, в данном случае система пересчитает стоимость для остальных месяцев с учетом примерных коэффициентов для сезонных наценок, 2) проставить вручную CPT/CPM для всех месяцев, нажав на одноименную кнопку. </li>
              </ul>
              <li>Шаг 3. Результаты планирования. Система отображает для каждого флайта: </li>
              <ul className={s.sublist}>
                <li>Основные заданные вводные для каждого флайта</li>
                <li>Рекомендованный сплит бюджета для заданного медиа микса по каждому флайту</li>
                <li>Бюджет каждого флайта + общий бюджет всех флайтов</li>
                <li>Совокупный охват для каждого флайта</li>
                <li>
                  Возможность перейти на страницу с детализированными рекомендациями для каждого флайта, где система предлагает вам расчеты и рекомендации в виде трех графиков:
                  <ul className={s.stepsList}>
                    <li> Рекомендуемый медиамикс – на графике отображаются рекомендации по бюджету или долям бюджета для выбранных медиа, или для выбранных групп медиа. Вы можете задавать настройки для выбора параметров, переключая «ползунок» в правом верхнем углу графика. </li>
                    <li> Вклад каждого медиа в совокупный охват – на графике отображается % уникального охвата, от каждого медиа или группы медиа для заданного флайта. Вы можете выбрать «медиа» или «группы медиа» в правом верхнем углу графика.</li>
                    <li> Сравнение различных медиамиксов на идентичный бюджет и их влияние на совокупный охват – визуальное отображение нескольких опций медиамикса, в том числе оптимальной рекомендованной.</li>
                  </ul>
                </li>
              </ul>
              <p>На этом этапе вы можете: </p>
              <ul className={s.sublist}>
                <li>
                  «Сохранить проект» - т.е. выгрузить excel файл с расчетами в техническом виде <b>для последующей загрузки в систему</b> на главном экране для последующей работы с проектом.
                </li>
                <li>
                  «Выгрузить расчеты» - т.е. выгрузить два файла <b>для подготовки отчета клиенту:</b> ppt c графиками для последующего оформления и excel с расчетами. Данные файлы <b>не подлежат дальнейшей загрузке в систему</b> для последующей работы с проектом.
                </li>
              </ul>
            </ul>
          </div>
          <div id={'questionsBlock'} className={s.questions}>
            <h1 className={s.title}>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h1>
            <div className={s.questionsBox}>
              <div id={'question1'}>
                <Question question={'Какие данные используются для вычислений и рекомендаций?  аудиторные данные, данные для рекомендаций по медиамиксу, данные по потреблению медиа.'}>
                  <p>Данные о построении охвата (Медиа – Источник):</p>
                  <ul className={s.stepsList}>
                    <li> ТВ – TV planet (Mediascope)</li>
                    <li>Radio – SuperNova (Mediascope)</li>
                    <li>OOH – OdaPlan (Espar)</li>
                    <li>Digital – MediaScope digital panel, данные подрядчиков, база результатов размещений MI </li>
                  </ul>
                  <p>Данные о кросс-медиа потреблении: TGI/Marketing index - media 360 Данные об относительном качестве контакта в медиа: дефолтные данные на основе моделей MI знания рекламы и имиджевых показателей марок</p>
                </Question>
              </div>
              <div id={'question2'}>
                <Question question={'Как система считает пересечения медиа для совокупного охвата и определяет долю уникального охвата для медиа?'}>
                  <p>Общий охват кампании рассчитывается как сумма охватов частей ЦА с различным кросс-медиа потреблением. Например, для выбранных двух медиа ТВ и Радио будем рассчитывать охват для трех частей нашей ЦА. Пусть охват ТВ размещения 80% и охват радио 50% ЦА, тогда охваты для трех частей рассчитываются так:</p>
                  <div style={{ display: 'flex' }}>
                    <img src={image1} alt='image1' />
                    <div>
                      <ul className={s.stepsList}>
                        <li> «Только Радио»: размер аудитории * охват Радио размещения = 15% * 50%=7.5%</li>
                        <li>«ТВ или Радио»: размер аудитории * охват Радио и ТВ размещения = 35% * (1-(1-80%)*(1-50%))=31.5%</li>
                        <li>«Только ТВ»: размер аудитории * охват ТВ размещения = 50% * 80%=40%</li>
                      </ul>
                    </div>
                  </div>

                  <p>Получаем общий охват 7.5%+31.5%+40%=79% Уникальный охват ТВ 40%, уникальный охват Радио 7.5%. Для случая выбора 15 медиа (максимум, заложенный в инструменте) расчет общего охвата производится по 32 767 частям ЦА.</p>
                </Question>
              </div>
              <div id={'question3'}>
                <Question question={'Зачем нужно использовать качество контакта?'}>
                  <p>Интуитивно понятно, что контакт с видео рекламой даст больший эффект, чем контакт с баннером в наружной рекламе или в диджитал при сопоставимом качестве креатива. Моделирование знания рекламы показывает устойчивые отношения качества контакта для типа рекламного контента:</p>
                  <ul>
                    <li>видео к баннеру – 1:4</li>
                    <li>видео к аудио – 1:3</li>
                  </ul>
                  <p>Для других KPI получаем другие коэффициенты, например, для построения имиджа отношения качества контакта видео к баннеру уже 1:8</p>
                  <p>Получается, когда мы говорим об охвате ТВ на эффективной частоте 5+, для ООН мы смотрим охват на частоте 20+ при качестве контакта 1:4 или ООН имеет качество контакта 25% от ТВ.</p>
                  <p>В инструменте для удобства за 100% зафиксировано качество контакта со стандартным ТВ роликом 20 сек</p>
                </Question>
              </div>
              <div id={'question4'}>
                <Question question={'Как учитывается качество контакта при расчете совокупного охвата?'}>
                  <p>До расчета общего охвата размещения, распределение охвата по частотам каждого медиа приводится к «общему знаменателю», к ТВ эквиваленту частоты. Например, пусть у медиа качество контакта относительно ТВ 1:2 или 50%, тогда переходим от реального распределения охватов по частотам к частотам ТВ эквиваленту следующим образом</p>

                  <div className={s.tableRow}>
                    <div>Частота реальная</div>
                    <div>Частота ТВ эквивалент</div>
                    <div>Охват</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>1</div>
                    <div>1</div>
                    <div>5.0%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>2</div>
                    <div>1</div>
                    <div>4.5%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>3</div>
                    <div>1</div>
                    <div>4.1%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>4</div>
                    <div>2</div>
                    <div>3.6%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>5</div>
                    <div>2</div>
                    <div>3.3%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>6</div>
                    <div>3</div>
                    <div>3.0%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>7</div>
                    <div>3</div>
                    <div>2.7%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>8</div>
                    <div>4</div>
                    <div>2.4%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>9</div>
                    <div>4</div>
                    <div>2.2%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>10</div>
                    <div>5</div>
                    <div>1.9%</div>
                  </div>
                  <div className={s.tableRow}>
                    <div>11</div>
                    <div>5</div>
                    <div>0.0%</div>
                  </div>
                  <img src={image2} alt='image22' />
                </Question>
              </div>
              <div id={'question5'}>
                <Question question={'Откуда брать значения для качества контакта?'}>
                  <p>Дефолтные значения, которые предлагает инструмент — это опыт агентства моделирования показателей «знание рекламы» и имидж марки. Пользователь может вставлять другие значения на основании собственного моделирования или по согласованию с клиентом</p>
                </Question>
              </div>
              <div id={'question6'}>
                <Question question={'Как строятся дополнительные варианты на графике сравнения медиа-миксов?'}>
                  <ul className={s.stepsList}>
                    <li>Выбираем медиа с макс бюджетом.</li>
                    <li>Берем разницу между бюджетом этого медиа и нижней границей, делим на 5. Получаем шаг изменения бюджета</li>
                    <li>Если шаг меньше 0.1% Общего бюджета то переходим к следующему по величине медиа и повторяем шаги с 1 по 3</li>
                    <li>Если не осталось ни одного медиа то все данные заполняем текущим сплитом и конец. </li>
                    <li>В случае получение подходящего шага Основное медиа уменьшаем на величину шага</li>
                    <li>Нормируем бюджеты остальных медиа к 1</li>
                    <li>Прибавляем к медиа часть шага соответствующую их доле.</li>
                    <li>Берем разницу между верхними границами и получившимися бюджетами</li>
                    <li>Если все не отрицательные то сплит получен</li>
                    <li>Если есть отрицательные, суммируем эти значения * -1 ( это остаток)</li>
                    <li>Выбираем медиа с положительными значениями</li>
                    <li>Добавляем в первое медиа полученный остаток</li>
                    <li>Если прошла по границам то все, если нет до добавляем сколько можем,</li>
                    <li>Остаток уменьшаем на эту величину и переходим к следующему медиа и повторяем 12-14</li>
                    <li>Если медиа закончились а остаток больше 0, то прибавляем его к основному медиа.</li>
                  </ul>
                </Question>
              </div>
              <div id={'question7'}>
                <Question question={'Как рассчитывается знание креатива?'}>
                  <div style={{ display: 'flex' }}>
                    <img src={image3} alt='image3' style={{ width: '500px', height: '400px' }} />
                    <div>
                      <p>Вместо максимизации охвата на заданной частоте, можем оценить «важность» охвата на каждой частоте для некоторого KPI. И максимизировать KPI, учитывая эффект от охвата на всех частотах.</p>
                      <p>Мы построили общий Reach-Frequency нашего медиа микса, преобразовав частоты к ТВ эквиваленту качества, это значит что мы говорим про видео ролики. Для аналогичного по качеству контакта ОЛВ мы можем напрямую замерить вероятность сказать знаю ролик в зависимости от частоты контакта с ним.</p>
                      <p>Тогда знание ролика – это сумма по всем частотам охватов на каждой частоте, умноженных на «вероятность сказать знаю» на этой частоте. И мы будем получать медиа миксы, которые будут максимизировать «знание ролика».</p>
                      <p>В данный момент в калькуляторе используются средние данные по знанию роликов, замеренные для ОЛВ размещений.</p>
                    </div>
                  </div>
                </Question>
              </div>
              <div id={'question8'}>
                <Question question={'Как проверяли дефолтные расчеты знания креатива?'}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ width: 400, marginRight: 10 }}>Дефолтные значения конверсий количества контактов в знание креатива для различных Целевых Аудиторий проверили, сопоставив расчеты знания в калькуляторе и замеры знания роликов в опросах BHT. На знание креатива, конечно, влияют не только параметры размещения, но в среднем результаты расчетов в калькуляторе совпадают с замерами.</p>
                      <div style={{ marginBottom: 20 }}>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Категория</div>
                          <div>N роликов</div>
                          <div>Замер знания</div>
                          <div>Оценка в калькуляторе</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Телеком</div>
                          <div>358</div>
                          <div>54%</div>
                          <div>46%</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Колбаса</div>
                          <div>29</div>
                          <div>27%</div>
                          <div>29%</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Детское питание</div>
                          <div>17</div>
                          <div>32%</div>
                          <div>29%</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Банки</div>
                          <div>38</div>
                          <div>32%</div>
                          <div>38%</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Платежные системы</div>
                          <div>16</div>
                          <div>33%</div>
                          <div>41%</div>
                        </div>
                        <div className={s.tableRow}>
                          <div style={{ width: 160 }}>Всего</div>
                          <div>458</div>
                          <div>36%</div>
                          <div>37%</div>
                        </div>
                      </div>
                    </div>
                    <img src={image4} alt='image4' style={{ width: '880px', height: '300px' }} />
                  </div>
                </Question>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    </>
  );
});
