import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import s from './Step3SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { ExportXLSFlights } from '../../components/ExportXLSFlights/ExportXLSFlights';
import { ExportPPTFlights } from '../../components/ExportPPTFlights/ExportPPTFlights';
import { LoadingButton } from '../../components/Common/LoadingButton/LoadingButton';
import { LinePieChart } from '../../components/Charts/LinePieChart/LinePieChart';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { StartButton } from '../../components/Common/StartButton/StartButton';
import { SaveXLSFlights } from '../../components/SaveXLSFlights/SaveXLSFlights';
import { setCurrentPathname } from '../../../bll/app_reducer';
import TA from '../../../assets/images/TA.png';
import geo from '../../../assets/images/geo.png';
import { makeGeoTitle } from '../../../helpers/flightTable_helpers';
import { findValueView, roundDependingOnTheOrder } from '../../../helpers/mediaChoiceTable_helpers';
import { round } from '../../../helpers/chart_helpers';
import download from '../../../assets/images/downloadNew.png';
import flow from '../../../assets/images/notebook.png';

export const Step3SeveralFlightsPage = React.memo(props => {
  const { flightArr, flightsCampaignTypesArr, targetAudienceFlights, flightsGeo, flightsFrequencyArr, flightsPeriod } = props;
  const flightsGeoPOST = flightsGeo.find(geo => geo.isChecked) && makeGeoTitle(flightsGeo);
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);

  const budgets = [];
  let sumBudgets = [];

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onClickBackButton = () => {
    history.push('/flightsStep2');
  };
  const formAFlowchart = () => {
    history.push('/flightsStep5');
  };
  const goToDetailPage = flight => {
    history.push(`/flightsStep4/${flight}`);
  };
  const [isExportPPTFlights, setIsExportPPTFlights] = useState(false);
  const [loadingAccordionFlightsCollapsed, setLoadingAccordionFlightsCollapsed] = useState(true);

  const onClickPowerPointFlightsBtn = () => {
    setIsExportPPTFlights(true);
  };
  const onClickUnLoadFlightsBtn = () => {
    setIsExportPPTFlights(false);
    setLoadingAccordionFlightsCollapsed(false);
  };
  const onClickAnyWhere = e => {
    if (e.target.id === 'downloadBtn' || e.target.id === 'downloadBtnImg' || e.target.id === 'downloadBtnDiv' || e.target.id === 'downloadBtnTextUnload' || e.target.id === 'downloadBtnTextСalculations' || e.target.id === 'loadingButtonPowerPoint' || e.target.id === 'loadingButtonExcelFile') {
      setLoadingAccordionFlightsCollapsed(false);
    } else {
      setLoadingAccordionFlightsCollapsed(true);
    }
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const flightsChartsAllColors = [];
  flightsChartsData.map(i => {
    i.linePieChartData.datasets.map(c => {
      !flightsChartsAllColors.find(a => Object.keys(a)[0] === c.label) && flightsChartsAllColors.push({ [c.label]: c.backgroundColor });

      return c;
    });

    return i;
  });
  useEffect(() => {
    flightsChartsAllColors.map(item => (document.getElementById(Object.keys(item)[0]).style.backgroundColor = Object.values(item)[0]));
  }, [flightsChartsAllColors]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step3Wrapper} onClick={onClickAnyWhere}>
        <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />

        <div className={s.contentBlock}>
          <h1>Результат планирования</h1>

          <div className={s.generalInputDataBlock}>
            <div className={s.TABlock}>
              <div>
                <span style={{ fontWeight: 'bold', color: 'white' }}> Целевая Аудитория </span>
                <br />
                <span style={{ color: 'white' }}> для всех флайтов</span>
              </div>
              <div>
                <img src={TA} alt='TA' style={{ width: '60px', height: '60px' }} />
              </div>
              <div className={s.TA}>{targetAudienceFlights}</div>
            </div>

            <div className={s.geoBlock}>
              <div>
                <span style={{ fontWeight: 'bold' }}> ГЕО</span> <br />
                для всех флайтов
              </div>
              <div>
                <img src={geo} alt='TA' style={{ width: '50px', height: '50px' }} />
              </div>
              <div className={s.TA}>{flightsGeoPOST}</div>
            </div>
          </div>

          <div style={{ minHeight: '250px', marginTop: '20px' }}>
            <div className={s.tableTitle}>Рекомендованный сплит бюджета:</div>
            <div className={s.table}>
              {flightArr.map((flight, i) => {
                const campaignType = flightsCampaignTypesArr.find(item => item.flight === flight.id) && flightsCampaignTypesArr.find(item => item.flight === flight.id).campaignType;
                const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
                const startDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).startDate;
                const finishDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).finishDate;
                const frequency = flightsFrequencyArr.find(item => item.flight === flight.id) && flightsFrequencyArr.find(item => item.flight === flight.id).frequency;
                const knowledgeGeneral = flightsChartsData.find(item => item.flight === flight.id) && flightsChartsData.find(item => item.flight === flight.id).knowledgeGeneral;

                const budgetPOST =
                  flightsChartsData.find(item => item.flight === flight.id) &&
                  flightsChartsData
                    .find(item => item.flight === flight.id)
                    .donutData.datasets[0].data.reduce(function (a, b) {
                      return +a + +b;
                    });
                budgets.push(budgetPOST);
                const coveragePOST = flightsChartsData.find(item => item.flight === flight.id) && flightsChartsData.find(item => item.flight === flight.id).optic_media_mix_graph_data.datasets[0].data[4];

                sumBudgets = budgets.reduce(function (accumulator, currentValue) {
                  return accumulator + +roundDependingOnTheOrder(+currentValue);
                }, 0);
                return (
                  <div key={i} className={s.flightBlock}>
                    <div className={s.flight}>
                      <div style={{ fontWeight: 'bold' }}>ФЛАЙТ {flight.flight}</div>
                      <div>
                        {campaignTypePOST}, {moment.utc(startDate).format('DD.MM')}-{moment.utc(finishDate).format('DD.MM')},
                      </div>
                      <div>f={frequency}</div>
                    </div>
                    {flightsChartsData.length > 0 && <LinePieChart flight={flight.id} flightsChartsData={flightsChartsData} />}

                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Бюджет <br />
                        на флайт: <br />
                        <span style={{ fontWeight: 'bold' }}> {findValueView(budgetPOST, 'roundBudget')} ₽</span>
                      </div>
                    )}
                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Знание <br />
                        на флайт: <br />
                        <span style={{ fontWeight: 'bold' }}> {round(knowledgeGeneral, 1)}%</span>
                      </div>
                    )}
                    {flightsChartsData.length === flightArr.length && (
                      <div className={s.card}>
                        Совокупный охват <br />
                        f={frequency} на флайт:
                        <br />
                        <span style={{ fontWeight: 'bold' }}>{findValueView(coveragePOST, 'roundCoverage')} %</span>
                      </div>
                    )}
                    <StepButton
                      onClick={() => {
                        goToDetailPage(flight.id);
                      }}
                      title={'Перейти на\n страницу с\n детализацией'}
                      buttonColor={'blue'}
                    />
                  </div>
                );
              })}
            </div>
            <div className={s.customLegendWrapper}>
              {flightsChartsAllColors.map((item, i) => {
                return (
                  <div key={i} className={s.LegendItemWrapper}>
                    <div id={Object.keys(item)[0]} className={s.legend}></div>
                    <div>{Object.keys(item)[0]}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={s.bottomBlock}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
              <div className={s.bottomDelimiter}></div>
            </div>

            {flightsChartsData.length === flightArr.length && <div className={s.bottomCard}>Совокупный бюджет : {findValueView(sumBudgets, 'checkedMedia')} ₽</div>}

            <div className={s.btnBlock}>
              <SaveXLSFlights />

              <StartButton onClick={onClickUnLoadFlightsBtn} view={'red'} id={'downloadBtn'}>
                <img id='downloadBtnImg' src={download} alt='download' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

                <div id='downloadBtnDiv'>
                  <div id='downloadBtnTextUnload'>Выгрузить </div>
                  <div id='downloadBtnTextСalculations'> расчеты </div>
                </div>
              </StartButton>

              <StartButton onClick={formAFlowchart} view={'red'}>
                <img src={flow} alt='download' style={{ width: '30px', height: '30px', marginRight: '5px' }} />

                <div>
                  <div>Сформировать </div>
                  <div> флоучарт </div>
                </div>
              </StartButton>

              {!loadingAccordionFlightsCollapsed && (
                <div className={s.loadingBtnsWrapper}>
                  <div className={s.loadingBtns}>
                    <ExportXLSFlights />
                    <LoadingButton onClick={onClickPowerPointFlightsBtn} title={'PowerPoint'} id={'loadingButtonPowerPoint'} />
                    {isExportPPTFlights && <ExportPPTFlights />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
