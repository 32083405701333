import React from 'react';
import s from './StepButton.module.css';

export const StepButton = React.memo(props => {
  const { onClick, title, isDisabled, buttonColor, children } = props;
  return (
    <div>
      <button className={buttonColor === 'blue' ? s.blueButton : s.stepButton} onClick={onClick} disabled={isDisabled ? true : false}>
        {children}
        {title}
      </button>
    </div>
  );
});
