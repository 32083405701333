import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step4SeveralFlightsPage.module.css';
import { Header } from '../../components/Header/Header';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { OpticMediaMixGraph } from '../../components/Charts/OpticMediaMixGraph/OpticMediaMixGraph';
import { ToggleSwitch } from '../../components/Common/ToggleSwitch/TiggleSwitch';
import { PieChart } from '../../components/Charts/PieChart/PieChart';
import { ContributionOfEachMedia } from '../../components/Charts/ContributionOfEachMedia/ContributionOfEachMedia';
import { Select } from '../../components/Common/Select/Select';
import { Checkbox } from '../../components/Common/Checkbox/Checkbox';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { changeFlightPieChart, changeFlightContributionChart, changeFlightOpticChart, getFlightKnowledge } from '../../../bll/flights_charts_reducer';
import { findValueView } from '../../../helpers/mediaChoiceTable_helpers';

export const Step4SeveralFlightsPage = React.memo(props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentFlight = +useParams().flightId;

  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === currentFlight);

  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const currentDataFromXL = dataFromXL_Flights.filter(item => item.flight === String(currentFlight));

  const chooseMainMediaArr = (currentFlightChartsData, dataFromXL) => {
    if (flightsUpload) {
      const makeMainMediaArr = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mainMediaArr = makeMainMediaArr(currentDataFromXL.filter(item => item.MainMedia));
      return mainMediaArr;
    } else if (!flightsUpload && Object.keys(currentFlightChartsData).length > 0) {
      const mainMediaArr = Object.keys(currentFlightChartsData.data[0].Incremental_reach);
      return mainMediaArr;
    } else if (!flightsUpload && Object.keys(currentFlightChartsData).length === 0) {
      const makeMainMediaArr = arr => {
        let newArr = [];
        arr.map(item => {
          !newArr.find(arrItem => arrItem === item.MainMedia) && newArr.push(item.MainMedia);
          return item;
        });
        return newArr;
      };
      const mainMediaArr = makeMainMediaArr(currentDataFromXL.filter(item => item.MainMedia));
      return mainMediaArr;
    }
  };

  const ts_pieChart_grouping_checked = currentFlightChartsData.ts_pieChart_grouping_checked;
  const ts_pieChart_unit_checked = currentFlightChartsData.ts_pieChart_unit_checked;
  const ts_contribution_chart_grouping_checked = currentFlightChartsData.ts_contribution_chart_grouping_checked;
  const ts_contribution_chart_unit_checked = currentFlightChartsData.ts_contribution_chart_unit_checked;
  const ts_optic_media_mix_chart_grouping_checked = currentFlightChartsData.ts_optic_media_mix_chart_grouping_checked;
  const ts_optic_media_mix_chart_unit_checked = currentFlightChartsData.ts_optic_media_mix_chart_unit_checked;

  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const frequency = flightsFrequencyArr.find(item => item.flight === currentFlight).frequency;

  const unitDonut = currentFlightChartsData.unitDonut;
  const unitOptic = currentFlightChartsData.unitOptic;

  const donutData = currentFlightChartsData.donutData;
  const mainMedia = currentFlightChartsData.mainMedia;
  const contributionOfEachMedia_data = currentFlightChartsData.contributionOfEachMedia_data;
  const chartsData = currentFlightChartsData.chartsData;
  const dataFromXL = currentFlightChartsData.dataFromXL;
  const optic_media_mix_graph_data = currentFlightChartsData.optic_media_mix_graph_data;

  const knowledgeGeneralCategory = currentFlightChartsData.knowledgeGeneralCategory;
  const seleb = currentFlightChartsData.seleb;
  const knowledgeGeneral = currentFlightChartsData.knowledgeGeneral;
  const reachGeneral = currentFlightChartsData.reachGeneral;

  const onChangeKnowledgeGeneralCategory = selectItem => {
    dispatch(getFlightKnowledge(selectItem, seleb, currentFlight));
  };
  const onChangeSeleb = e => {
    const newSeleb = e.currentTarget.checked ? 1 : 0;
    dispatch(getFlightKnowledge(knowledgeGeneralCategory, newSeleb, currentFlight));
  };

  const onClickTSPie = (e, toggleName, flight) => {
    if (toggleName === 'toggleSwitch_pieChart_ruble_checked') {
      if (ts_pieChart_grouping_checked) {
        dispatch(changeFlightPieChart(e.currentTarget.checked ? '₽' : '%', 'all', flight));
      } else {
        dispatch(changeFlightPieChart(e.currentTarget.checked ? '₽' : '%', 'group', flight));
      }
    } else if (toggleName === 'toggleSwitch_pieChart_all_group_checked') {
      if (ts_pieChart_unit_checked) {
        dispatch(changeFlightPieChart('₽', e.currentTarget.checked ? 'all' : 'group', flight));
      } else {
        dispatch(changeFlightPieChart('%', e.currentTarget.checked ? 'all' : 'group', flight));
      }
    }
  };
  const onClickTSContribution = (e, toggleName, flight) => {
    if (toggleName === 'toggleSwitch_contribution_Chart_ruble_checked') {
      if (ts_contribution_chart_grouping_checked) {
        dispatch(changeFlightContributionChart(e.currentTarget.checked ? '₽' : '%', 'all', mainMedia, flight));
      } else {
        dispatch(changeFlightContributionChart(e.currentTarget.checked ? '₽' : '%', 'group', mainMedia, flight));
      }
    } else if (toggleName === 'toggleSwitch_contribution_Chart_all_group_checked') {
      if (ts_contribution_chart_unit_checked) {
        dispatch(changeFlightContributionChart('₽', e.currentTarget.checked ? 'all' : 'group', mainMedia, flight));
      } else {
        dispatch(changeFlightContributionChart('%', e.currentTarget.checked ? 'all' : 'group', mainMedia, flight));
      }
    }
  };
  const onClickTSOptic = (e, toggleName, flight) => {
    if (toggleName === 'toggleSwitch_optic_media_mix_graph_ruble_checked') {
      if (ts_optic_media_mix_chart_grouping_checked) {
        dispatch(changeFlightOpticChart(e.currentTarget.checked ? '₽' : '%', 'all', flight));
      } else {
        dispatch(changeFlightOpticChart(e.currentTarget.checked ? '₽' : '%', 'group', flight));
      }
    } else if (toggleName === 'toggleSwitch_optic_media_mix_graph_all_group_checked') {
      if (ts_optic_media_mix_chart_unit_checked) {
        dispatch(changeFlightOpticChart('₽', e.currentTarget.checked ? 'all' : 'group', flight));
      } else {
        dispatch(changeFlightOpticChart('%', e.currentTarget.checked ? 'all' : 'group', flight));
      }
    }
  };

  const onClickBackButton = () => {
    history.push('/flightsStep3');
  };
  const onChangeMainMedia = selectItem => {
    if (ts_contribution_chart_unit_checked && ts_contribution_chart_grouping_checked) {
      dispatch(changeFlightContributionChart('₽', 'all', selectItem, currentFlight));
    } else if (ts_contribution_chart_unit_checked && !ts_contribution_chart_grouping_checked) {
      dispatch(changeFlightContributionChart('₽', 'group', selectItem, currentFlight));
    } else if (!ts_contribution_chart_unit_checked && ts_contribution_chart_grouping_checked) {
      dispatch(changeFlightContributionChart('%', 'all', selectItem, currentFlight));
    } else if (!ts_contribution_chart_unit_checked && !ts_contribution_chart_grouping_checked) {
      dispatch(changeFlightContributionChart('%', 'group', selectItem, currentFlight));
    }
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step3Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
        <div className={s.chartsWrapper}>
          <div className={s.pieCharts}>
            <div className={s.pieChartWrapper}>
              <h4 className={s.chartTitle}>Рекомендуемый медиа микс</h4>

              <br />
              <div className={s.toggleSwitchWrapper}>
                <div className={s.toggleSwitch_ruble_pie}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSPie(e, 'toggleSwitch_pieChart_ruble_checked', currentFlight);
                    }}
                    checked={ts_pieChart_unit_checked}
                    labelStart={'₽'}
                    labelEnd={'%'}
                    id={'pie_p_%'}
                  />
                </div>

                <div className={s.toggleSwitch_all_group_pie}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSPie(e, 'toggleSwitch_pieChart_all_group_checked', currentFlight);
                    }}
                    checked={ts_pieChart_grouping_checked}
                    labelStart={'медиа'}
                    labelEnd={'группы медиа'}
                    id={'pie_all_group'}
                  />
                </div>
              </div>
              <PieChart donutData={donutData} unitDonut={unitDonut} />
            </div>

            <div className={s.contributionChartWrapper}>
              <h4 className={s.chartTitle}>Вклад каждого медиа </h4>
              <h4 className={s.chartTitle}>в совокупный охват {frequency} </h4>
              <br />
              <div className={s.toggleSwitchWrapper}>
                <p className={s.selectTitle}>Основное медиа: </p>
                {mainMedia !== null && (
                  <div className={s.selectWrapper}>
                    <Select name={'mainMedia'} value={mainMedia} onChange={onChangeMainMedia} items={chooseMainMediaArr(chartsData, dataFromXL)} location={'contributionChart'} />
                  </div>
                )}

                <div className={s.toggleSwitch_all_group_contribution}>
                  <ToggleSwitch
                    onChangeChecked={e => {
                      onClickTSContribution(e, 'toggleSwitch_contribution_Chart_all_group_checked', currentFlight);
                    }}
                    checked={ts_contribution_chart_grouping_checked}
                    labelStart={'медиа'}
                    labelEnd={'группы медиа'}
                    id={'contribution_all_group'}
                  />
                </div>
              </div>
              <ContributionOfEachMedia contributionOfEachMedia_data={contributionOfEachMedia_data} />
            </div>

            <div className={s.knowledgeWrapper}>
              <h4 className={s.knowledgeTitle}>Прогноз</h4>
              <div className={s.knowledgeItem}>
                <div className={s.knowledgeText}>Охват ЦА {frequency} </div>
                {findValueView(reachGeneral, 'roundCoverage')} %<div className={s.separator}></div>
                <div style={{ fontWeight: 'bold' }}>{findValueView(knowledgeGeneral, 'roundCoverage')} %</div>
                <div className={s.knowledgeText2}>
                  Знание <br />
                  креатива
                </div>
              </div>
            </div>
          </div>

          <div className={s.opticChartWrapper}>
            <h4 className={s.chartTitle}>Сравнение различных медиамиксов на идентичный </h4>
            <h4 className={s.chartTitle}> бюджет и их влияние на совокупный охват</h4>
            <div className={s.toggleSwitchWrapper}>
              <div className={s.toggleSwitch_ruble}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSOptic(e, 'toggleSwitch_optic_media_mix_graph_ruble_checked', currentFlight);
                  }}
                  checked={ts_optic_media_mix_chart_unit_checked}
                  labelStart={'₽'}
                  labelEnd={'%'}
                  id={'opticMediaMixGraph_p_%'}
                />
              </div>
              <div className={s.toggleSwitch_all_group}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSOptic(e, 'toggleSwitch_optic_media_mix_graph_all_group_checked', currentFlight);
                  }}
                  checked={ts_optic_media_mix_chart_grouping_checked}
                  labelStart={'медиа'}
                  labelEnd={'группы медиа'}
                  id={'opticMediaMixGraph_all_group'}
                />
              </div>
            </div>
            <OpticMediaMixGraph optic_media_mix_graph_data={optic_media_mix_graph_data} unitOptic={unitOptic} donutData={donutData} />
          </div>
        </div>

        <div>
          <div className={s.presenterBtn}>
            <div className={s.knowledgeGeneralCategoryWrapper}>
              <div className={s.blockWrapper}>
                <div className={s.blockTitle}>
                  ДЛЯ РАСЧЕТА <br /> ЗНАНИЯ
                </div>

                <div className={s.blockContent}>
                  <div>
                    Выберите <br /> категорию:
                  </div>
                  <Select name={'knowledgeGeneralCategory'} value={knowledgeGeneralCategory} onChange={onChangeKnowledgeGeneralCategory} items={['Телеком', 'FMCG', 'Товары для детей', 'Банки и финансы', 'Все категории']} width={135} />

                  <div style={{ marginTop: 30 }}>
                    <Checkbox
                      onChangeChecked={e => {
                        onChangeSeleb(e);
                      }}
                      checked={seleb === 1 ? true : false}
                      label={'Селебрити'}
                      id={'seleb'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
