import React, { useEffect, useState } from 'react';
import s from './ScrollToTop.module.css';
import arrowUp from '../../../../assets/images/icon-arrow-up.svg';

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  return (
    <>
      {isVisible && (
        <div onClick={scrollToTop} className={isVisible ? `${s.scrollUp} ${s.isVisible}` : s.scrollUp}>
          <img src={arrowUp} alt='Go to top' />
        </div>
      )}
    </>
  );
};
