import React from 'react';
import s from './StartButton.module.css';

export const StartButton = React.memo(props => {
  const { onClick, view, children, id } = props;
  const findClass = () => {
    if (view === 'add') {
      return `${s.startButton} ${s.add}`;
    } else if (view === 'red') {
      return s.redSquareButton;
    } else {
      return s.startButton;
    }
  };
  return (
    <div>
      <button className={findClass()} onClick={onClick} id={id}>
        {children}
      </button>
    </div>
  );
});
