import React from 'react';
import pptxgen from 'pptxgenjs';
import { COLORS } from '../../../assets/styles/colors/colors';
import { findMaxMinOhvat, round } from '../../../helpers/chart_helpers';
import colorStar from '../../../assets/images/Picture1.png';

export const ExportPPTPart1 = React.memo(props => {
  const { donutData, contributionOfEachMedia_data, optic_media_mix_graph_data } = props;
  const optic_reachArr = optic_media_mix_graph_data.datasets && optic_media_mix_graph_data.datasets[0].data;
  const maxValueOpt = donutData.datasets[0].data.reduce((a, b) => +a + +b);
  const opticAllColorsArray = optic_media_mix_graph_data.datasets.filter(item => item.type === 'bar').map(i => i.backgroundColor);

  let pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';
  pptx.defineSlideMaster({
    title: 'MASTER_SLIDE',
    objects: [{ text: { text: 'Flight Media', options: { x: '11%', y: '7%', w: 5.5, h: 0.75 } } }, { text: { text: 'Mix Calculator', options: { x: '21%', y: '7%', w: 5.5, h: 0.75, color: '#d32f2f' } } }, { image: { y: '8%', w: '100%', h: '8%', data: colorStar } }],
    slideNumber: { x: '94%', y: '9%' },
  });

  let slide1 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  const dataChartPieStat = [
    {
      name: 'Pie',
      labels: donutData.labels,
      values: donutData.datasets[0].data,
    },
  ];

  slide1.addText('Рекомендуемый медиа микс', { x: 3.5, y: 0, w: 6, h: 3.2, color: '#080808', fontSize: 25, bold: true });
  let optsPie = {
    x: 2.5,
    y: 2,
    w: '60%',
    h: '60%',
    legendPos: 't',
    legendFontFace: 'Roboto',
    legendFontSize: 16,
    showLegend: true,
    showLeaderLines: true,
    legendColor: '808080',
    showPercent: false,
    showValue: true,
    chartColors: donutData.datasets[0].backgroundColor,
    dataBorder: { pt: '1', color: 'F1F1F1' },
    dataLabelColor: 'FFFFFF',
    dataLabelFontSize: 12,
    dataLabelPosition: 'ctr', //outEnd', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
  };
  slide1.addChart(pptx.charts.PIE, dataChartPieStat, optsPie);

  let slide2 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide2.addText('Вклад каждого медиа в совокупный охват', { x: 3.5, y: 0, w: 7, h: 3.2, color: '#080808', fontSize: 25, bold: true });
  let optsChartBar1 = {
    x: 1.5,
    y: 2,
    w: '80%',
    h: '60%',
    legendFontSize: 16,
    barDir: 'bar',
    catAxisOrientation: 'maxMin',
    catAxisLabelColor: '808080',
    barGrouping: 'standard',
    dataLabelColor: '808080',
    showValue: true,
    chartColors: contributionOfEachMedia_data.datasets[0].backgroundColor.map(i => i.substring(1, 7)),
  };
  let single = [
    {
      name: 'Conrtibution',
      labels: contributionOfEachMedia_data.labels,
      values: contributionOfEachMedia_data.datasets[0].data,
    },
  ];
  slide2.addChart(pptx.charts.BAR, single, optsChartBar1);

  let slide3 = pptx.addSlide({ masterName: 'MASTER_SLIDE' });
  slide3.addText('Сравнение различных медиамиксов на идентичный бюджет и их влияние на совокупный охват', { x: 2.5, y: 0, w: 9, h: 3.2, color: '#080808', fontSize: 25, bold: true });
  function doStackedLine() {
    let opts = {
      x: 1.5,
      y: 2,
      w: '80%',
      h: '70%',
      legendFontSize: 16,
      barDir: 'col',
      barGrouping: 'stacked',
      chartColors: opticAllColorsArray,
      catAxisLabelColor: '808080',
      catAxisLabelFontFace: 'Roboto',
      catAxisLabelFontSize: 12,
      legendPos: 'b',
      legendFontFace: 'Roboto',
      legendColor: '808080',
      showLegend: true,
      showValue: true,
      // dataLabelFormatCode: '0.#;;;',
      dataLabelFormatCode: '#;;;',
      dataLabelColor: 'FFFFFF',
      dataLabelFontSize: 12,
      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: 'Доля в медиамиксе (млн. руб)',
          valAxisTitleFontSize: 12,
          valAxisTitleColor: '808080',
          valAxisMaxVal: maxValueOpt,
          valAxisMinVal: 0,
          valAxisDisplayUnit: 'millions',
        },
        {
          showValAxisTitle: true,
          valAxisTitle: 'Совокупный охват (%)',
          valAxisTitleFontSize: 12,
          valAxisTitleColor: '808080',
          catAxisOrientation: 'maxMin',
          valAxisMajorUnit: 1,
          valAxisMaxVal: round(findMaxMinOhvat(optic_reachArr)[1]),
          valAxisMinVal: round(findMaxMinOhvat(optic_reachArr)[0]),
          valGridLine: { style: 'none' },
        },
      ],

      catAxes: [
        {
          catAxisTitle: '',
        },
        {
          catAxisHidden: true,
        },
      ],
    };

    const opticDataWithoutReach = optic_media_mix_graph_data.datasets.filter(data => data.type === 'bar');

    let chartTypes = [
      {
        type: pptx.charts.BAR,
        data: opticDataWithoutReach.map(item => ({
          name: item.label,
          labels: optic_media_mix_graph_data.labels,
          values: item.data,
        })),
      },
      {
        type: pptx.charts.LINE,
        data: [
          {
            name: 'Охват',
            labels: optic_media_mix_graph_data.labels,
            values: optic_media_mix_graph_data.datasets.find(data => data.type === 'line').data,
          },
        ],
        options: {
          barGrouping: 'standard',
          chartColors: [COLORS.black],
          secondaryValAxis: !!opts.valAxes,
          secondaryCatAxis: !!opts.catAxes,
        },
      },
    ];
    slide3.addChart(chartTypes, opts);
  }
  doStackedLine();
  pptx.writeFile({ fileName: 'media_mix_calculator_charts_part1.pptx' });
  return <div></div>;
});
