import { COLORS } from '../assets/styles/colors/colors';
export const colorsArr = [COLORS.BannersImage, COLORS.BannersNetworks, COLORS.BannersRichPopUp, COLORS.Bloggers, COLORS.DigitalAudio, COLORS.MobileApps, COLORS.OLVInStream, COLORS.OLVOutStream, COLORS.OLVSmartTV, COLORS.SocialNets, COLORS.OOHStandardFormats, COLORS.OOHBigFormatsSupersites, COLORS.OOHCityformats, COLORS.Radio, COLORS.TV];
export const colorsGroupArr = [COLORS.DigitalGroup, COLORS.OOHGroup, COLORS.RadioGroup, COLORS.TVGroup];

export function findSum(obg) {
  let sum = 0;
  for (let item of Object.values(obg)) {
    sum += item.Budget;
  }
  return sum; // 650
}
export const findName = (name, arr) => {
  const findName = arr.find(arr => arr[name]);
  return findName;
};

export const makeArrayBudget = obg => {
  let arr = [];
  for (let item of Object.values(obg)) {
    arr.push(item.Budget);
  }
  return arr;
};
export const makeSumBudget = obg => {
  let arr = [];
  for (let item of Object.values(obg)) {
    arr.push(item.Budget);
  }
  var sum = 0;
  for (var i = 0; i < arr.length; i++) {
    sum += arr[i];
  }
  return sum;
};
export const makeArrayNames = arr => {
  let newArr = [];
  arr.map(a => {
    newArr.push(a.name);
    return a;
  });
  return newArr;
};
export const makeArrayGroup = obg => {
  let arr = [];
  for (let item of Object.values(obg)) {
    !arr.find(arrItem => arrItem === item.group) && arr.push(item.group);
  }
  return arr;
};

export const makeArrayBudgetPr = obg => {
  let arr = [];
  for (let item of Object.values(obg)) {
    arr.push(item.BudgetPr);
  }
  return arr;
};

export const makeArrayBudgetGroupBar = obg => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const keys = makeArrayGroup(obg);
  const arr = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arr);
    const findGroupArr = makeOneGroupBudget(keys[i], obg);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  return arr;
};

const makeOneGroupBudget = (groupName, obg) => {
  let arr = [];
  for (let item of Object.values(obg)) {
    item.group === groupName && arr.push(item.Budget);
  }
  return arr;
};

const makeOneGroupBudgetPr = (groupName, obg) => {
  let arr = [];
  for (let item of Object.values(obg)) {
    item.group === groupName && arr.push(item.BudgetPr);
  }
  return arr;
};

export const makeArrayBudgetGroup = obg => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const keys = makeArrayGroup(obg);
  const arr = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arr);
    const findGroupArr = makeOneGroupBudget(keys[i], obg);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  const newArr = arr.map(item => Object.values(item)[0][0]);
  return newArr;
};

export const makeArrayBudgetGroupPr = obg => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const keys = makeArrayGroup(obg);
  const arr = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arr);
    const findGroupArr = makeOneGroupBudgetPr(keys[i], obg);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  const newArr = arr.map(item => Object.values(item)[0][0]);
  return newArr;
};

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
export function setMillion(labelValue) {
  return Math.abs(Number(labelValue)) / 1.0e6;
}
export function getMaxValueOfArray(numArray) {
  return Math.max.apply(null, numArray);
}
export function getMinValueOfArray(numArray) {
  return Math.min.apply(null, numArray);
}

export const findMainMediaByBudget = (obg, budgetsArr) => {
  function getMaxBudgetOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }
  const maxBudget = getMaxBudgetOfArray(budgetsArr);

  let mainMediaByBudget = '';
  for (let item of Object.values(obg)) {
    if (item.Budget === maxBudget) {
      mainMediaByBudget = item.name;
    }
  }
  return mainMediaByBudget;
};

export function compareNumeric(a, b) {
  if (a > b) return 1;
  if (a === b) return 0;
  if (a < b) return -1;
}

export function arraySum(array) {
  var sum = 0;
  for (var i = 0; i < array.length; i++) {
    sum += array[i];
  }
  return sum;
}

export const makeArrayBudgetPrGroupBar = obg => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const keys = makeArrayGroup(obg);
  const arr = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arr);
    const findGroupArr = makeOneGroupBudgetPr(keys[i], obg);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  return arr;
};

export const makeArrayCPPMedia = (arrGroupName, startArr, BUDGET, donutGroupBudget) => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const arr = arrGroupName.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < arrGroupName.length; i++) {
    const donutGroupBudValue = Object.values(donutGroupBudget.find(media => Object.keys(media)[0] === arrGroupName[i]))[0][0];
    const findNameGroup = findName(arrGroupName[i], arr);
    const findGroupArr = makeOneGroupCPP(arrGroupName[i], startArr, BUDGET);
    const findGroupBudget = donutGroupBudValue===0 ? 0 : arraySum(findGroupArr)/donutGroupBudValue;
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  return arr;
};

export const makeArrayCPPMediaDataFromXL = (arrGroupName, startArr, BUDGET, donutGroupBudget) => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }

  const arr = arrGroupName.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < arrGroupName.length; i++) {
    const donutGroupBudValue = Object.values(donutGroupBudget.find(media => Object.keys(media)[0] === arrGroupName[i]))[0][0];
    const findNameGroup = findName(arrGroupName[i], arr);
    const findGroupArr = makeOneGroupCPP_DataFronXL(arrGroupName[i], startArr, BUDGET);
    const findGroupBudget = donutGroupBudValue===0 ? 0 : arraySum(findGroupArr)/donutGroupBudValue;
    Object.values(findNameGroup)[0].push(findGroupBudget);
  }
  return arr;
};

const makeOneGroupCPP = (groupName, startArr, Budget) => {
  let arr = [];
  for (let item of startArr) {
    const ITEM_BUDGET = +Budget[item.media].Budget
    const ITEM_CPT = item.CPP * ITEM_BUDGET
    item.group === groupName && arr.push(ITEM_CPT);
  }
  return arr;
};

const makeOneGroupCPP_DataFronXL = (groupName, startArr, Budget) => {
  let arr = [];
  for (let item of startArr) {
    const ITEM_BUDGET =  Budget.find(i=> i.Media1===item.media)  && +Budget.find(i=> i.Media1===item.media).Budget  
    const ITEM_CPT = +item.CPP * ITEM_BUDGET
    item.group === groupName && arr.push(ITEM_CPT);
  }
  return arr;
};

export const findMaxMinOhvat = numArray => {
  const min = getMinValueOfArray(numArray);
  const max = getMaxValueOfArray(numArray);
  const difference = ((max - min) / 100) * 10;

  const minOhvat = difference === 0 ? min - 1 : min - difference;
  const maxOhvat = difference === 0 ? max + 1 : max + difference;
  return [minOhvat, maxOhvat];
};

export const makeArrayGroupNames = arr => {
  let groups = [];
  arr.map(arrItem => {
    !groups.find(group => group === arrItem) && groups.push(arrItem);
    return arrItem;
  });
  return groups;
};
