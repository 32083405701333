import { findSum, findName, makeArrayGroup, makeArrayBudgetGroupBar, round, setMillion } from '../helpers/chart_helpers';
import { makeArrayGroupDataFromXL } from '../helpers/chart_helpers_data_from_XL';
import { setStatus } from './app_reducer';

const SET_INVESTMENT_COVERAGE_GRAPH_DATA = 'SET_INVESTMENT_COVERAGE_GRAPH_DATA';
const SET_INVESTMENT_GROWTH_GRAPH_DATA = 'SET_INVESTMENT_GROWTH_GRAPH_DATA';
const SET_ALL_CHARTS_DATA_GROWTH = 'SET_ALL_CHARTS_DATA_GROWTH';
const SET_ALL_CHARTS_DATA_COVERAGE = 'SET_ALL_CHARTS_DATA_COVERAGE';
const SET_UNIT_GROWTH = 'SET_UNIT_GROWTH';
const SET_UNIT_COVERAGE = 'SET_UNIT_COVERAGE';
const SET_ts_investment_growth_chart_grouping_checked = 'SET_ts_investment_growth_chart_grouping_checked';
const SET_ts_investment_growth_chart_unit_checked = 'SET_ts_investment_growth_chart_unit_checked';
const SET_ts_investment_coverage_chart_grouping_checked = 'SET_ts_investment_coverage_chart_grouping_checked';
const SET_ts_investment_coverage_chart_unit_checked = 'SET_ts_investment_coverage_chart_unit_checked';
const SET_IS_GROWTH_CHART_RENDERED = 'SET_IS_GROWTH_CHART_RENDERED';
const SET_IS_COVERAGE_CHART_RENDERED = 'SET_IS_COVERAGE_CHART_RENDERED';
const SET_KNOWLEDGE_ARR = 'SET_KNOWLEDGE_ARR';

const initialState = {
  allChartsDataGrowth: [],
  allChartsDataCoverage: [],
  investment_coverage_graph_data: {},
  investment_growth_graph_data: {},
  unitGrowth: '₽',
  unitCoverage: '₽',
  ts_investment_growth_chart_grouping_checked: true,
  ts_investment_growth_chart_unit_checked: true,
  ts_investment_coverage_chart_grouping_checked: true,
  ts_investment_coverage_chart_unit_checked: true,
  isGrowthChartRendered: false,
  isCoverageChartRendered: false,
  knowledgeArr:[]
};

export const charts_part2_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVESTMENT_COVERAGE_GRAPH_DATA:
      return { ...state, investment_coverage_graph_data: action.investment_coverage_graph_data };
    case SET_INVESTMENT_GROWTH_GRAPH_DATA:
      return { ...state, investment_growth_graph_data: action.investment_growth_graph_data };
    case SET_ALL_CHARTS_DATA_GROWTH:
      return { ...state, allChartsDataGrowth: action.allChartsDataGrowth };
    case SET_ALL_CHARTS_DATA_COVERAGE:
      return { ...state, allChartsDataCoverage: action.allChartsDataCoverage };
    case SET_UNIT_GROWTH:
      return { ...state, unitGrowth: action.unitGrowth };
    case SET_UNIT_COVERAGE:
      return { ...state, unitCoverage: action.unitCoverage };
    case SET_ts_investment_growth_chart_grouping_checked:
      return { ...state, ts_investment_growth_chart_grouping_checked: action.ts_investment_growth_chart_grouping_checked };
    case SET_ts_investment_growth_chart_unit_checked:
      return { ...state, ts_investment_growth_chart_unit_checked: action.ts_investment_growth_chart_unit_checked };
    case SET_ts_investment_coverage_chart_grouping_checked:
      return { ...state, ts_investment_coverage_chart_grouping_checked: action.ts_investment_coverage_chart_grouping_checked };
    case SET_ts_investment_coverage_chart_unit_checked:
      return { ...state, ts_investment_coverage_chart_unit_checked: action.ts_investment_coverage_chart_unit_checked };
    case SET_IS_GROWTH_CHART_RENDERED:
      return { ...state, isGrowthChartRendered: action.isGrowthChartRendered };
    case SET_IS_COVERAGE_CHART_RENDERED:
      return { ...state, isCoverageChartRendered: action.isCoverageChartRendered };
    case SET_KNOWLEDGE_ARR:
      return { ...state, knowledgeArr: action.knowledgeArr };
    default:
      return state;
  }
};

// action creators
export const set_investment_coverage_graph_data = investment_coverage_graph_data => ({ type: SET_INVESTMENT_COVERAGE_GRAPH_DATA, investment_coverage_graph_data });
export const set_investment_growth_graph_data = investment_growth_graph_data => ({ type: SET_INVESTMENT_GROWTH_GRAPH_DATA, investment_growth_graph_data });
export const setAllChartsDataGrowth = allChartsDataGrowth => ({ type: SET_ALL_CHARTS_DATA_GROWTH, allChartsDataGrowth });
export const setAllChartsDataCoverage = allChartsDataCoverage => ({ type: SET_ALL_CHARTS_DATA_COVERAGE, allChartsDataCoverage });
export const setUnitGrowth = unitGrowth => ({ type: SET_UNIT_GROWTH, unitGrowth });
export const setUnitCoverage = unitCoverage => ({ type: SET_UNIT_COVERAGE, unitCoverage });
export const setTs_investment_growth_chart_grouping_checked = ts_investment_growth_chart_grouping_checked => ({ type: SET_ts_investment_growth_chart_grouping_checked, ts_investment_growth_chart_grouping_checked });
export const setTs_investment_growth_chart_unit_checked = ts_investment_growth_chart_unit_checked => ({ type: SET_ts_investment_growth_chart_unit_checked, ts_investment_growth_chart_unit_checked });
export const setTs_investment_coverage_chart_grouping_checked = ts_investment_coverage_chart_grouping_checked => ({ type: SET_ts_investment_coverage_chart_grouping_checked, ts_investment_coverage_chart_grouping_checked });
export const setTs_investment_coverage_chart_unit_checked = ts_investment_coverage_chart_unit_checked => ({ type: SET_ts_investment_coverage_chart_unit_checked, ts_investment_coverage_chart_unit_checked });
export const setIsGrowthChartRendered = isGrowthChartRendered => ({ type: SET_IS_GROWTH_CHART_RENDERED, isGrowthChartRendered });
export const setIsCoverageChartRendered = isCoverageChartRendered => ({ type: SET_IS_COVERAGE_CHART_RENDERED, isCoverageChartRendered });
export const setKnowledgeArr = knowledgeArr => ({ type: SET_KNOWLEDGE_ARR, knowledgeArr });

//thunk creators
export const changeGrowthChart = (unit, grouping) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const allChartsDataGrowth = getState().charts2.allChartsDataGrowth;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const unitGrowth = getState().charts2.unitGrowth;
  const frequency = getState().input.frequency;
  const colorsArray = getState().charts.colorsArray;
  const colorsGroupArray = getState().charts.colorsGroupArray;

  const getDataForGrowthChart_DataFromXL = dataFromXL => {
    const coveragesGrowthDataFromXL = [];
    const growthData = dataFromXL.filter(item => item.Media4);
    const growthMedia = growthData.map(item => item.Media4);
    const mediaGroupArrDataFromXL = makeArrayGroupDataFromXL(growthData);

    const growthReach = dataFromXL.filter(item => item.ReachGrowth);
    growthReach.map(item => {
      coveragesGrowthDataFromXL.push(item['1'], item['2'], item['3'], item['4'], item['5'], item['6'], item['7'], item['8'], item['9']);
      return item;
    });

    const mediaArrayGrowthDataFromXL = growthMedia.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayGrowthPercentDataFromXL = growthMedia.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayGrowthGroupDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayGrowthGroupPercentDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (let i = 0; i < growthMedia.length; i++) {
      const findNameMedia = findName(growthMedia[i], mediaArrayGrowthDataFromXL);
      const findObj = growthData.find(obj => obj.Media4 === growthMedia[i]);
      Object.values(findNameMedia)[0].push(findObj['1'], findObj['2'], findObj['3'], findObj['4'], findObj['5'], findObj['6'], findObj['7'], findObj['8'], findObj['9']);
    }

    for (let i = 0; i < growthMedia.length; i++) {
      const findNameMedia = findName(growthMedia[i], mediaArrayGrowthPercentDataFromXL);
      const findObj = growthData.find(obj => obj.Media4 === growthMedia[i]);
      Object.values(findNameMedia)[0].push(findObj['1_Pr'], findObj['2_Pr'], findObj['3_Pr'], findObj['4_Pr'], findObj['5_Pr'], findObj['6_Pr'], findObj['7_Pr'], findObj['8_Pr'], findObj['9_Pr']);
    }

    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayGrowthGroupDataFromXL);
      const findObjArr = growthData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1 = 0;
      let sumOption2 = 0;
      let sumOption3 = 0;
      let sumOption4 = 0;
      let sumOption5 = 0;
      let sumOption6 = 0;
      let sumOption7 = 0;
      let sumOption8 = 0;
      let sumOption9 = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1 += +findObjArr[i]['1'];
        sumOption2 += +findObjArr[i]['2'];
        sumOption3 += +findObjArr[i]['3'];
        sumOption4 += +findObjArr[i]['4'];
        sumOption5 += +findObjArr[i]['5'];
        sumOption6 += +findObjArr[i]['6'];
        sumOption7 += +findObjArr[i]['7'];
        sumOption8 += +findObjArr[i]['8'];
        sumOption9 += +findObjArr[i]['9'];
      }
      Object.values(findNameMedia)[0].push(+sumOption1, +sumOption2, +sumOption3, +sumOption4, +sumOption5, +sumOption6, +sumOption7, +sumOption8, +sumOption9);
    }
    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayGrowthGroupPercentDataFromXL);
      const findObjArr = growthData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1Pr = 0;
      let sumOption2Pr = 0;
      let sumOption3Pr = 0;
      let sumOption4Pr = 0;
      let sumOption5Pr = 0;
      let sumOption6Pr = 0;
      let sumOption7Pr = 0;
      let sumOption8Pr = 0;
      let sumOption9Pr = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1Pr += +findObjArr[i]['1_Pr'];
        sumOption2Pr += +findObjArr[i]['2_Pr'];
        sumOption3Pr += +findObjArr[i]['3_Pr'];
        sumOption4Pr += +findObjArr[i]['4_Pr'];
        sumOption5Pr += +findObjArr[i]['5_Pr'];
        sumOption6Pr += +findObjArr[i]['6_Pr'];
        sumOption7Pr += +findObjArr[i]['7_Pr'];
        sumOption8Pr += +findObjArr[i]['8_Pr'];
        sumOption9Pr += +findObjArr[i]['9_Pr'];
      }
      Object.values(findNameMedia)[0].push(+sumOption1Pr, +sumOption2Pr, +sumOption3Pr, +sumOption4Pr, +sumOption5Pr, +sumOption6Pr, +sumOption7Pr, +sumOption8Pr, +sumOption9Pr);
    }
    const labelsArrDataFromXL = [];
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    let sum5 = 0;
    let sum6 = 0;
    let sum7 = 0;
    let sum8 = 0;
    let sum9 = 0;
    for (let i = 0; i < growthData.length; i++) {
      sum1 += +growthData[i]['1'];
      sum2 += +growthData[i]['2'];
      sum3 += +growthData[i]['3'];
      sum4 += +growthData[i]['4'];
      sum5 += +growthData[i]['5'];
      sum6 += +growthData[i]['6'];
      sum7 += +growthData[i]['7'];
      sum8 += +growthData[i]['8'];
      sum9 += +growthData[i]['9'];
    }
    labelsArrDataFromXL.push(+sum1, +sum2, +sum3, +sum4, +sum5, +sum6, +sum7, +sum8, +sum9);
    return [labelsArrDataFromXL, coveragesGrowthDataFromXL, mediaArrayGrowthDataFromXL, mediaArrayGrowthPercentDataFromXL, mediaArrayGrowthGroupDataFromXL, mediaArrayGrowthGroupPercentDataFromXL];
  };
  const labelsArrDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[0];
  const coveragesGrowthDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[1];
  const mediaArrayGrowthDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[2];
  const mediaArrayGrowthPercentDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[3];
  const mediaArrayGrowthGroupDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[4];
  const mediaArrayGrowthGroupPercentDataFromXL = isChartsUpload && getDataForGrowthChart_DataFromXL(dataFromXL)[5];

  const getDataForGrowthChart_DataFromAllChartsDataGrowth = allChartsDataGrowth => {
    const coveragesGrowth = [];
    const labelsArr = [];
    const GROWTH = allChartsDataGrowth.data[0];
    const obg = JSON.parse(GROWTH[0][1]);
    const keys = Object.keys(obg);
    const mediaGroupArr = makeArrayGroup(obg);
    const mediaArrayGrowth = keys.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayGrowthGroup = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayGrowthPercent = keys.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayGrowthGroupPercent = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });

    for (const data of GROWTH) {
      const DATA = JSON.parse(data[1]);
      const sum = findSum(DATA);
      coveragesGrowth.push(data[0]);
      const growthGroupBudgetArr = makeArrayBudgetGroupBar(DATA);
      labelsArr.push(sum);
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayGrowth);
        Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
      }
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayGrowthPercent);
        Object.values(findNameMedia)[0].push(+((+DATA[keys[i]].Budget * 100) / sum).toFixed(1));
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayGrowthGroup);
        const findBudgetMedia = findName(mediaGroupArr[i], growthGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(Object.values(findBudgetMedia)[0][0]);
      }

      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayGrowthGroupPercent);
        const findBudgetMedia = findName(mediaGroupArr[i], growthGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(+((+Object.values(findBudgetMedia)[0][0] * 100) / sum).toFixed(1));
      }
    }
    return [coveragesGrowth, labelsArr, mediaArrayGrowth, mediaArrayGrowthGroup, mediaArrayGrowthPercent, mediaArrayGrowthGroupPercent];
  };

  const coveragesGrowth = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[0];
  const labelsArr = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[1];
  const mediaArrayGrowth = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[2];
  const mediaArrayGrowthGroup = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[3];
  const mediaArrayGrowthPercent = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[4];
  const mediaArrayGrowthGroupPercent = !isChartsUpload && getDataForGrowthChart_DataFromAllChartsDataGrowth(allChartsDataGrowth)[5];
  const chooseData = () => {
    if (isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayGrowthPercentDataFromXL;
        else if (unit === '₽') return mediaArrayGrowthDataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayGrowthGroupPercentDataFromXL;
        else if (unit === '₽') return mediaArrayGrowthGroupDataFromXL;
      }
    } else if (!isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayGrowthPercent;
        else if (unit === '₽') return mediaArrayGrowth;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayGrowthGroupPercent;
        else if (unit === '₽') return mediaArrayGrowthGroup;
      }
    }
  };
  dispatch(
    set_investment_growth_graph_data({
      labels: isChartsUpload ? labelsArrDataFromXL.map(item => `${round(setMillion(item))} млн`) : labelsArr.map(item => `${round(setMillion(item))} млн`),
      datasets: [
        {
          type: 'scatter',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 1) + ` %`;
              },
            },
          },
          label: 'Охват',
          backgroundColor: 'black',
          borderWidth: 3,
          fill: false,
          data: isChartsUpload ? coveragesGrowthDataFromXL : coveragesGrowth,
          yAxisID: 'ohvat',
          y2axis: true,
          datalabels: {
            display: false,
          },
        },
        ...chooseData().map(array => ({
          type: 'bar',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                const changedTooltipItem = unitGrowth === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                return unitGrowth === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitGrowth}`;
              },
            },
          },
          label: array[Object.keys(array)[1]],
          backgroundColor: array[Object.keys(array)[2]],
          data: array[Object.keys(array)[0]],
        })),
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeCoverageChart = (unit, grouping) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const allChartsDataCoverage = getState().charts2.allChartsDataCoverage;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const colorsArray = getState().charts.colorsArray;
  const colorsGroupArray = getState().charts.colorsGroupArray;

  const getDataForCoverageChart_DataFromXL = dataFromXL => {
    const coverageData = dataFromXL.filter(item => item.Media5);
    const coverageMedia = coverageData.map(item => item.Media5);
    const mediaGroupArrDataFromXL = makeArrayGroupDataFromXL(coverageData);
    const coverageReach = dataFromXL.filter(item => item.ReachCovarage);
    const labelsArrCoverageDataFromXL = [];
    coverageReach.map(item => {
      labelsArrCoverageDataFromXL.push(item['1'], item['2'], item['3'], item['4'], item['5'], item['6'], item['7'], item['8'], item['9']);
      return item;
    });

    const mediaArrayCoverageDataFromXL = coverageMedia.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayCoveragePercentDataFromXL = coverageMedia.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayCoverageGroupDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayCoverageGroupPercentDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (let i = 0; i < coverageMedia.length; i++) {
      const findNameMedia = findName(coverageMedia[i], mediaArrayCoverageDataFromXL);
      const findObj = coverageData.find(obj => obj.Media5 === coverageMedia[i]);
      Object.values(findNameMedia)[0].push(findObj['1'], findObj['2'], findObj['3'], findObj['4'], findObj['5'], findObj['6'], findObj['7'], findObj['8'], findObj['9']);
    }
    for (let i = 0; i < coverageMedia.length; i++) {
      const findNameMedia = findName(coverageMedia[i], mediaArrayCoveragePercentDataFromXL);
      const findObj = coverageData.find(obj => obj.Media5 === coverageMedia[i]);
      Object.values(findNameMedia)[0].push(findObj['1_Pr'], findObj['2_Pr'], findObj['3_Pr'], findObj['4_Pr'], findObj['5_Pr'], findObj['6_Pr'], findObj['7_Pr'], findObj['8_Pr'], findObj['9_Pr']);
    }

    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayCoverageGroupDataFromXL);
      const findObjArr = coverageData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1 = 0;
      let sumOption2 = 0;
      let sumOption3 = 0;
      let sumOption4 = 0;
      let sumOption5 = 0;
      let sumOption6 = 0;
      let sumOption7 = 0;
      let sumOption8 = 0;
      let sumOption9 = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1 += +findObjArr[i]['1'];
        sumOption2 += +findObjArr[i]['2'];
        sumOption3 += +findObjArr[i]['3'];
        sumOption4 += +findObjArr[i]['4'];
        sumOption5 += +findObjArr[i]['5'];
        sumOption6 += +findObjArr[i]['6'];
        sumOption7 += +findObjArr[i]['7'];
        sumOption8 += +findObjArr[i]['8'];
        sumOption9 += +findObjArr[i]['9'];
      }
      Object.values(findNameMedia)[0].push(+sumOption1, +sumOption2, +sumOption3, +sumOption4, +sumOption5, +sumOption6, +sumOption7, +sumOption8, +sumOption9);
    }
    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayCoverageGroupPercentDataFromXL);
      const findObjArr = coverageData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1Pr = 0;
      let sumOption2Pr = 0;
      let sumOption3Pr = 0;
      let sumOption4Pr = 0;
      let sumOption5Pr = 0;
      let sumOption6Pr = 0;
      let sumOption7Pr = 0;
      let sumOption8Pr = 0;
      let sumOption9Pr = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1Pr += +findObjArr[i]['1_Pr'];
        sumOption2Pr += +findObjArr[i]['2_Pr'];
        sumOption3Pr += +findObjArr[i]['3_Pr'];
        sumOption4Pr += +findObjArr[i]['4_Pr'];
        sumOption5Pr += +findObjArr[i]['5_Pr'];
        sumOption6Pr += +findObjArr[i]['6_Pr'];
        sumOption7Pr += +findObjArr[i]['7_Pr'];
        sumOption8Pr += +findObjArr[i]['8_Pr'];
        sumOption9Pr += +findObjArr[i]['9_Pr'];
      }
      Object.values(findNameMedia)[0].push(+sumOption1Pr, +sumOption2Pr, +sumOption3Pr, +sumOption4Pr, +sumOption5Pr, +sumOption6Pr, +sumOption7Pr, +sumOption8Pr, +sumOption9Pr);
    }
    return [labelsArrCoverageDataFromXL, mediaArrayCoverageDataFromXL, mediaArrayCoveragePercentDataFromXL, mediaArrayCoverageGroupDataFromXL, mediaArrayCoverageGroupPercentDataFromXL];
  };

  const labelsArrCoverageDataFromXL = isChartsUpload && getDataForCoverageChart_DataFromXL(dataFromXL)[0];
  const mediaArrayCoverageDataFromXL = isChartsUpload && getDataForCoverageChart_DataFromXL(dataFromXL)[1];
  const mediaArrayCoveragePercentDataFromXL = isChartsUpload && getDataForCoverageChart_DataFromXL(dataFromXL)[2];
  const mediaArrayCoverageGroupDataFromXL = isChartsUpload && getDataForCoverageChart_DataFromXL(dataFromXL)[3];
  const mediaArrayCoverageGroupPercentDataFromXL = isChartsUpload && getDataForCoverageChart_DataFromXL(dataFromXL)[4];
  const getDataForCoverageChart_DataFromAllChartsDataCoverage = allChartsDataCoverage => {
    const COVERAGE = allChartsDataCoverage.data;
    const obgCoverage = JSON.parse(COVERAGE[0][1]);
    const keysCoverage = Object.keys(obgCoverage);
    const mediaGroupArr = makeArrayGroup(obgCoverage);

    const labelsArrCoverage = [];
    const mediaArrayCoverage = keysCoverage.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayCoverageGroup = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });

    const mediaArrayCoveragePercent = keysCoverage.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayCoverageGroupPercent = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (const data of COVERAGE) {
      const DATA = JSON.parse(data[1]);
      labelsArrCoverage.push(data[0]);
      const sum = findSum(DATA);
      const coverageGroupBudgetArr = makeArrayBudgetGroupBar(DATA);
      for (let i = 0; i < keysCoverage.length; i++) {
        const findNameMedia = findName(keysCoverage[i], mediaArrayCoverage);
        Object.values(findNameMedia)[0].push(DATA[keysCoverage[i]].Budget);
      }
      for (let i = 0; i < keysCoverage.length; i++) {
        const findNameMedia = findName(keysCoverage[i], mediaArrayCoveragePercent);
        Object.values(findNameMedia)[0].push(+((+DATA[keysCoverage[i]].Budget * 100) / sum).toFixed(1));
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayCoverageGroup);
        const findBudgetMedia = findName(mediaGroupArr[i], coverageGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(Object.values(findBudgetMedia)[0][0]);
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayCoverageGroupPercent);
        const findBudgetMedia = findName(mediaGroupArr[i], coverageGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(+((+Object.values(findBudgetMedia)[0][0] * 100) / sum).toFixed(1));
      }
    }
    return [labelsArrCoverage, mediaArrayCoverage, mediaArrayCoverageGroup, mediaArrayCoveragePercent, mediaArrayCoverageGroupPercent];
  };
  const labelsArrCoverage = !isChartsUpload && getDataForCoverageChart_DataFromAllChartsDataCoverage(allChartsDataCoverage)[0];
  const mediaArrayCoverage = !isChartsUpload && getDataForCoverageChart_DataFromAllChartsDataCoverage(allChartsDataCoverage)[1];
  const mediaArrayCoverageGroup = !isChartsUpload && getDataForCoverageChart_DataFromAllChartsDataCoverage(allChartsDataCoverage)[2];
  const mediaArrayCoveragePercent = !isChartsUpload && getDataForCoverageChart_DataFromAllChartsDataCoverage(allChartsDataCoverage)[3];
  const mediaArrayCoverageGroupPercent = !isChartsUpload && getDataForCoverageChart_DataFromAllChartsDataCoverage(allChartsDataCoverage)[4];
  const chooseData = () => {
    if (isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayCoveragePercentDataFromXL;
        else if (unit === '₽') return mediaArrayCoverageDataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayCoverageGroupPercentDataFromXL;
        else if (unit === '₽') return mediaArrayCoverageGroupDataFromXL;
      }
    } else if (!isChartsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayCoveragePercent;
        else if (unit === '₽') return mediaArrayCoverage;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayCoverageGroupPercent;
        else if (unit === '₽') return mediaArrayCoverageGroup;
      }
    }
  };

  dispatch(
    set_investment_coverage_graph_data({
      labels: isChartsUpload ? labelsArrCoverageDataFromXL.map(item => `Охват ${round(item)} %`) : labelsArrCoverage.map(item => `Охват ${round(item)} %`),
      datasets: chooseData().map(array => ({
        type: 'bar',
        label: array[Object.keys(array)[1]],
        backgroundColor: array[Object.keys(array)[2]],
        data: array[Object.keys(array)[0]],
      })),
    })
  );
  dispatch(setStatus('success'));
};
