import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../Common/Select/Select';
import { setFlightsGeo, setFlightsInputStep1DataChanged } from '../../../../bll/flights_reducer';

export const FlightsGeo = React.memo(props => {
  const { isDisabled } = props;
  const dispatch = useDispatch();
  const flightsGeo = useSelector(state => state.flights.flightsGeo);

  const convertGeoName = name => {
    if (name === 'NAT') {
      return 'Россия 0+';
    } else if (name === 'Moscow') {
      return 'Москва';
    } else if (name === 'Kazan') {
      return 'Миллионник';
    }
  };
  const currentGeo = flightsGeo.find(i => i.isChecked === true) ? convertGeoName(flightsGeo.find(i => i.isChecked === true).name) : '';

  const findGeoName = selectItem => {
    if (selectItem === 'Россия 0+') {
      return 'NAT';
    } else if (selectItem === 'Москва') {
      return 'Moscow';
    } else if (selectItem === 'Миллионник') {
      return 'Kazan';
    }
  };

  const onChangeFlightsGeo = selectItem => {
    const selectItemName = findGeoName(selectItem);
    const updatedGeoState = flightsGeo.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = true;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectItemName ? selectedItem : otherItem;
    });
    dispatch(setFlightsGeo(updatedGeoState));
    dispatch(setFlightsInputStep1DataChanged(true));
  };
  return (
    <Select isDisabled={isDisabled} name={'geo'} value={currentGeo} onChange={onChangeFlightsGeo} items={['Россия 0+', 'Москва', 'Миллионник']} location={'severalFlights'} />
  );
});
