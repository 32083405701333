import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditableSpanForCPMTable } from '../Common/EditableSpanForCPMTable/EditableSpanForCPMTable';
import s from './CPMTable.module.css';
import { setFlightsInputStep2DataChanged, setFlightsMediaArr, setCPPAllMonth } from '../../../bll/flights_reducer';
import { findMonthArr, findArrDates, findMonthArrNumber, numberOfDaysBetweenDates, daysInMonth } from '../../../helpers/flightTable_helpers';

export const CPMTable = React.memo(props => {
  const dispatch = useDispatch();
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);
  const CPPAllMonth = useSelector(state => state.flights.CPPAllMonth);
 
  const monthArrByString = findMonthArr(flightsPeriod);
  const monthArrByNumber = findMonthArrNumber(flightsPeriod)

  const onChangeCPM = (newValue, mediaName, month) => {

    const updateCPPAllMonth = CPPAllMonth.map(item=> {
      const newItem = { ...item };
      newItem[month] = newValue
      return item.media === mediaName ? newItem : item
    })

    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {    
      const updatedMediaState = item.copyStartMedia.map(el => {
        return { ...el };
      });
      const sumAllFlightsCPPValues=[]
      const newCPPObg = updateCPPAllMonth.find(i=> i.media ===mediaName)
      const mediaArrCurrentMediaIsChecked = flightsMediaArr.filter(m=> m.media.find(media=>media.media===mediaName) && m.media.find(media=>media.media===mediaName).isChecked)
     
      flightsPeriod.map(period=> {
        const flightsMediaArrCurrentPeriod = flightsMediaArr.find(mediaArr=> mediaArr.flight===period.flight)       
        const currentMediaIsCheckedInCurrentPeriod = flightsMediaArrCurrentPeriod.media.find(media=>media.media===mediaName) && flightsMediaArrCurrentPeriod.media.find(media=>media.media===mediaName).isChecked
        const arrCPPValues = []
        const arrQuotientDays = []
        const currentPeriodArrDates = findArrDates(period.startDate, period.finishDate)
        for (let i = 0; i < monthArrByNumber.length; i++) {
        const eachMonthArr = currentPeriodArrDates.filter(d=>d.substring(5, 7) === monthArrByNumber[i])
        const numberOfDaysEachMonthArr = numberOfDaysBetweenDates(eachMonthArr[0],eachMonthArr[eachMonthArr.length-1])
        const numberAllDaysCurrentMonth = daysInMonth(monthArrByNumber[i],new Date(period.startDate).getFullYear())

        currentMediaIsCheckedInCurrentPeriod && arrCPPValues.push((newCPPObg[monthArrByString[i]]*numberOfDaysEachMonthArr)/numberAllDaysCurrentMonth) 
        currentMediaIsCheckedInCurrentPeriod && arrQuotientDays.push(numberOfDaysEachMonthArr/numberAllDaysCurrentMonth)
        }
        const sumCPPValues = arrCPPValues.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0)
        const sumQuotientDays = arrQuotientDays.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0)
        currentMediaIsCheckedInCurrentPeriod && sumAllFlightsCPPValues.push(sumCPPValues/sumQuotientDays)
        return period
      })
        const findCurrentMediaCPP = sumAllFlightsCPPValues.reduce(function (accumulator, currentValue) {
        return accumulator + +currentValue/mediaArrCurrentMediaIsChecked.length
        }, 0)

      const currentObg = updatedMediaState.find(it=>it.media === mediaName)
      currentObg.CPP = findCurrentMediaCPP   

      const newI = { ...item };
      newI.media = updatedMediaState;
      newI.copyStartMedia = updatedMediaState;
      return newI;
    });   
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setCPPAllMonth(updateCPPAllMonth))
    dispatch(setFlightsInputStep2DataChanged(true));
  };
  return (
    <table className={s.coeffTable}>
      <thead className={s.thead}>
        <tr>
          <th className={s.th}>Медиа</th>
          <th className={s.th}>Январь</th>
          <th className={s.th}>Февраль</th>
          <th className={s.th}>Март</th>
          <th className={s.th}>Апрель</th>
          <th className={s.th}>Май</th>
          <th className={s.th}>Июнь</th>
          <th className={s.th}>Июль</th>
          <th className={s.th}>Август</th>
          <th className={s.th}>Сентябрь</th>
          <th className={s.th}>Оутябрь</th>
          <th className={s.th}>Ноябрь</th>
          <th className={s.th}>Декабрь</th>
        </tr>
      </thead>
      <tbody className={s.tbody}>
        {CPPAllMonth &&
          CPPAllMonth.map(item => {           
            const verifyMonth = month => {
              const monthSelect = monthArrByString.find(m => m === month);
              return monthSelect ? s.td : `${s.td} ${s.text_muted}`;
            };
            return (
              <tr key={item.media}>
                <td className={s.td}>{item.media}</td>
                <td className={verifyMonth('Январь')}>
                  <EditableSpanForCPMTable value={item.Январь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Январь'} />
                </td>
                <td className={verifyMonth('Февраль')}>
                  <EditableSpanForCPMTable value={item.Февраль} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Февраль'} />
                </td>
                <td className={verifyMonth('Март')}>
                  <EditableSpanForCPMTable value={item.Март} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Март'} />
                </td>
                <td className={verifyMonth('Апрель')}>
                  <EditableSpanForCPMTable value={item.Апрель} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Апрель'} />
                </td>
                <td className={verifyMonth('Май')}>
                  <EditableSpanForCPMTable value={item.Май} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Май'} />
                </td>
                <td className={verifyMonth('Июнь')}>
                  <EditableSpanForCPMTable value={item.Июнь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Июнь'} />
                </td>
                <td className={verifyMonth('Июль')}>
                  <EditableSpanForCPMTable value={item.Июль} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Июль'} />
                </td>
                <td className={verifyMonth('Август')}>
                  <EditableSpanForCPMTable value={item.Август} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Август'} />
                </td>
                <td className={verifyMonth('Сентябрь')}>
                  <EditableSpanForCPMTable value={item.Сентябрь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Сентябрь'} />
                </td>
                <td className={verifyMonth('Октябрь')}>
                  <EditableSpanForCPMTable value={item.Октябрь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Октябрь'} />
                </td>
                <td className={verifyMonth('Ноябрь')}>
                  <EditableSpanForCPMTable value={item.Ноябрь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Ноябрь'} />
                </td>
                <td className={verifyMonth('Декабрь')}>
                  <EditableSpanForCPMTable value={item.Декабрь} name={item.media} onChange={onChangeCPM} location={'mediaChoiceTable'} month={'Декабрь'} />
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
});
