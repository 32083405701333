export const findName = (name, arr) => {
  const findName = arr.find(arr => arr[name]);
  return findName;
};

export const findMediaRepeat = (arr) => {
const newArr = []
arr.map(i=> {
!newArr.find(item=> item === i) && newArr.push(i)
  return i
})
return newArr
}

export const makeArrayGroupDataFromXL = arr => {
  let newArr = [];
  arr.map(a => {
    !newArr.find(arrItem => arrItem === a.Group) && newArr.push(a.Group);
    return a;
  });
  return newArr;
};

const makeOneGroupBudgetDataFromXL = (groupName, arr) => {
  let newArr = [];
  arr.map(a => {
    a.Group === groupName && newArr.push(+a.Budget);
    return a;
  });
  return newArr;
};

const makeOneGroupBudgetPrDataFromXL = (groupName, arr) => {
  let newArr = [];
  arr.map(a => {
    a.Group === groupName && newArr.push(+a.BudgetPr);
    return a;
  });
  return newArr;
};

export const makeArrayBudgetGroupDataFromXL = arr => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }
  const keys = makeArrayGroupDataFromXL(arr);
  const arrGroup = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arrGroup);
    const findGroupArr = makeOneGroupBudgetDataFromXL(keys[i], arr);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(+findGroupBudget);
  }
  const newArr = arrGroup.map(item => Object.values(item)[0][0]);
  return newArr;
};

export const makeArrayBudgetGroupPrDataFromXL = arr => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }
  const keys = makeArrayGroupDataFromXL(arr);
  const arrGroup = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arrGroup);
    const findGroupArr = makeOneGroupBudgetPrDataFromXL(keys[i], arr);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(+findGroupBudget);
  }
  const newArr = arrGroup.map(item => Object.values(item)[0][0]);
  return newArr;
};

export function arraySum(array) {
  var sum = 0;
  for (var i = 0; i < array.length; i++) {
    sum += array[i];
  }
  return sum;
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(+value * multiplier) / multiplier;
}

export const findMainMediaByBudgetDataFromXL = (arr, budgetsArr) => {
  function getMaxBudgetOfArray(numArray) {
    return round(Math.max.apply(null, numArray));
  }
  const maxBudget = getMaxBudgetOfArray(budgetsArr);
  let mainMediaByBudget = '';
  arr.map(a => {
    if (round(+a.Budget) === maxBudget) {
      mainMediaByBudget = a.Media1;
    }
    return a;
  });
  return mainMediaByBudget;
};

export const makeArrayBudgetGroupPrDataFromXLForExelSave = arr => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }
  const keys = makeArrayGroupDataFromXL(arr);
  const arrGroup = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arrGroup);
    const findGroupArr = makeOneGroupBudgetPrDataFromXL(keys[i], arr);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(+findGroupBudget);
  }
  return arrGroup;
};

export const makeArrayBudgetGroupDataFromXLForExelSave = arr => {
  function arraySum(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
      sum += array[i];
    }
    return sum;
  }
  const keys = makeArrayGroupDataFromXL(arr);
  const arrGroup = keys.map((key, index) => {
    return {
      [key]: [],
    };
  });
  for (let i = 0; i < keys.length; i++) {
    const findNameGroup = findName(keys[i], arrGroup);
    const findGroupArr = makeOneGroupBudgetDataFromXL(keys[i], arr);
    const findGroupBudget = arraySum(findGroupArr);
    Object.values(findNameGroup)[0].push(+findGroupBudget);
  }
  return arrGroup;
};
