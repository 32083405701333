export const COLORS = {
  BannersImage: '#D31E1A',
  BannersNetworks: '#FF908D',
  BannersRichPopUp: '#FFC7C6',
  Bloggers: '#67677C',
  DigitalAudio: '#A3A3B2',
  MobileApps: '#ADB9CA',
  OLVInStream: '#DBECF8',
  OLVOutStream: '#A5D1EE',
  OLVSmartTV: '#A2C4DA',
  SocialNets: '#74A7C8',
  OOHStandardFormats: '#ABBCC7',
  OOHBigFormatsSupersites: '#738FA2',
  OOHCityformats: '#536C7D',
  Radio: '#284D64',
  TV: '#0D2E44',
  DigitalGroup: '#738fa2',
  OOHGroup: '#ff0500',
  RadioGroup: '#284D64',
  TVGroup: '#0D2E44',
  black: '#080808',
};
