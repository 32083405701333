import React from 'react';
import s from './Progress.module.css';
import spinner from '../../../../assets/images/Rolling-1s-150px.gif';

export const Progress = React.memo(props => {
  return (
    <div className={s.progressWrapper}>
      <div className={`${s.meter} ${s.red}`}>
        <span style={{ width: '100%' }}></span>
        <img
          src={spinner}
          alt='spinner'
          style={{
            position: 'fixed',
            top: '45%',
            left: '45%',
          }}
        />
      </div>
    </div>
  );
});
