const coeff = require('../assets/data/coeff.json');

export const setNextValueFlightArr = flightArr => {
  const maxValue = Math.max.apply(null, flightArr);
  const newValue = maxValue + 1;
  return newValue;
};

export const findFlightByPeriod = (flightsPeriod, flightsMediaArr, flightArr) => {
  const monthArr = [];
  flightsPeriod.map(period => {
    const monthArrCurrentFlight = [];
    findArrDates(period.startDate, period.finishDate).map(i => {
      !monthArrCurrentFlight.find(month => month === makeweek(i)) && monthArrCurrentFlight.push(makeweek(i));
      return i;
    });
    const currentMediaArr = flightsMediaArr && flightsMediaArr.find(mediaArr => mediaArr.flight === period.flight);
    const currentMediaIsChecked = currentMediaArr && currentMediaArr.media.filter(m => m.isChecked === true);

    const currentFlightNumber = flightArr.find(fl => fl.id === period.flight) && flightArr.find(fl => fl.id === period.flight).flight;
    monthArr.push({ flight: period.flight, startDate: period.startDate, currentFlightNumber: currentFlightNumber, monthArr: monthArrCurrentFlight, mediaArr: currentMediaIsChecked, current: 0 });
    return period;
  });
  return monthArr;
};

export const findSelectedMediaAllFlights = flightsMediaArr => {
  const selectedMediaAllFlights = [];
  flightsMediaArr.map(item => {
    const selectedMedia = item.media.filter(item => item.isChecked === true);
    selectedMedia.map(i => {
      selectedMediaAllFlights.push(i);
      return i;
    });
    return item;
  });
  return selectedMediaAllFlights;
};

export const findSelectedMediaAllFlightsForFlow = (flightsPeriod, flightsMediaArr, flightArr) => {
  const flightsAndPeriodsArr = findFlightByPeriod(flightsPeriod, flightsMediaArr, flightArr);
  const selectedMediaAllFlights = [];
  flightsMediaArr.map(item => {
    const selectedMedia = item.media.filter(item => item.isChecked === true);

    selectedMedia.map(i => {
      selectedMediaAllFlights.push(i);
      return i;
    });
    return item;
  });

  const sortSelectedMediaAllFlights = [];
  const sortMediaArrByGroup = [];
  selectedMediaAllFlights.map(i => {
    const repeatItem = sortMediaArrByGroup.find(item => item.group === i.group);
    const ind = sortMediaArrByGroup.indexOf(repeatItem);
    !sortMediaArrByGroup.find(a => a.group === i.group) ? sortMediaArrByGroup.push(i) : sortMediaArrByGroup.splice(ind, 0, i);
    return i;
  });
  sortMediaArrByGroup.map(i => {
    const repeatItem = sortSelectedMediaAllFlights.find(item => item.media === i.media);
    const ind = sortSelectedMediaAllFlights.indexOf(repeatItem);
    !sortSelectedMediaAllFlights.find(a => a.media === i.media) ? sortSelectedMediaAllFlights.push(i) : sortSelectedMediaAllFlights.splice(ind, 0, i);
    return i;
  });

  const newArr = [];
  const sortSelectedMediaAllFlightsWithNumber = sortSelectedMediaAllFlights.map(i => {
    newArr.push(i);
    const findFlightArr = [];
    flightsAndPeriodsArr.map(f => {
      const findMedia = f.mediaArr.find(me => me.media === i.media);
      findMedia && findFlightArr.push(f.currentFlightNumber);
      return f;
    });

    const findCurrentMediaArr = newArr.filter(media => media.media === i.media);
    return {
      CPP: i.CPP,
      campaignType: i.campaignType,
      coeff: i.coeff,
      finishBudget: i.finishBudget,
      group: i.group,
      isChecked: i.isChecked,
      k: i.k,
      media: i.media,
      startBudget: i.startBudget,
      number: findFlightArr[findCurrentMediaArr.length - 1],
    };
  });
  return sortSelectedMediaAllFlightsWithNumber;
};

export const addCurrentNumberSelectedMediaAllFlights = (selectedMediaAllFlights, currentMediaNumberArr) => {
  const selectedMediaAllFlightsWithCurrentNumber = selectedMediaAllFlights.map(i => {
    const currentMediaNumber = currentMediaNumberArr.find(fl => fl.currentFlightNumber === i.number && fl.media === i.media) && currentMediaNumberArr.find(fl => fl.currentFlightNumber === i.number && fl.media === i.media).currentNumber;
    return {
      CPP: i.CPP,
      campaignType: i.campaignType,
      coeff: i.coeff,
      finishBudget: i.finishBudget,
      group: i.group,
      isChecked: i.isChecked,
      k: i.k,
      media: i.media,
      startBudget: i.startBudget,
      number: i.number,
      currentNumber: currentMediaNumber,
    };
  });

  let mediaArr = [];
  const newMediaArr = [];
  selectedMediaAllFlightsWithCurrentNumber.map(i => {
    !mediaArr.find(a => a === i.media) && mediaArr.push(i.media);
    return i;
  });
  mediaArr = mediaArr.map((key, i) => {
    let mediaItemArr = selectedMediaAllFlightsWithCurrentNumber.filter(m => m.media === key);
    const mediaItemArrCut = [];
    mediaItemArr.map(a => {
      !mediaItemArrCut.find(t => t.currentNumber === a.currentNumber) && mediaItemArrCut.push(a);
      return a;
    });
    return {
      [key]: [mediaItemArrCut],
    };
  });

  mediaArr.map(item => {
    Object.values(item)[0][0].map(i => {
      newMediaArr.push(i);
      return i;
    });
    return item;
  });
  return newMediaArr;
};

export const addCurrentNumberFlightsAndPeriodsArr = (flightsAndPeriodsArr, currentFlightsNumberArr) => {
  const flightsAndPeriodsArrWithCurrentNumber = flightsAndPeriodsArr.map(i => {
    const currentNumber = currentFlightsNumberArr.find(fl => fl.id === i.flight) && currentFlightsNumberArr.find(fl => fl.id === i.flight).currentNumber;

    return {
      current: currentNumber,
      currentFlightNumber: i.currentFlightNumber,
      flight: i.flight,
      mediaArr: i.mediaArr,
      monthArr: i.monthArr,
      startDate: i.startDate,
    };
  });
  return flightsAndPeriodsArrWithCurrentNumber;
};

export const findSelectedMediaAllFlightsWithoutRepeat = flightsMediaArr => {
  const selectedMediaAllFlights = findSelectedMediaAllFlights(flightsMediaArr);

  const selectedMediaAllFlightsWithoutRepeat = [];
  selectedMediaAllFlights.map(i => {
    !selectedMediaAllFlightsWithoutRepeat.find(a => a.media === i.media) && selectedMediaAllFlightsWithoutRepeat.push(i);
    return i;
  });
  const selectedMediaAllFlightsTrueShape = selectedMediaAllFlightsWithoutRepeat.map(item => {
    return {
      CPP: item.CPP,
      Январь: item.CPP * coeff[item.media].find(m => m['Январь'])['Январь'],
      Февраль: item.CPP * coeff[item.media].find(m => m['Февраль'])['Февраль'],
      Март: item.CPP * coeff[item.media].find(m => m['Март'])['Март'],
      Апрель: item.CPP * coeff[item.media].find(m => m['Апрель'])['Апрель'],
      Май: item.CPP * coeff[item.media].find(m => m['Май'])['Май'],
      Июнь: item.CPP * coeff[item.media].find(m => m['Июнь'])['Июнь'],
      Июль: item.CPP * coeff[item.media].find(m => m['Июль'])['Июль'],
      Август: item.CPP * coeff[item.media].find(m => m['Август'])['Август'],
      Сентябрь: item.CPP * coeff[item.media].find(m => m['Сентябрь'])['Сентябрь'],
      Октябрь: item.CPP * coeff[item.media].find(m => m['Октябрь'])['Октябрь'],
      Ноябрь: item.CPP * coeff[item.media].find(m => m['Ноябрь'])['Ноябрь'],
      Декабрь: item.CPP * coeff[item.media].find(m => m['Декабрь'])['Декабрь'],
      coeff: item.coeff,
      coeffPromo: selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Промо') ? selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Промо') && selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Промо').coeff : 0,
      coeffImage: selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Имидж') ? selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Имидж') && selectedMediaAllFlights.find(a => a.media === item.media && a.campaignType === 'Имидж').coeff : 0,

      group: item.group,
      k: item.k,
      media: item.media,
      isChecked: true,
      campaignType: item.campaignType,
    };
  });

  return selectedMediaAllFlightsTrueShape;
};
export const numberOfDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate); //clone
  const end = new Date(endDate); //clone
  let dayCount = 0;
  while (end > start) {
    dayCount++;
    start.setDate(start.getDate() + 1);
  }
  return dayCount;
};

export const findArrDates = (startDate, endDate) => {
  const D = new Date(startDate);
  const Till = new Date(endDate);
  const result = [];
  function pad(s) {
    return ('00' + s).slice(-2);
  }
  while (D.getTime() < Till.getTime()) {
    result.push('' + D.getFullYear() + '-' + pad(D.getMonth() + 1) + '-' + pad(D.getDate()));
    D.setDate(D.getDate() + 1);
  }
  return result;
};

const makeMonthString = monthArr => {
  if (monthArr === '01') {
    return 'Январь';
  } else if (monthArr === '02') {
    return 'Февраль';
  } else if (monthArr === '03') {
    return 'Март';
  } else if (monthArr === '04') {
    return 'Апрель';
  } else if (monthArr === '05') {
    return 'Май';
  } else if (monthArr === '06') {
    return 'Июнь';
  } else if (monthArr === '07') {
    return 'Июль';
  } else if (monthArr === '08') {
    return 'Август';
  } else if (monthArr === '09') {
    return 'Сентябрь';
  } else if (monthArr === '10') {
    return 'Октябрь';
  } else if (monthArr === '11') {
    return 'Ноябрь';
  } else if (monthArr === '12') {
    return 'Декабрь';
  }
};

export const findMonthArr = flightsPeriod => {
  const monthArr = [];
  flightsPeriod.map(period => {
    findArrDates(period.startDate, period.finishDate).map(i => {
      !monthArr.find(month => month === i.substring(5, 7)) && monthArr.push(i.substring(5, 7));
      return i;
    });
    return period;
  });
  const monthArrString = monthArr.map(month => makeMonthString(month));
  return monthArrString;
};
export const findMonthArrNumber = flightsPeriod => {
  const monthArr = [];
  flightsPeriod.map(period => {
    findArrDates(period.startDate, period.finishDate).map(i => {
      !monthArr.find(month => month === i.substring(5, 7)) && monthArr.push(i.substring(5, 7));
      return i;
    });
    return period;
  });
  return monthArr;
};

export const MONTH = ['Январь1', 'Январь2', 'Январь3', 'Январь4', 'Февраль1', 'Февраль2', 'Февраль3', 'Февраль4', 'Март1', 'Март2', 'Март3', 'Март4', 'Апрель1', 'Апрель2', 'Апрель3', 'Апрель4', 'Май1', 'Май2', 'Май3', 'Май4', 'Июнь1', 'Июнь2', 'Июнь3', 'Июнь4', 'Июль1', 'Июль2', 'Июль3', 'Июль4', 'Август1', 'Август2', 'Август3', 'Август4', 'Сентябрь1', 'Сентябрь2', 'Сентябрь3', 'Сентябрь4', 'Октябрь1', 'Октябрь2', 'Октябрь3', 'Октябрь4', 'Ноябрь1', 'Ноябрь2', 'Ноябрь3', 'Ноябрь4', 'Декабрь1', 'Декабрь2', 'Декабрь3', 'Декабрь4'];

export const makeweek = date => {
  if (date.substring(5, 7) === '01' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Январь1';
  } else if (date.substring(5, 7) === '01' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Январь2';
  } else if (date.substring(5, 7) === '01' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Январь3';
  } else if (date.substring(5, 7) === '01' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Январь4';
  } else if (date.substring(5, 7) === '02' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Февраль1';
  } else if (date.substring(5, 7) === '02' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Февраль2';
  } else if (date.substring(5, 7) === '02' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Февраль3';
  } else if (date.substring(5, 7) === '02' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Февраль4';
  } else if (date.substring(5, 7) === '03' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Март1';
  } else if (date.substring(5, 7) === '03' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Март2';
  } else if (date.substring(5, 7) === '03' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Март3';
  } else if (date.substring(5, 7) === '03' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Март4';
  } else if (date.substring(5, 7) === '04' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Апрель1';
  } else if (date.substring(5, 7) === '04' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Апрель2';
  } else if (date.substring(5, 7) === '04' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Апрель3';
  } else if (date.substring(5, 7) === '04' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Апрель4';
  } else if (date.substring(5, 7) === '05' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Май1';
  } else if (date.substring(5, 7) === '05' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Май2';
  } else if (date.substring(5, 7) === '05' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Май3';
  } else if (date.substring(5, 7) === '05' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Май4';
  } else if (date.substring(5, 7) === '06' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Июнь1';
  } else if (date.substring(5, 7) === '06' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Июнь2';
  } else if (date.substring(5, 7) === '06' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Июнь3';
  } else if (date.substring(5, 7) === '06' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Июнь4';
  } else if (date.substring(5, 7) === '07' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Июль1';
  } else if (date.substring(5, 7) === '07' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Июль2';
  } else if (date.substring(5, 7) === '07' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Июль3';
  } else if (date.substring(5, 7) === '07' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Июль4';
  } else if (date.substring(5, 7) === '08' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Август1';
  } else if (date.substring(5, 7) === '08' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Август2';
  } else if (date.substring(5, 7) === '08' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Август3';
  } else if (date.substring(5, 7) === '08' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Август4';
  } else if (date.substring(5, 7) === '09' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Сентябрь1';
  } else if (date.substring(5, 7) === '09' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Сентябрь2';
  } else if (date.substring(5, 7) === '09' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Сентябрь3';
  } else if (date.substring(5, 7) === '09' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Сентябрь4';
  } else if (date.substring(5, 7) === '10' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Октябрь1';
  } else if (date.substring(5, 7) === '10' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Октябрь2';
  } else if (date.substring(5, 7) === '10' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Октябрь3';
  } else if (date.substring(5, 7) === '10' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Октябрь4';
  } else if (date.substring(5, 7) === '11' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Ноябрь1';
  } else if (date.substring(5, 7) === '11' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Ноябрь2';
  } else if (date.substring(5, 7) === '11' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Ноябрь3';
  } else if (date.substring(5, 7) === '11' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Ноябрь4';
  } else if (date.substring(5, 7) === '12' && (date.substring(8) === '01' || date.substring(8) === '02' || date.substring(8) === '03' || date.substring(8) === '04' || date.substring(8) === '05' || (date.substring(8) === '06') | (date.substring(8) === '07'))) {
    return 'Декабрь1';
  } else if (date.substring(5, 7) === '12' && (date.substring(8) === '08' || date.substring(8) === '09' || date.substring(8) === '10' || date.substring(8) === '11' || date.substring(8) === '12' || date.substring(8) === '13' || date.substring(8) === '14' || date.substring(8) === '15')) {
    return 'Декабрь2';
  } else if (date.substring(5, 7) === '12' && (date.substring(8) === '16' || date.substring(8) === '17' || date.substring(8) === '18' || date.substring(8) === '19' || date.substring(8) === '20' || date.substring(8) === '21' || date.substring(8) === '22' || date.substring(8) === '23')) {
    return 'Декабрь3';
  } else if (date.substring(5, 7) === '12' && (date.substring(8) === '24' || date.substring(8) === '25' || date.substring(8) === '26' || date.substring(8) === '27' || date.substring(8) === '28' || date.substring(8) === '29' || date.substring(8) === '30' || date.substring(8) === '31')) {
    return 'Декабрь4';
  }
};

export const makeGeoTitle = geo => {
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  if (geoPost === 'NAT') {
    return 'Россия';
  } else if (geoPost === 'Moscow') {
    return 'Москва';
  } else if (geoPost === 'Kazan') {
    return 'Миллионник';
  }
};
export const findGeo = geoName => {
  if (geoName === 'NAT') {
    return 'Россия';
  } else if (geoName === 'Moscow') {
    return 'Москва';
  } else if (geoName === 'Kazan') {
    return 'Миллионник';
  }
};
export const findBudget_CoverageName = name => {
  if (name === 'budget') {
    return 'Бюджет';
  } else if (name === 'coverage') {
    return 'Охват';
  }
};

export const findCurrentMediaGroupWithoutRepeat = currentMediaSelectedArr => {
  const currentMediaSelectedArrWithoutRepeat = [];
  currentMediaSelectedArr.map(i => {
    !currentMediaSelectedArrWithoutRepeat.find(a => a === i.group) && currentMediaSelectedArrWithoutRepeat.push(i.group);
    return i;
  });
  return currentMediaSelectedArrWithoutRepeat;
};

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
