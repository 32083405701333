export const findQuestion = (mediaGroup) => {
    if (mediaGroup ==='OOH' || mediaGroup === 'Radio' || mediaGroup === 'TV') {
      return 'Стоимость за 1000 контактов. Для максимальной точности расчетов рекомендуем использовать реальный CPT ваших клиентов, не используя настройки по умолчанию'
    } else {
      return 'Стоимость за 1000 показов. Для максимальной точности расчетов рекомендуем использовать реальный CPM ваших клиентов, не используя настройки по умолчанию'
    }
}
export const findName = (name, arr) => {
  const findName = arr.find(arr => arr[name]);
  return findName;
};
export const makeGroupsNames = arr => {
  let groups = [];
  arr.map(arrItem => {
    !groups.find(group => group === arrItem.group) && groups.push(arrItem.group);
    return arrItem;
  });
  return groups;
};
export const findFirstMediaInGroup = (groupsArr, mediaArr) => {
  const firsMediaInGroupArr = [];
  for (let i = 0; i < groupsArr.length; i++) {
    const firstMediaInGroup = mediaArr.find(media => media.group === groupsArr[i]);
    firsMediaInGroupArr.push(firstMediaInGroup.media);
  }
  return firsMediaInGroupArr;
};

export const makeFilteredMedia = (groups, selectedMediaTrueShape) => {
  const filteredMedia = [];
  const groupsArr = groups.map(media => {
    return {
      [media]: [],
    };
  });
  selectedMediaTrueShape.map(media => {
    for (let i = 0; i < groups.length; i++) {
      const findNameMedia = findName(groups[i], groupsArr);
      groups[i] === media.group && Object.values(findNameMedia)[0].push(media);
    }
    return media;
  });

  groupsArr.map(group => {
    Object.values(group)[0].map(media => {
      filteredMedia.push(media);
      return media;
    });
    return group;
  });
  return filteredMedia;
};

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function addZero(countZero) {
  var text = '1';
  while (text.length < countZero + 1) text = text + '0';
  return +text;
}

export const roundDependingOnTheOrder = value => {
  if (value < 1000) {
    return 0;
  } else {
    const poryadok = Math.round(Math.log10(value), 0) - 3;
    const f = poryadok=== 0 ? addZero(3) : addZero(poryadok)
    const result = Math.round(value / f) * f;
    return result;
   
  }
};

export const findValueView = (value, location) => {
  const valueRound = round(value);
  const valueRound2 = round(value, 2);
  const valueRound1 = round(value, 1);
  const value_WithSpace_WithoutZero = value.toLocaleString();
  const roundBudget = roundDependingOnTheOrder(value).toLocaleString();
  const value_flightBudget = valueRound.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ') + ' ₽';
  const value_flightCoverage = value + ' %';
  const value_WithSpace_Round = valueRound.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  const value_WithPercent = round(value) + ' %';
  const value_WithRuble =
    value === 0
      ? value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ') + ' ₽'
      : value
          .toString()
          .replace(/^0+/, '')
          .replace(/(\d)(?=(\d{3})+$)/g, '$1 ') + ' ₽';
  if (location === 'checkedMedia') {
    return value_WithSpace_WithoutZero;
  } else if (location === 'roundBudget') {
    return roundBudget;
  } else if (location === 'roundCoverage') {
    return valueRound1;
  } else if (location === 'checkedMedia_budgetСonstraints') {
    return value_WithRuble;
  } else if (location === 'mediaChoiceTable') {
    return value_WithSpace_Round;
  } else if (location === 'mediaChoiceTableCoeff') {
    return value_WithPercent;
  } else if (location === 'flightsCppChangeTable') {
    return valueRound2;
  } else if (location === 'flightBudget') {
    return value_flightBudget;
  } else if (location === 'flightCoverage') {
    return value_flightCoverage;
  } else {
    return value;
  }
};
