import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Period.module.css';
import { InputNumber } from '../../../Common/InputNumber/InputNumber';
import { setPeriod, setMediaIsSelected, setInputDataChanged, setMonth, setIsMonthVisible } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const Period = React.memo(props => {
  const { setPeriodIsSelected } = props;
  const dispatch = useDispatch();
  const period = useSelector(state => state.input.period);
  const onChangePeriod = e => {
    dispatch(setPeriod(+e.currentTarget.value));
    dispatch(setMediaIsSelected(false));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setMonth('Июнь'));
    dispatch(setIsMonthVisible(1));
  };
  useEffect(() => {
    period ? setPeriodIsSelected(true) : setPeriodIsSelected(false);
  }, [period, setPeriodIsSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ПЕРИОД</div>
      <div className={s.blockContent}>
        <p className={s.p}>КОЛИЧЕСТВО ДНЕЙ</p>
        <InputNumber value={period} onChange={onChangePeriod} />
      </div>
    </div>
  );
});
