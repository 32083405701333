import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step0Page.module.css';
import { StartButton } from '../../components/Common/StartButton/StartButton';
import { useHistory, useLocation } from 'react-router-dom';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Header } from '../../../ui/components/Header/Header';
import { StepButton } from '../../../ui/components/Common/StepButton/StepButton';
import Plan from '../../../assets/images/Plan.png';

export const Step0Page = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const onClickPlanMediaMixForSeveralFlights = () => {
    history.push('/flightsStep1');
  };
  const onClickPlanMediaMixForOneFlight = () => {
    history.push('/step1');
  };
  const onClickBackButton = () => {
    history.push('/');
  };
  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.startNewProjectBtnWrapper}>
        <div style={{ marginRight: '50px', position: 'relative' }}>
          <StartButton onClick={onClickPlanMediaMixForSeveralFlights}>
            <div className={s.icon}>
              <img src={Plan} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div>Спланировать медиа микс </div>
              <div className={s.redTitle}>для нескольких флайтов </div>
              <div>в течение одного года</div>
            </div>
          </StartButton>
          <div className={s.goBackBtnWrapper}>
            <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
          </div>
        </div>
        <div>
          <StartButton onClick={onClickPlanMediaMixForOneFlight}>
            <div className={s.icon}>
              <img src={Plan} alt='newProgect' style={{ width: '40px', height: '40px' }} />
            </div>
            <div className={s.title}>
              <div>Спланировать медиа микс </div>
              <div className={s.redTitle}>для одного флайта </div>
            </div>
          </StartButton>
        </div>
      </div>
    </>
  );
};
