import React from 'react';
import s from './PieChart.module.css';
import { Pie } from 'react-chartjs-2';
import { setMillion, round } from '../../../../helpers/chart_helpers';
import { COLORS } from '../../../../assets/styles/colors/colors';

export const PieChart = React.memo(props => {
  const { donutData, unitDonut } = props;
  const maxValueArr = donutData.datasets && donutData.datasets[0].data;
  const maxValue =
    maxValueArr &&
    maxValueArr.reduce(function (accumulator, currentValue) {
      return accumulator + +currentValue;
    }, 0);
  const visibleMaxValue = 5;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов
  const donutDataLabels = donutData && donutData.labels;
  const chooseLegendSize = () => {
    if (donutDataLabels && donutDataLabels.length > 10) {
      return {
        boxHeight: 10,
        padding: 1,
      };
    } else if (donutDataLabels && donutDataLabels.length > 7) {
      return {
        boxHeight: 10,
        padding: 5,
      };
    } else {
      return {};
    }
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          if (+value > maxValuePr) {
            const changedValue = unitDonut === '₽' ? round(setMillion(value), 1) : round(value, 1);
            return unitDonut === '₽' ? changedValue + ` млн` : changedValue + ` ${unitDonut}`;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              size: '14px',
              weight: 'bold',
            },
          },
        },
        anchor: 'end',
        align: 'start',
        color: function (context) {
          if (context.dataset.backgroundColor[context.dataIndex] === COLORS.BannersImage || context.dataset.backgroundColor[context.dataIndex] === COLORS.Bloggers || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHBigFormatsSupersites || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHCityformats || context.dataset.backgroundColor[context.dataIndex] === COLORS.Radio || context.dataset.backgroundColor[context.dataIndex] === COLORS.TV || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHGroup || context.dataset.backgroundColor[context.dataIndex] === COLORS.RadioGroup || context.dataset.backgroundColor[context.dataIndex] === COLORS.TVGroup) {
            return 'white';
          } else {
            return 'black';
          }
        },
      },
      legend: {
        display: true,
        position: 'right',
        align: 'centre',
        labels: chooseLegendSize(),
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            const changedTooltipItem = unitDonut === '₽' ? round(setMillion(TooltipItem.parsed), 1) : round(TooltipItem.parsed, 1);
            return unitDonut === '₽' ? TooltipItem.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.label + ': ' + changedTooltipItem + ` ${unitDonut}`;
          },
        },
      },
    },
  };

  return (
    <div className={s.wrapper}>
      <Pie width={300} height={200} data={donutData} options={options} />
    </div>
  );
});
