import { API2 } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { setFlightsisRestrictionsMediaBudget, setFlightArr, setFlightsBudget_CoverageArr, setFlightsMonth, setFlightsPeriod, setFlightsFrequencyArr, setFlightsPostData, setAud_totalCurrentValueFlightsArr, setFlightsCampaignTypesArrArr, setFlightsMediaArr, setFlightsGeo, setTargetAudienceFlights, setFlightsIsRestrictions, setCPPAllMonth, setCopyCPPAllMonth } from './flights_reducer';
import { round, setMillion, findSum, makeArrayBudget, makeArrayGroup, makeArrayBudgetPr, makeArrayBudgetGroup, makeArrayBudgetGroupPr, makeArrayBudgetGroupBar, findName, colorsArr, colorsGroupArr, makeSumBudget, findMainMediaByBudget, makeArrayGroupNames } from '../helpers/chart_helpers';
import { makeArrayGroupDataFromXL, makeArrayBudgetGroupDataFromXL, makeArrayBudgetGroupPrDataFromXL, findMainMediaByBudgetDataFromXL } from '../helpers/chart_helpers_data_from_XL';
import { findTAWithOldNames } from '../helpers/start_helpers';

const SET_FLIGHTS_CHARTS_DATA = 'SET_FLIGHTS_CHARTS_DATA';
const SET_FLIGHTS_UPLOAD = 'SET_FLIGHTS_UPLOAD';
const SET_DATA_FROM_XL_FLIGHTS = 'SET_DATA_FROM_XL_FLIGHTS';

const initialState = {
  flightsChartsData: [],
  flightsUpload: false,
  dataFromXL_Flights: [],
};
export const flights_charts_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLIGHTS_CHARTS_DATA:
      return { ...state, flightsChartsData: action.flightsChartsData };
    case SET_FLIGHTS_UPLOAD:
      return { ...state, flightsUpload: action.flightsUpload };
    case SET_DATA_FROM_XL_FLIGHTS:
      return { ...state, dataFromXL_Flights: action.dataFromXL_Flights };
    default:
      return state;
  }
};

// action creators
export const setFlightsChartsData = flightsChartsData => ({ type: SET_FLIGHTS_CHARTS_DATA, flightsChartsData });
export const setFlightsUpload = flightsUpload => ({ type: SET_FLIGHTS_UPLOAD, flightsUpload });
export const setDataFromXL_Flights = dataFromXL_Flights => ({ type: SET_DATA_FROM_XL_FLIGHTS, dataFromXL_Flights });

export const updateFlightsCharts = dataFromXL => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const updatedInputData = dataFromXL.filter(item => item.targetAudience);
  const flightsIdArr = updatedInputData.map(item => item.flight);
  const Aud_totalCurrentValue_dataFromXL = +updatedInputData.Aud_totalCurrentValue;

  const updateCPPAllMonth = dataFromXL.filter(item => item.CPPOrigin);
  const updateCopyCPPAllMonth = dataFromXL.filter(item => item.CPPCopy);
  dispatch(setCPPAllMonth(updateCPPAllMonth));
  dispatch(setCopyCPPAllMonth(updateCopyCPPAllMonth));

  const updatedFlightsArr = flightsIdArr.map((item, i) => {
    const indexArr = [];
    for (let i = 1; i < flightsIdArr.length + 1; i++) {
      indexArr.push(i);
    }
    return {
      flight: indexArr[i],
      id: +item,
    };
  });

  dispatch(setFlightArr(updatedFlightsArr));
  const updatedInputMedia = [];
  flightsIdArr.map(item => {
    const currentUpdatedInputMedia = dataFromXL
      .filter(i => i.flight === item)
      .filter(x => x.media)
      .map(z => ({
        media: z.media,
        group: z.group,
        CPP: +z.CPP,
        campaignType: z.campaignType,
        coeff: +z.coeff,
        finishBudget: +z.finishBudget,
        startBudget: +z.startBudget,
        isChecked: z.isChecked === 'FALSE' || z.isChecked === 'ЛОЖЬ' ? false : true,
        k: +z.k,
      }));

    const currentUpdatedGroupCheckedArr = dataFromXL
      .filter(i => i.flight === item)
      .filter(x => x.groupCheckbox)
      .map(z => ({
        [z.groupCheckbox]: z.isChecked === 'FALSE' || z.isChecked === 'ЛОЖЬ' ? false : true,
      }));

    const currentUpdatedCopyStartMedia = dataFromXL
      .filter(i => i.copyStartMedia)
      .map(sm => ({
        media: sm.copyStartMedia,
        group: sm.group,
        CPP: +sm.CPP,
        campaignType: sm.campaignType,
        coeff: +sm.coeff,
        finishBudget: +sm.finishBudget,
        startBudget: +sm.startBudget,
        isChecked: sm.isChecked === 'FALSE' || sm.isChecked === 'ЛОЖЬ' ? false : true,
        k: +sm.k,
      }));

    const currentUpdatedDefaultMediaData = dataFromXL
      .filter(i => i.mediaDefault)
      .map(sm => ({
        media: sm.mediaDefault,
        group: sm.group,
        CPP: +sm.CPP,
        campaignType: sm.campaignType,
        coeff: +sm.coeff,
        finishBudget: +sm.finishBudget,
        startBudget: +sm.startBudget,
        isChecked: sm.isChecked === 'FALSE' || sm.isChecked === 'ЛОЖЬ' ? false : true,
        k: +sm.k,
      }));

    updatedInputMedia.push({
      flight: +item,
      mediaIsSelected: true,
      groupCheckedArr: currentUpdatedGroupCheckedArr,
      media: currentUpdatedInputMedia,
      copyStartMedia: currentUpdatedCopyStartMedia,
      defaultMediaData: currentUpdatedDefaultMediaData,
    });

    return item;
  });

  dispatch(setAud_totalCurrentValueFlightsArr(Aud_totalCurrentValue_dataFromXL));
  dispatch(setFlightsMediaArr(updatedInputMedia));
  const updateTargetAudienceFlights = updatedInputData[0].targetAudience;
  dispatch(setTargetAudienceFlights(updateTargetAudienceFlights));

  const flightsGeo = getState().flights.flightsGeo;
  const updatedFlightsGeo = flightsGeo.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    updatedItem.geo = updatedInputData[0].geo;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return item.geo === updatedInputData[0].geo ? updatedItem : otherItem;
  });
  dispatch(setFlightsGeo(updatedFlightsGeo));

  const flightsCampaignTypesArr = getState().flights.flightsCampaignTypesArr;
  const updateFlightsCampaignTypesArr = [];
  flightsIdArr.map(item => {
    const updatedCTDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].campaignType;
    const updatedCurrentCampaignType = flightsCampaignTypesArr[0].campaignType.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.name === updatedCTDataFromXL ? selectedItem : otherItem;
    });
    updateFlightsCampaignTypesArr.push({
      flight: +item,
      campaignType: updatedCurrentCampaignType,
    });
    return item;
  });
  dispatch(setFlightsCampaignTypesArrArr(updateFlightsCampaignTypesArr));

  const updateFlightsFrequencyArr = [];
  flightsIdArr.map(item => {
    const updatedFrequencyDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].frequency;
    updateFlightsFrequencyArr.push({
      flight: +item,
      frequency: updatedFrequencyDataFromXL,
    });
    return item;
  });
  dispatch(setFlightsFrequencyArr(updateFlightsFrequencyArr));

  const updatedFlightsPeriod = [];

  flightsIdArr.map(item => {
    const updatedFlightsPeriodDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].period;
    const updatedFlightsStartDateDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].startDate;
    const updatedFlightsFinishDateDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].finishDate;
    updatedFlightsPeriod.push({
      flight: +item,
      period: +updatedFlightsPeriodDataFromXL,
      startDate: updatedFlightsStartDateDataFromXL,
      finishDate: updatedFlightsFinishDateDataFromXL,
    });
    return item;
  });
  dispatch(setFlightsPeriod(updatedFlightsPeriod));

  const flightsIsRestrictions = updatedInputData[0].flightsIsRestrictions;
  dispatch(setFlightsIsRestrictions(+updatedInputData[0].flightsIsRestrictions));
  dispatch(setFlightsMonth(updatedInputData[0].month));

  const flightsisRestrictionsMediaBudget = updatedInputData[0].flightsisRestrictionsMediaBudget;
  dispatch(setFlightsisRestrictionsMediaBudget(+updatedInputData[0].flightsisRestrictionsMediaBudget));

  const flightsBudget_CoverageArr = getState().flights.flightsBudget_CoverageArr;
  const updateFlightsBudget_CoverageArr = [];
  flightsIdArr.map(item => {
    const updatedBudget_CoverageDataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].budget_coverage;
    const updatedBudget_CoverageNAME_DataFromXL = dataFromXL.filter(i => i.flight === item).filter(x => x.targetAudience)[0].budget_coverageNAME;

    const updatedCurrentFlightsBudget_Coverage = flightsBudget_CoverageArr[0].budget_coverage.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      selectedItem.value = +updatedBudget_CoverageDataFromXL;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      otherItem.value = 0;
      return i.name === updatedBudget_CoverageNAME_DataFromXL ? selectedItem : otherItem;
    });
    updateFlightsBudget_CoverageArr.push({
      flight: +item,
      budget_coverage: updatedCurrentFlightsBudget_Coverage,
    });
    return item;
  });
  dispatch(setFlightsBudget_CoverageArr(updateFlightsBudget_CoverageArr));

  const flightsChartsData = [];
  const flightsPostData = [];
  for (const flight of updatedFlightsArr) {
    const coverages = [];

    const reachGeneral = dataFromXL.filter(item => item.targetAudience).find(i => i.flight === String(flight.id)).reachGeneral;
    const knowledgeGeneral = dataFromXL.filter(item => item.targetAudience).find(i => i.flight === String(flight.id)).knowledgeGeneral;
    const knowledgeGeneralCategory = dataFromXL.filter(item => item.targetAudience).find(i => i.flight === String(flight.id)).knowledgeGeneralCategory;
    const seleb = dataFromXL.filter(item => item.targetAudience).find(i => i.flight === String(flight.id)).seleb;

    const donutData = dataFromXL.filter(item => item.Media1).filter(i => i.flight === String(flight.id));
    const donutMedia = donutData.map(item => item.Media1);
    const donutBudget = donutData.map(item => item.Budget);
    const donutBudgetPercent = donutData.map(item => item.BudgetPr);
    const INCREACHMEDIA = dataFromXL
      .filter(item => item.Media2)
      .filter(i => i.flight === String(flight.id))
      .map(media => media.MainMedia);
    const tv = INCREACHMEDIA.find(media => media === 'TV');
    const mainMediaByBudget = findMainMediaByBudgetDataFromXL(donutData, donutBudget);
    const firstMainMedia = tv ? tv : mainMediaByBudget;

    const contributionData = dataFromXL
      .filter(item => item.Media2)
      .filter(i => i.flight === String(flight.id))
      .filter(item => item.MainMedia === firstMainMedia);
    contributionData.sort(function (x, y) {
      return x.Media2 === x.MainMedia ? -1 : y.Media2 === y.MainMedia ? 1 : 0;
    });
    const contributionMedia = contributionData.map(item => item.Media2);
    const contributionBudget = contributionData.map(item => item.Budget);
    const opticData = dataFromXL.filter(item => item.Media3).filter(i => i.flight === String(flight.id));
    const opticReach = dataFromXL.filter(item => item.Reach).filter(i => i.flight === String(flight.id));
    const opticMedia = opticData.map(item => item.Media3);
    opticReach.map(item => {
      coverages.push(+item.Option1, +item.Option2, +item.Option3, +item.Option4, +item.Option5, +item.Option6, +item.Option7, +item.Option8, +item.Option9);
      return item;
    });

    const budget_DataFromXL = `${round(
      setMillion(
        +dataFromXL
          .filter(item => item.Media3)
          .filter(i => i.flight === String(flight.id))
          .map(item => item.Option5)
          .reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue;
          }, 0)
      )
    )} млн`;

    const mediaArr = updatedInputMedia[0].media;
    const allMediaName = mediaArr.map(item => item.media);
    const allColorsArray = allMediaName.map((key, i) => {
      return {
        [key]: colorsArr[i],
      };
    });
    const allMediaGroup = makeArrayGroupNames(mediaArr.map(item => item.group));
    const allColorsGroupArray = allMediaGroup.map((key, i) => {
      return {
        [key]: colorsGroupArr[i],
      };
    });
    const donutAllColorsArray = donutMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, allColorsArray))[0],
      };
    });
    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, allColorsArray))[0],
      };
    });

    const mediaArrayOptic = opticMedia.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, allColorsArray))[0],
      };
    });
    for (let i = 0; i < opticMedia.length; i++) {
      const findNameMedia = findName(opticMedia[i], mediaArrayOptic);
      const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
      Object.values(findNameMedia)[0].push(findObj.Option1, findObj.Option2, findObj.Option3, findObj.Option4, findObj.Option5, findObj.Option6, findObj.Option7, findObj.Option8, findObj.Option9);
    }

    const currentLinePieChartData = {
      datasets: donutMedia.map((item, i) => {
        const color = allColorsArray.find(i => i[item]) && allColorsArray.find(i => i[item])[item];
        return {
          backgroundColor: color,
          data: [+donutBudgetPercent[i]],
          label: item,
        };
      }),
      labels: [''],
    };

    const currentOpticMediaMixGraphData = {
      labels: [budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL, budget_DataFromXL],
      datasets: [
        {
          type: 'line',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                return TooltipItem.dataset.label + ` ${frequencyPOST}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
              },
            },
          },
          label: 'Охват',
          borderColor: 'black',
          backgroundColor: 'black',
          borderWidth: 2,
          fill: false,
          data: coverages,
          yAxisID: 'ohvat',
          y2axis: true,

          datalabels: {
            display: false,
          },
        },
        ...mediaArrayOptic.map(array => ({
          type: 'bar',
          tooltip: {
            callbacks: {
              label: function (TooltipItem) {
                return TooltipItem.dataset.label + ` ${updatedInputData.frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
              },
            },
          },
          label: array[Object.keys(array)[1]],
          backgroundColor: array[Object.keys(array)[2]],
          data: array[Object.keys(array)[0]],
        })),
      ],
    };
    const currentDonutData = {
      labels: donutMedia,

      datasets: [
        {
          label: 'Pie',
          data: donutBudget,
          backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          hoverOffset: 4,
        },
      ],
    };

    const currentContributionOfEachMedia_data = {
      labels: contributionMedia,

      datasets: [
        {
          label: 'Contribution',
          data: contributionBudget,
          backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
          hoverOffset: 4,
        },
      ],
    };

    const updatedRfData = dataFromXL.filter(item => item.rf0);
    const rfData = updatedRfData.find(item => +item.flight === +flight.id);
    const rf = Object.values(rfData).map(item => +item);

    rf.shift();

    const currentFlightChartsData = {
      flight: +flight.id,
      // chartsData: response,
      contributionOfEachMedia_data: currentContributionOfEachMedia_data,
      optic_media_mix_graph_data: currentOpticMediaMixGraphData,
      donutData: currentDonutData,
      linePieChartData: currentLinePieChartData,
      mainMedia: firstMainMedia,
      unitDonut: '₽',
      unitOptic: '₽',
      ts_pieChart_grouping_checked: true,
      ts_pieChart_unit_checked: true,
      ts_contribution_chart_grouping_checked: true,
      ts_contribution_chart_unit_checked: true,
      ts_optic_media_mix_chart_grouping_checked: true,
      ts_optic_media_mix_chart_unit_checked: true,
      colorsArray: allColorsArray,
      colorsGroupArray: allColorsGroupArray,
      reachGeneral: +reachGeneral,
      knowledgeGeneral: +knowledgeGeneral,
      knowledgeGeneralCategory: knowledgeGeneralCategory,
      seleb: +seleb,
      rf: rf,
    };
    const frequencyPOST = updateFlightsFrequencyArr.find(item => item.flight === flight.id) && updateFlightsFrequencyArr.find(item => item.flight === flight.id).frequency;
    const periodPOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).period;
    const startDatePOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).startDate;
    const finishDatePOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).finishDate;
    const campaignType = updateFlightsCampaignTypesArr.find(item => item.flight === flight.id) && updateFlightsCampaignTypesArr.find(item => item.flight === flight.id).campaignType;
    const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;
    const media = updatedInputMedia.find(item => item.flight === flight.id) && updatedInputMedia.find(item => item.flight === flight.id).media;
    const mediaPOST = media.map(media => {
      return { media: media.media, CPP: media.CPP * media.k, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked };
    });
    const geoPOST = updatedFlightsGeo.find(geo => geo.isChecked) && updatedFlightsGeo.find(geo => geo.isChecked).name;
    const targetAudiencePOST = updateTargetAudienceFlights;
    const budget_coverage = updateFlightsBudget_CoverageArr.find(item => item.flight === flight.id) && updateFlightsBudget_CoverageArr.find(item => item.flight === flight.id).budget_coverage;
    const budget_coveragePOST = budget_coverage.find(item => item.isChecked) && +budget_coverage.find(item => item.isChecked).value;
    const budget_coverageNAME = budget_coverage.find(item => item.isChecked) && budget_coverage.find(item => item.isChecked).name;

    const currentFlightPostData = {
      flight: +flight.id,
      flightNumber: +flight.flight,
      frequency: frequencyPOST,
      period: periodPOST,
      flightsIsRestrictions: flightsIsRestrictions,
      startDate: startDatePOST,
      finishDate: finishDatePOST,
      geo: geoPOST,
      targetAudience: targetAudiencePOST,
      campaignType: campaignTypePOST,
      budget_coverage: budget_coveragePOST,
      budget_coverageNAME: budget_coverageNAME,
      media: mediaPOST,
      month: updatedInputData.month,
      flightsisRestrictionsMediaBudget: +flightsisRestrictionsMediaBudget,
      reachGeneral: +reachGeneral,
      knowledgeGeneral: +knowledgeGeneral,
      knowledgeGeneralCategory: knowledgeGeneralCategory,
      seleb: +seleb,
    };
    flightsChartsData.push(currentFlightChartsData);
    flightsPostData.push(currentFlightPostData);
  }
  dispatch(setFlightsChartsData(flightsChartsData));
  dispatch(setFlightsPostData(flightsPostData));
  dispatch(setStatus('success'));
};

export const postFlightsCharts = (unit, grouping, history) => async (dispatch, getState) => {
  const unitOptic = '₽';
  const flightsMonth = getState().flights.flightsMonth;
  function removePlus(str) {
    return +str.substring(0, str.indexOf('+'));
  }
  const flightArr = getState().flights.flightArr;
  try {
    dispatch(setStatus('loading'));
    const flightsChartsData = [];
    const flightsPostData = [];

    for (const flight of flightArr) {
      const flightsFrequencyArr = getState().flights.flightsFrequencyArr;
      const frequency = flightsFrequencyArr.find(item => item.flight === flight.id) && flightsFrequencyArr.find(item => item.flight === flight.id).frequency;
      const frequencyPOST = removePlus(frequency);

      const flightsPeriod = getState().flights.flightsPeriod;
      const period = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).period;
      const startDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).startDate;
      const finishDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).finishDate;

      const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
      const period2POST = flightsIsRestrictions === 0 ? 999 : period;
      const flightsisRestrictionsMediaBudget = getState().flights.flightsisRestrictionsMediaBudget;

      const flightsCampaignTypesArr = getState().flights.flightsCampaignTypesArr;
      const campaignType = flightsCampaignTypesArr.find(item => item.flight === flight.id) && flightsCampaignTypesArr.find(item => item.flight === flight.id).campaignType;
      const campaignTypePOST = campaignType.find(type => type.isChecked) && campaignType.find(type => type.isChecked).name;

      const flightsMediaArr = getState().flights.flightsMediaArr;
      const media = flightsMediaArr.find(item => item.flight === flight.id) && flightsMediaArr.find(item => item.flight === flight.id).media;
      const mediaPOST = media.map(media => {
        return { media: media.media, CPP: media.CPP * media.k, coeff: +media.coeff / 100, group: media.group, startBudget: media.startBudget, finishBudget: media.finishBudget, isChecked: media.isChecked };
      });

      const flightsGeo = getState().flights.flightsGeo;
      const geo = flightsGeo;
      const geoPOST = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).name;

      const targetAudience = getState().flights.targetAudienceFlights;
      const targetAudience_NSPK = findTAWithOldNames(targetAudience);
      const flightsBudget_CoverageArr = getState().flights.flightsBudget_CoverageArr;
      const budget_coverage = flightsBudget_CoverageArr.find(item => item.flight === flight.id) && flightsBudget_CoverageArr.find(item => item.flight === flight.id).budget_coverage;
      const budget_coveragePOST = budget_coverage.find(item => item.isChecked) && +budget_coverage.find(item => item.isChecked).value;
      const budget_coverageNAME = budget_coverage.find(item => item.isChecked) && budget_coverage.find(item => item.isChecked).name;
      const budget = budget_coverage.find(item => item.name === 'budget');

      const response = budget.isChecked ? await API2.post_chartDataPath1_budget(JSON.stringify({ isRestrictionsMediaBudget: flightsisRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Budget: budget_coveragePOST, media: mediaPOST, project: 'mediamix' })) : await API2.post_chartDataPath1_coverage(JSON.stringify({ isRestrictionsMediaBudget: flightsisRestrictionsMediaBudget, frequency: frequencyPOST, period: period, period2: period2POST, geo: geoPOST, targetAudience: targetAudience_NSPK, campaignType: campaignTypePOST, Reach: budget_coveragePOST, media: mediaPOST, project: 'mediamix' }));

      if (response.status === 200) {
        const allMediaName = media.map(item => item.media);
        const allColorsArray = allMediaName.map((key, i) => {
          return {
            [key]: colorsArr[i],
          };
        });
        const allMediaGroup = makeArrayGroupNames(media.map(item => item.group));
        const allColorsGroupArray = allMediaGroup.map((key, i) => {
          return {
            [key]: colorsGroupArr[i],
          };
        });
        const BUDGET = JSON.parse(response.data[0].Budget);
        const donutMedia = Object.keys(BUDGET);
        const donutBudget = makeArrayBudget(BUDGET);
        const donutBudgetPercent = makeArrayBudgetPr(BUDGET);
        const donutMediaGroup = makeArrayGroup(BUDGET);
        const donutBudgetGroup = makeArrayBudgetGroup(BUDGET);
        const donutBudgetPercentGroup = makeArrayBudgetGroupPr(BUDGET);
        const chooseLabel = () => {
          if (grouping === 'all') {
            if (unit === '%') return donutBudgetPercent;
            else if (unit === '₽') return donutBudget;
          } else if (grouping === 'group') {
            if (unit === '%') return donutBudgetPercentGroup;
            else if (unit === '₽') return donutBudgetGroup;
          }
        };
        const donutAllColorsArray = donutMedia.map((key, i) => {
          return {
            [key]: Object.values(findName(key, allColorsArray))[0],
          };
        });

        const INCREACHMEDIA = Object.keys(response.data[0].Incremental_reach);
        const tv = INCREACHMEDIA.find(media => media === 'TV');
        const mainMediaByBudget = findMainMediaByBudget(BUDGET, donutBudget);
        const firstMainMedia = tv ? tv : mainMediaByBudget;
        const INCREACH = JSON.parse(response.data[0].Incremental_reach[firstMainMedia]);
        const contributionMedia = Object.keys(INCREACH);
        const contributionBudget = makeArrayBudget(INCREACH);
        const MEDIA_MIX_COMPARE = response.data[0].Media_mix_compare;
        const budget = `${round(setMillion(makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]))))} млн`;

        const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
        const keys = Object.keys(obg);

        const reachGeneral = response.data[0].Media_mix_compare[4][0];
        const knowledgeGeneral = response.data[1];
        const rf = response.data[2];

        let coverages = [];
        const mediaArrayOptic = keys.map((key, index) => {
          return {
            [key]: [],
            name: key,
            color: Object.values(findName(key, allColorsArray))[0],
          };
        });

        for (const data of response.data[0].Media_mix_compare) {
          coverages.push(data[0]);
          for (let i = 0; i < keys.length; i++) {
            const findNameMedia = findName(keys[i], mediaArrayOptic);
            const DATA = JSON.parse(data[1]);
            Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
          }
        }

        const contributionAllColorsArray = contributionMedia.map((key, i) => {
          return {
            [key]: Object.values(findName(key, allColorsArray))[0],
          };
        });
        const currentContributionOfEachMedia_data = {
          labels: contributionMedia,

          datasets: [
            {
              label: 'Contribution',
              data: contributionBudget,
              backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
              hoverOffset: 4,
            },
          ],
        };

        const currentLinePieChartData = {
          datasets: donutMedia.map((item, i) => {
            const color = allColorsArray.find(i => i[item]) && allColorsArray.find(i => i[item])[item];
            return {
              backgroundColor: color,
              data: [donutBudgetPercent[i]],
              label: item,
            };
          }),
          labels: [''],
        };

        const currentOpticMediaMixGraphData = {
          labels: [budget, budget, budget, budget, budget, budget, budget, budget, budget],
          datasets: [
            {
              type: 'line',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
                  },
                },
              },
              label: 'Охват',
              borderColor: 'black',
              backgroundColor: 'black',
              borderWidth: 2,
              fill: false,
              data: coverages,
              yAxisID: 'ohvat',
              y2axis: true,

              datalabels: {
                display: false,
              },
            },
            ...mediaArrayOptic.map(array => ({
              type: 'bar',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    const changedTooltipItem = unitOptic === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
                    return unitOptic === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitOptic}`;
                  },
                },
              },
              label: array[Object.keys(array)[1]],
              backgroundColor: array[Object.keys(array)[2]],
              data: array[Object.keys(array)[0]],
            })),
          ],
        };

        const currentDonutData = {
          labels: grouping === 'all' ? donutMedia : donutMediaGroup,

          datasets: [
            {
              label: grouping === 'all' ? 'Pie' : 'PieGroup',
              data: chooseLabel(),
              backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
              hoverOffset: 4,
            },
          ],
        };

        const currentFlightChartsData = {
          flight: flight.id,
          chartsData: response,
          contributionOfEachMedia_data: currentContributionOfEachMedia_data,
          optic_media_mix_graph_data: currentOpticMediaMixGraphData,
          donutData: currentDonutData,
          linePieChartData: currentLinePieChartData,
          mainMedia: firstMainMedia,
          unitDonut: '₽',
          unitOptic: '₽',
          ts_pieChart_grouping_checked: true,
          ts_pieChart_unit_checked: true,
          ts_contribution_chart_grouping_checked: true,
          ts_contribution_chart_unit_checked: true,
          ts_optic_media_mix_chart_grouping_checked: true,
          ts_optic_media_mix_chart_unit_checked: true,
          colorsArray: allColorsArray,
          colorsGroupArray: allColorsGroupArray,
          reachGeneral: +reachGeneral,
          knowledgeGeneral: +knowledgeGeneral,
          knowledgeGeneralCategory: 'Все категории',
          seleb: 0,
          rf: rf,
        };
        const currentFlightPostData = {
          flight: flight.id,
          flightNumber: flight.flight,
          frequency: frequency,
          period: period,
          flightsIsRestrictions: flightsIsRestrictions,
          startDate: startDate,
          finishDate: finishDate,
          geo: geoPOST,
          targetAudience: targetAudience,
          campaignType: campaignTypePOST,
          budget_coverage: budget_coveragePOST,
          budget_coverageNAME: budget_coverageNAME,
          media: mediaPOST,
          month: flightsMonth,
          flightsisRestrictionsMediaBudget: +flightsisRestrictionsMediaBudget,
          reachGeneral: +reachGeneral,
          knowledgeGeneral: +knowledgeGeneral,
          knowledgeGeneralCategory: 'Все категории',
          seleb: 0,
        };

        flightsChartsData.push(currentFlightChartsData);
        flightsPostData.push(currentFlightPostData);
      }
    }
    dispatch(setFlightsChartsData(flightsChartsData));
    dispatch(setFlightsPostData(flightsPostData));
    dispatch(setFlightsUpload(false));
    history.push('/flightsStep3');

    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const changeFlightPieChart = (unit, grouping, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData = currentFlightChartsData.chartsData;
  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === String(flight));

  const getDataForPieChart_DataFromXL = dataFromXL => {
    const donutData = dataFromXL.filter(item => item.Media1);
    const donutMedia_DataFromXL = donutData.map(item => item.Media1);
    const donutBudget_DataFromXL = donutData.map(item => item.Budget);
    const donutBudgetPr_DataFromXL = donutData.map(item => item.BudgetPr);
    const donutMediaGroup_DataFromXL = makeArrayGroupDataFromXL(donutData);
    const donutBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXL(donutData);
    const donutBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXL(donutData);

    const donutAllColorsArray_DataFromXL = donutMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });

    const donutAllGroupColorsArray_DataFromXL = donutMediaGroup_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsGroupArray))[0],
      };
    });
    return [donutMedia_DataFromXL, donutBudget_DataFromXL, donutBudgetPr_DataFromXL, donutMediaGroup_DataFromXL, donutBudgetGroup_DataFromXL, donutBudgetPercentGroup_DataFromXL, donutAllColorsArray_DataFromXL, donutAllGroupColorsArray_DataFromXL];
  };
  const donutMedia_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[0];
  const donutBudget_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[1];
  const donutBudgetPr_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[2];
  const donutMediaGroup_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[3];
  const donutBudgetGroup_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[4];
  const donutBudgetPercentGroup_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[5];
  const donutAllColorsArray_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[6];
  const donutAllGroupColorsArray_DataFromXL = flightsUpload && getDataForPieChart_DataFromXL(dataFromXL)[7];

  const getDataForPieChart_DataFromChartsData = chartsData => {
    const BUDGET = JSON.parse(chartsData.data[0].Budget);
    const donutMedia = Object.keys(BUDGET);
    const donutBudget = makeArrayBudget(BUDGET);
    const donutBudgetPercent = makeArrayBudgetPr(BUDGET);
    const donutMediaGroup = makeArrayGroup(BUDGET);
    const donutBudgetGroup = makeArrayBudgetGroup(BUDGET);
    const donutBudgetPercentGroup = makeArrayBudgetGroupPr(BUDGET);

    const donutAllColorsArray = donutMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });
    const donutAllGroupColorsArray = donutMediaGroup.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsGroupArray))[0],
      };
    });
    return [donutMedia, donutBudget, donutBudgetPercent, donutMediaGroup, donutBudgetGroup, donutBudgetPercentGroup, donutAllColorsArray, donutAllGroupColorsArray];
  };

  const donutMedia = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[0];
  const donutBudget = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[1];
  const donutBudgetPercent = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[2];
  const donutMediaGroup = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[3];
  const donutBudgetGroup = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[4];
  const donutBudgetPercentGroup = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[5];
  const donutAllColorsArray = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[6];
  const donutAllGroupColorsArray = !flightsUpload && getDataForPieChart_DataFromChartsData(chartsData)[7];

  const chooseData = () => {
    if (flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return donutBudgetPr_DataFromXL;
        else if (unit === '₽') return donutBudget_DataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return donutBudgetPercentGroup_DataFromXL;
        else if (unit === '₽') return donutBudgetGroup_DataFromXL;
      }
    } else if (!flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return donutBudgetPercent;
        else if (unit === '₽') return donutBudget;
      } else if (grouping === 'group') {
        if (unit === '%') return donutBudgetPercentGroup;
        else if (unit === '₽') return donutBudgetGroup;
      }
    }
  };

  const chooseLabel = () => {
    if (grouping === 'all') {
      if (flightsUpload) return donutMedia_DataFromXL;
      else if (!flightsUpload) return donutMedia;
    } else if (grouping === 'group') {
      if (flightsUpload) return donutMediaGroup_DataFromXL;
      else if (!flightsUpload) return donutMediaGroup;
    }
  };
  const chooseColors = () => {
    if (grouping === 'all') {
      if (flightsUpload) return donutAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!flightsUpload) return donutAllColorsArray.map(i => Object.values(Object.values(i))[0]);
    } else if (grouping === 'group') {
      if (flightsUpload) return donutAllGroupColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!flightsUpload) return donutAllGroupColorsArray.map(i => Object.values(Object.values(i))[0]);
    }
  };

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.unitDonut = unit;
  updatedCurrentFlightChartsData.ts_pieChart_grouping_checked = grouping === 'all' ? true : false;
  updatedCurrentFlightChartsData.ts_pieChart_unit_checked = unit === '₽' ? true : false;
  updatedCurrentFlightChartsData.donutData = {
    labels: chooseLabel(),
    datasets: [
      {
        label: grouping === 'all' ? 'Pie' : 'PieGroup',
        data: chooseData(),
        backgroundColor: chooseColors(),
        hoverOffset: 4,
      },
    ],
  };

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));

  dispatch(setStatus('success'));
};

export const changeFlightContributionChart = (unit, grouping, mainMedia, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);

  const chartsData = currentFlightChartsData.chartsData;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === String(flight));
  const flightsUpload = getState().flights_charts.flightsUpload;

  const getDataForContrChart_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.Media2).filter(item => item.MainMedia === mainMedia);

    contributionData.sort(function (x, y) {
      return x.Media2 === x.MainMedia ? -1 : y.Media2 === y.MainMedia ? 1 : 0;
    });

    const contributionMedia_DataFromXL = contributionData.map(item => item.Media2);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Budget);
    const contributionBudgetPercent_DataFromXL = contributionData.map(item => item.BudgetPr);
    const contributionMediaGroup_DataFromXL = makeArrayGroupDataFromXL(contributionData);
    const contributionBudgetGroup_DataFromXL = makeArrayBudgetGroupDataFromXL(contributionData);
    const contributionBudgetPercentGroup_DataFromXL = makeArrayBudgetGroupPrDataFromXL(contributionData);

    const contributionAllColorsArray_DataFromXL = contributionMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });

    const contributionAllGroupColorsArray_DataFromXL = contributionMediaGroup_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsGroupArray))[0],
      };
    });
    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL, contributionBudgetPercent_DataFromXL, contributionMediaGroup_DataFromXL, contributionBudgetGroup_DataFromXL, contributionBudgetPercentGroup_DataFromXL, contributionAllColorsArray_DataFromXL, contributionAllGroupColorsArray_DataFromXL];
  };
  const contributionMedia_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[1];
  const contributionBudgetPercent_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[2];
  const contributionMediaGroup_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[3];
  const contributionBudgetGroup_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[4];
  const contributionBudgetPercentGroup_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[5];
  const contributionAllColorsArray_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[6];
  const contributionAllGroupColorsArray_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[7];

  const getDataForContrChart_DataFromChartsData = chartsData => {
    const INCREACH = JSON.parse(chartsData.data[0].Incremental_reach[mainMedia]);
    const contributionMedia = Object.keys(INCREACH);
    const contributionBudget = makeArrayBudget(INCREACH);
    const contributionBudgetPercent = makeArrayBudgetPr(INCREACH);
    const contributionMediaGroup = makeArrayGroup(INCREACH);
    const contributionBudgetGroup = makeArrayBudgetGroup(INCREACH);
    const contributionBudgetPercentGroup = makeArrayBudgetGroupPr(INCREACH);

    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });

    const contributionAllGroupColorsArray = contributionMediaGroup.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsGroupArray))[0],
      };
    });
    return [contributionMedia, contributionBudget, contributionBudgetPercent, contributionMediaGroup, contributionBudgetGroup, contributionBudgetPercentGroup, contributionAllColorsArray, contributionAllGroupColorsArray];
  };
  const contributionMedia = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[0];
  const contributionBudget = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[1];
  const contributionBudgetPercent = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[2];
  const contributionMediaGroup = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[3];
  const contributionBudgetGroup = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[4];
  const contributionBudgetPercentGroup = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[5];
  const contributionAllColorsArray = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[6];
  const contributionAllGroupColorsArray = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[7];

  const chooseData = () => {
    if (flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return contributionBudgetPercent_DataFromXL;
        else if (unit === '₽') return contributionBudget_DataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return contributionBudgetPercentGroup_DataFromXL;
        else if (unit === '₽') return contributionBudgetGroup_DataFromXL;
      }
    } else if (!flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return contributionBudgetPercent;
        else if (unit === '₽') return contributionBudget;
      } else if (grouping === 'group') {
        if (unit === '%') return contributionBudgetPercentGroup;
        else if (unit === '₽') return contributionBudgetGroup;
      }
    }
  };

  const chooseLabel = () => {
    if (grouping === 'all') {
      if (flightsUpload) return contributionMedia_DataFromXL;
      else if (!flightsUpload) return contributionMedia;
    } else if (grouping === 'group') {
      if (flightsUpload) return contributionMediaGroup_DataFromXL;
      else if (!flightsUpload) return contributionMediaGroup;
    }
  };

  const chooseColors = () => {
    if (grouping === 'all') {
      if (flightsUpload) return contributionAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!flightsUpload) return contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]);
    } else if (grouping === 'group') {
      if (flightsUpload) return contributionAllGroupColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]);
      else if (!flightsUpload) return contributionAllGroupColorsArray.map(i => Object.values(Object.values(i))[0]);
    }
  };

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.mainMedia = mainMedia;
  updatedCurrentFlightChartsData.ts_contribution_chart_grouping_checked = grouping === 'all' ? true : false;
  updatedCurrentFlightChartsData.ts_contribution_chart_unit_checked = unit === '₽' ? true : false;
  updatedCurrentFlightChartsData.contributionOfEachMedia_data = {
    labels: chooseLabel(),

    datasets: [
      {
        label: grouping === 'all' ? 'Contribution' : 'ContributionGroup',
        data: chooseData(),
        backgroundColor: chooseColors(),
        hoverOffset: 4,
      },
    ],
  };
  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));
  dispatch(setStatus('success'));
};

export const changeFlightOpticChart = (unit, grouping, flight) => async (dispatch, getState) => {
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);

  const flightsFrequencyArr = getState().flights.flightsFrequencyArr;
  const frequency = flightsFrequencyArr.find(item => item.flight === flight);

  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === String(flight));
  const flightsUpload = getState().flights_charts.flightsUpload;
  const unitOptic = currentFlightChartsData.unitOptic;
  dispatch(setStatus('loading'));

  const getDataForOpticChart_DataFromXL = dataFromXL => {
    const budget_DataFromXL = `${round(
      setMillion(
        +dataFromXL
          .filter(item => item.Media3)
          .map(item => item.Option5)
          .reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue;
          }, 0)
      )
    )} млн`;

    let coveragesDataFromXL = [];
    const opticData = dataFromXL.filter(item => item.Media3);
    const opticMedia = opticData.map(item => item.Media3);
    const opticReach = dataFromXL.filter(item => item.Reach);
    const mediaGroupArrDataFromXL = makeArrayGroupDataFromXL(opticData);

    opticReach.map(item => {
      coveragesDataFromXL.push(item.Option1, item.Option2, item.Option3, item.Option4, item.Option5, item.Option6, item.Option7, item.Option8, item.Option9);
      return item;
    });
    const colorsArray = currentFlightChartsData.colorsArray;
    const colorsGroupArray = currentFlightChartsData.colorsGroupArray;
    const mediaArrayOpticDataFromXL = opticMedia.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticPercentDataFromXL = opticMedia.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticGroupDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayOpticGroupPercentDataFromXL = mediaGroupArrDataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (let i = 0; i < opticMedia.length; i++) {
      const findNameMedia = findName(opticMedia[i], mediaArrayOpticDataFromXL);
      const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
      Object.values(findNameMedia)[0].push(findObj.Option1, findObj.Option2, findObj.Option3, findObj.Option4, findObj.Option5, findObj.Option6, findObj.Option7, findObj.Option8, findObj.Option9);
    }
    for (let i = 0; i < opticMedia.length; i++) {
      const findNameMedia = findName(opticMedia[i], mediaArrayOpticPercentDataFromXL);
      const findObj = opticData.find(obj => obj.Media3 === opticMedia[i]);
      Object.values(findNameMedia)[0].push(findObj.Option1Pr, findObj.Option2Pr, findObj.Option3Pr, findObj.Option4Pr, findObj.Option5Pr, findObj.Option6Pr, findObj.Option7Pr, findObj.Option8Pr, findObj.Option9Pr);
    }

    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayOpticGroupDataFromXL);
      const findObjArr = opticData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1 = 0;
      let sumOption2 = 0;
      let sumOption3 = 0;
      let sumOption4 = 0;
      let sumOption5 = 0;
      let sumOption6 = 0;
      let sumOption7 = 0;
      let sumOption8 = 0;
      let sumOption9 = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1 += +findObjArr[i].Option1;
        sumOption2 += +findObjArr[i].Option2;
        sumOption3 += +findObjArr[i].Option3;
        sumOption4 += +findObjArr[i].Option4;
        sumOption5 += +findObjArr[i].Option5;
        sumOption6 += +findObjArr[i].Option6;
        sumOption7 += +findObjArr[i].Option7;
        sumOption8 += +findObjArr[i].Option8;
        sumOption9 += +findObjArr[i].Option9;
      }
      Object.values(findNameMedia)[0].push(+sumOption1, +sumOption2, +sumOption3, +sumOption4, +sumOption5, +sumOption6, +sumOption7, +sumOption8, +sumOption9);
    }
    for (let i = 0; i < mediaGroupArrDataFromXL.length; i++) {
      const findNameMedia = findName(mediaGroupArrDataFromXL[i], mediaArrayOpticGroupPercentDataFromXL);
      const findObjArr = opticData.filter(obj => obj.Group === mediaGroupArrDataFromXL[i]);
      let sumOption1Pr = 0;
      let sumOption2Pr = 0;
      let sumOption3Pr = 0;
      let sumOption4Pr = 0;
      let sumOption5Pr = 0;
      let sumOption6Pr = 0;
      let sumOption7Pr = 0;
      let sumOption8Pr = 0;
      let sumOption9Pr = 0;
      for (let i = 0; i < findObjArr.length; i++) {
        sumOption1Pr += +findObjArr[i].Option1Pr;
        sumOption2Pr += +findObjArr[i].Option2Pr;
        sumOption3Pr += +findObjArr[i].Option3Pr;
        sumOption4Pr += +findObjArr[i].Option4Pr;
        sumOption5Pr += +findObjArr[i].Option5Pr;
        sumOption6Pr += +findObjArr[i].Option6Pr;
        sumOption7Pr += +findObjArr[i].Option7Pr;
        sumOption8Pr += +findObjArr[i].Option8Pr;
        sumOption9Pr += +findObjArr[i].Option9Pr;
      }
      Object.values(findNameMedia)[0].push(+sumOption1Pr, +sumOption2Pr, +sumOption3Pr, +sumOption4Pr, +sumOption5Pr, +sumOption6Pr, +sumOption7Pr, +sumOption8Pr, +sumOption9Pr);
    }
    return [coveragesDataFromXL, mediaArrayOpticDataFromXL, mediaArrayOpticPercentDataFromXL, mediaArrayOpticGroupDataFromXL, mediaArrayOpticGroupPercentDataFromXL, budget_DataFromXL];
  };

  const coveragesDataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[0];
  const mediaArrayOpticDataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[1];
  const mediaArrayOpticPercentDataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[2];
  const mediaArrayOpticGroupDataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[3];
  const mediaArrayOpticGroupPercentDataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[4];
  const budget_DataFromXL = flightsUpload && getDataForOpticChart_DataFromXL(dataFromXL)[5];

  const chartsData = currentFlightChartsData.chartsData;
  const getDataForOpticChart_DataFromChartsData = chartsData => {
    let coverages = [];
    const MEDIA_MIX_COMPARE = chartsData.data[0].Media_mix_compare;
    const budget = `${round(setMillion(makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]))))} млн`;
    const obg = JSON.parse(MEDIA_MIX_COMPARE[1][1]);
    const mediaGroupArr = makeArrayGroup(obg);
    const keys = Object.keys(obg);
    const colorsArray = currentFlightChartsData.colorsArray;
    const colorsGroupArray = currentFlightChartsData.colorsGroupArray;

    const mediaArrayOptic = keys.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticPercent = keys.map(key => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsArray))[0],
      };
    });
    const mediaArrayOpticGroup = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    const mediaArrayOpticGroupPercent = mediaGroupArr.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, colorsGroupArray))[0],
      };
    });
    for (const data of chartsData.data[0].Media_mix_compare) {
      const DATA = JSON.parse(data[1]);
      const sum = findSum(DATA);
      coverages.push(data[0]);
      const opticGroupBudgetArr = makeArrayBudgetGroupBar(DATA);
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayOptic);
        Object.values(findNameMedia)[0].push(DATA[keys[i]].Budget);
      }
      for (let i = 0; i < keys.length; i++) {
        const findNameMedia = findName(keys[i], mediaArrayOpticPercent);
        Object.values(findNameMedia)[0].push(+((+DATA[keys[i]].Budget * 100) / sum).toFixed(1));
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayOpticGroup);
        const findBudgetMedia = findName(mediaGroupArr[i], opticGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(Object.values(findBudgetMedia)[0][0]);
      }
      for (let i = 0; i < mediaGroupArr.length; i++) {
        const findNameMedia = findName(mediaGroupArr[i], mediaArrayOpticGroupPercent);
        const findBudgetMedia = findName(mediaGroupArr[i], opticGroupBudgetArr);
        findBudgetMedia && Object.values(findNameMedia)[0].push(+((+Object.values(findBudgetMedia)[0][0] * 100) / sum).toFixed(1));
      }
    }
    return [coverages, mediaArrayOptic, mediaArrayOpticPercent, mediaArrayOpticGroup, mediaArrayOpticGroupPercent, budget];
  };

  const coverages = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[0];
  const mediaArrayOptic = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[1];
  const mediaArrayOpticPercent = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[2];
  const mediaArrayOpticGroup = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[3];
  const mediaArrayOpticGroupPercent = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[4];
  const budget = !flightsUpload && getDataForOpticChart_DataFromChartsData(chartsData)[5];
  const budgetFinal = !flightsUpload ? budget : budget_DataFromXL;

  const chooseData = () => {
    if (flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayOpticPercentDataFromXL;
        else if (unit === '₽') return mediaArrayOpticDataFromXL;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayOpticGroupPercentDataFromXL;
        else if (unit === '₽') return mediaArrayOpticGroupDataFromXL;
      }
    } else if (!flightsUpload) {
      if (grouping === 'all') {
        if (unit === '%') return mediaArrayOpticPercent;
        else if (unit === '₽') return mediaArrayOptic;
      } else if (grouping === 'group') {
        if (unit === '%') return mediaArrayOpticGroupPercent;
        else if (unit === '₽') return mediaArrayOpticGroup;
      }
    }
  };

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.unitOptic = unit;
  updatedCurrentFlightChartsData.ts_optic_media_mix_chart_grouping_checked = grouping === 'all' ? true : false;
  updatedCurrentFlightChartsData.ts_optic_media_mix_chart_unit_checked = unit === '₽' ? true : false;
  updatedCurrentFlightChartsData.optic_media_mix_graph_data = {
    labels: [budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal, budgetFinal],
    datasets: [
      {
        type: 'line',
        tooltip: {
          callbacks: {
            label: function (TooltipItem) {
              return TooltipItem.dataset.label + ` ${frequency}: ` + round(TooltipItem.parsed.y, 2) + ` %`;
            },
          },
        },
        label: 'Охват',
        borderColor: 'black',
        backgroundColor: 'black',
        borderWidth: 2,
        fill: false,
        data: flightsUpload ? coveragesDataFromXL : coverages,
        yAxisID: 'ohvat',
        y2axis: true,
        datalabels: {
          display: false,
        },
      },
      ...chooseData().map(array => ({
        type: 'bar',
        tooltip: {
          callbacks: {
            label: function (TooltipItem) {
              const changedTooltipItem = unitOptic === '₽' ? round(setMillion(TooltipItem.parsed.y), 1) : round(TooltipItem.parsed.y, 1);
              return unitOptic === '₽' ? TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн' : TooltipItem.dataset.label + ': ' + changedTooltipItem + ` ${unitOptic}`;
            },
          },
        },
        label: array[Object.keys(array)[1]],
        backgroundColor: array[Object.keys(array)[2]],
        data: array[Object.keys(array)[0]],
      })),
    ],
  };

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));

  dispatch(setStatus('success'));
};

export const getFlightKnowledge = (knowledgeGeneralCategory, seleb, flight) => async (dispatch, getState) => {
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const flightsPostData = getState().flights.flightsPostData;
  const currentFlightsPostData = flightsPostData.find(item => item.flight === flight);

  const targetAudience = getState().flights.targetAudienceFlights;
  const rf = currentFlightChartsData.rf;
  try {
    dispatch(setStatus('loading'));
    const response = await API2.post_calc_awer(JSON.stringify({ rf: rf, targetAudience: targetAudience, project: 'mediamix', celeb: seleb, cat: knowledgeGeneralCategory }));

    if (response.status === 200) {
      const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
      updatedCurrentFlightChartsData.knowledgeGeneral = response.data;
      updatedCurrentFlightChartsData.knowledgeGeneralCategory = knowledgeGeneralCategory;
      updatedCurrentFlightChartsData.seleb = seleb;

      const updatedСurrentFlightPostData = { ...currentFlightsPostData };
      updatedСurrentFlightPostData.knowledgeGeneral = response.data;
      updatedСurrentFlightPostData.knowledgeGeneralCategory = knowledgeGeneralCategory;
      updatedСurrentFlightPostData.seleb = seleb;

      const updatedFlightArr = flightsChartsData.map(item => {
        const newItem = updatedCurrentFlightChartsData;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsChartsData(updatedFlightArr));

      const updatedPostDataArr = flightsPostData.map(item => {
        const newItem = updatedСurrentFlightPostData;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsPostData(updatedPostDataArr));
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
