import React from 'react';
import s from './Step.module.css';

export const Step = React.memo(props => {
  const { stepNumber, stepTitle, stepDescription } = props;
  return (
    <div className={s.stepWrapper}>
      <div className={s.stepNumber}>{stepNumber}</div>
      <div className={s.stepTitle}>{stepTitle}</div>
      <div className={s.stepDescription}>{stepDescription}</div>
    </div>
  );
});
