import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Frequency.module.css';
import { Select } from '../../../Common/Select/Select';
import { setFrequency, setInputDataChanged } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const Frequency = React.memo(props => {
  const { setFrequencyIsSelected } = props;
  const dispatch = useDispatch();
  const frequency = useSelector(state => state.input.frequency);
  const onChangeFrequency = selectItem => {
    dispatch(setFrequency(selectItem));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  useEffect(() => {
    frequency ? setFrequencyIsSelected(true) : setFrequencyIsSelected(false);
  }, [frequency, setFrequencyIsSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        ЭФФЕКТИВНАЯ <br />
        ЧАСТОТА
      </div>
      <div className={s.blockContent}>
        <p className={s.p}>ВЫБРАТЬ</p>
        <Select name={'frequency'} value={frequency} onChange={onChangeFrequency} question info={'на эту частоту будут произведены расчеты'} items={['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+', '20+']} />
      </div>
    </div>
  );
});
