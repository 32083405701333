import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step5Page.module.css';
import { Header } from '../../components/Header/Header';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { SaveXLSPart1 } from '../../components/SaveXLSPart1/SaveXLSPart1';
import { SaveXLSPart2 } from '../../components/SaveXLSPart2/SaveXLSPart2';
import { SaveXLSGrowth } from '../../components/SaveXLSGrowth/SaveXLSGrowth';
import { SaveXLSCoverage } from '../../components/SaveXLSCoverage/SaveXLSCoverage';
import { LoadingButton } from '../../components/Common/LoadingButton/LoadingButton';
import { useHistory, useLocation } from 'react-router-dom';
import { InvestmentGrowthGraph } from '../../components/Charts/InvestmentGrowthGraph/InvestmentGrowthGraph';
// import { InvestmentCoverageGraph } from '../../components/Charts/InvestmentCoverageGraph/InvestmentCoverageGraph';
// import { changeGrowthChart, changeCoverageChart, setUnitGrowth, setUnitCoverage, setTs_investment_growth_chart_unit_checked, setTs_investment_growth_chart_grouping_checked, setTs_investment_coverage_chart_unit_checked, setTs_investment_coverage_chart_grouping_checked } from '../../../bll/charts_part2_reducer';
import { changeGrowthChart, setUnitGrowth, setTs_investment_growth_chart_unit_checked, setTs_investment_growth_chart_grouping_checked } from '../../../bll/charts_part2_reducer';
import { ToggleSwitch } from '../../components/Common/ToggleSwitch/TiggleSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { ExportPPTPart1 } from '../../components/ExportPPTPart1/ExportPPTPart1';
import { ExportPPTPart2 } from '../../components/ExportPPTPart2/ExportPPTPart2';
import { ExportPPTGrowth } from '../../components/ExportPPTGrowth/ExportPPTGrowth';
import { ExportPPTCoverage } from '../../components/ExportPPTCoverage/ExportPPTCoverage';
import { ExportXLSPart2 } from '../../components/ExportXLSPart2/ExportXLSPart2';
import { ExportXLSGrowth } from '../../components/ExportXLSGrowth/ExportXLSGrowth';
import { ExportXLSCoverage } from '../../components/ExportXLSCoverage/ExportXLSCoverage';
import { ExportXLSPart1 } from '../../components/ExportXLSPart1/ExportXLSPart1';
// import { postCoverage } from '../../../bll/inputParameters_reducer';
// import { postGrowth } from '../../../bll/inputParameters_reducer';
// import {  setIsCoverageChartRendered } from '../../../bll/charts_part2_reducer';
// import { setIsGrowthChartRendered } from '../../../bll/charts_part2_reducer';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { Knowledge } from '../../components/Knowledge/Knowledge';

export const Step5Page = React.memo(props => {
  const { chartsData, allChartsDataGrowth, allChartsDataCoverage, investment_coverage_graph_data, investment_growth_graph_data, optic_media_mix_graph_data, donutData, contributionOfEachMedia_data, postData, mainMedia, dataFromXL, knowledgeArr } = props;
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const isGrowthChartRendered = useSelector(state => state.charts2.isGrowthChartRendered);
  const isCoverageChartRendered = useSelector(state => state.charts2.isCoverageChartRendered);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isExportPPTPart2, setIsExportPPTPart2] = useState(false);
  const onClickPowerPointBtn = () => {
    setIsExportPPTPart2(true);
  };

  const [loadingAccordionCollapsed, setLoadingAccordionCollapsed] = useState(true);
  const unitGrowth = useSelector(state => state.charts2.unitGrowth);
  // const unitCoverage = useSelector(state => state.charts2.unitCoverage);

  const ts_investment_growth_chart_grouping_checked = useSelector(state => state.charts2.ts_investment_growth_chart_grouping_checked);
  const ts_investment_growth_chart_unit_checked = useSelector(state => state.charts2.ts_investment_growth_chart_unit_checked);
  // const ts_investment_coverage_chart_grouping_checked = useSelector(state => state.charts2.ts_investment_coverage_chart_grouping_checked);
  // const ts_investment_coverage_chart_unit_checked = useSelector(state => state.charts2.ts_investment_coverage_chart_unit_checked);

  const onClickUnLoadBtn = () => {
    setIsExportPPTPart2(false);
    setLoadingAccordionCollapsed(!loadingAccordionCollapsed);
  };
  const onClickTSGrowth = (e, toggleName) => {
    if (toggleName === 'toggleSwitch_investment_growth_chart_graph_ruble_checked') {
      dispatch(setUnitGrowth(e.currentTarget.checked ? '₽' : '%'));
      dispatch(setTs_investment_growth_chart_unit_checked(e.currentTarget.checked));
      if (ts_investment_growth_chart_grouping_checked) {
        dispatch(changeGrowthChart(e.currentTarget.checked ? '₽' : '%', 'all'));
      } else {
        dispatch(changeGrowthChart(e.currentTarget.checked ? '₽' : '%', 'group'));
      }
    } else if (toggleName === 'toggleSwitch_investment_growth_chart_graph_all_group_checked') {
      dispatch(setTs_investment_growth_chart_grouping_checked(e.currentTarget.checked));
      if (ts_investment_growth_chart_unit_checked) {
        dispatch(changeGrowthChart('₽', e.currentTarget.checked ? 'all' : 'group'));
      } else {
        dispatch(changeGrowthChart('%', e.currentTarget.checked ? 'all' : 'group'));
      }
    }
  };

  // const onClickTSCoverage = (e, toggleName) => {
  //   if (toggleName === 'toggleSwitch_investment_coverage_chart_ruble_checked') {
  //     dispatch(setUnitCoverage(e.currentTarget.checked ? '₽' : '%'));
  //     dispatch(setTs_investment_coverage_chart_unit_checked(e.currentTarget.checked));

  //     if (ts_investment_coverage_chart_grouping_checked) {
  //       dispatch(changeCoverageChart(e.currentTarget.checked ? '₽' : '%', 'all'));
  //     } else {
  //       dispatch(changeCoverageChart(e.currentTarget.checked ? '₽' : '%', 'group'));
  //     }
  //   } else if (toggleName === 'toggleSwitch_investment_coverage_chart_all_group_checked') {
  //     dispatch(setTs_investment_coverage_chart_grouping_checked(e.currentTarget.checked));
  //     if (ts_investment_coverage_chart_unit_checked) {
  //       dispatch(changeCoverageChart('₽', e.currentTarget.checked ? 'all' : 'group'));
  //     } else {
  //       dispatch(changeCoverageChart('%', e.currentTarget.checked ? 'all' : 'group'));
  //     }
  //   }
  // };

  const onClickNextButton = () => {
    history.push('/');
  };
  const onClickBackButton = () => {
    history.push('/step4');
  };

  const chooseSaveXLS = () => {
    if (isChartsUpload && isGrowthChartRendered && isCoverageChartRendered) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSPart2 allChartsDataGrowth={allChartsDataGrowth} allChartsDataCoverage={allChartsDataCoverage} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />{' '}
        </div>
      );
    } else if (isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSGrowth allChartsDataGrowth={allChartsDataGrowth} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSCoverage allChartsDataCoverage={allChartsDataCoverage} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0 && Object.keys(allChartsDataCoverage).length > 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSPart2 allChartsDataGrowth={allChartsDataGrowth} allChartsDataCoverage={allChartsDataCoverage} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0 && Object.keys(allChartsDataCoverage).length > 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSCoverage allChartsDataCoverage={allChartsDataCoverage} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0 && Object.keys(allChartsDataCoverage).length === 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSGrowth allChartsDataGrowth={allChartsDataGrowth} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0 && Object.keys(allChartsDataCoverage).length === 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSGrowth allChartsDataGrowth={allChartsDataGrowth} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataCoverage).length > 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSCoverage allChartsDataCoverage={allChartsDataCoverage} chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    } else if (!isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataCoverage).length === 0) {
      return (
        <div className={s.saveBtn}>
          <SaveXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />
        </div>
      );
    }
  };
  const chooseExportXLS = () => {
    if (isChartsUpload && isGrowthChartRendered && isCoverageChartRendered) {
      return <ExportXLSPart2 allChartsDataGrowth={allChartsDataGrowth} allChartsDataCoverage={allChartsDataCoverage} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered) {
      return <ExportXLSCoverage allChartsDataCoverage={allChartsDataCoverage} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered) {
      return <ExportXLSGrowth allChartsDataGrowth={allChartsDataGrowth} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0 && Object.keys(allChartsDataCoverage).length > 0) {
      return <ExportXLSPart2 allChartsDataGrowth={allChartsDataGrowth} allChartsDataCoverage={allChartsDataCoverage} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0 && Object.keys(allChartsDataCoverage).length === 0) {
      return <ExportXLSGrowth allChartsDataGrowth={allChartsDataGrowth} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0 && Object.keys(allChartsDataCoverage).length > 0) {
      return <ExportXLSCoverage allChartsDataCoverage={allChartsDataCoverage} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0 && Object.keys(allChartsDataCoverage).length === 0) {
      return <ExportXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(allChartsDataGrowth).length > 0) {
      return <ExportXLSGrowth allChartsDataGrowth={allChartsDataGrowth} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(allChartsDataGrowth).length === 0) {
      return <ExportXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataCoverage).length > 0) {
      return <ExportXLSCoverage allChartsDataCoverage={allChartsDataCoverage} postData={postData} chartsData={chartsData} mainMedia={mainMedia} dataFromXL={dataFromXL} />;
    } else if (!isChartsUpload && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(allChartsDataCoverage).length === 0) {
      return <ExportXLSPart1 chartsData={chartsData} mainMedia={mainMedia} postData={postData} dataFromXL={dataFromXL} />;
    }
  };
  const chooseExportPPT = () => {
    if (isExportPPTPart2 && isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length > 0 && Object.keys(investment_growth_graph_data).length > 0) {
      return <ExportPPTPart2 investment_coverage_graph_data={investment_coverage_graph_data} investment_growth_graph_data={investment_growth_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} />;
    } else if (isExportPPTPart2 && isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length === 0 && Object.keys(investment_growth_graph_data).length > 0) {
      return <ExportPPTGrowth investment_growth_graph_data={investment_growth_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} />;
    } else if (isExportPPTPart2 && isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length > 0 && Object.keys(investment_growth_graph_data).length === 0) {
      return <ExportPPTCoverage investment_coverage_graph_data={investment_coverage_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} />;
    } else if (isExportPPTPart2 && isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length === 0 && Object.keys(investment_growth_graph_data).length === 0) {
      return <ExportPPTPart1 donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} optic_media_mix_graph_data={optic_media_mix_graph_data} />;
    } else if (isExportPPTPart2 && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length > 0) {
      return <ExportPPTCoverage investment_coverage_graph_data={investment_coverage_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} />;
    } else if (isExportPPTPart2 && !isGrowthChartRendered && isCoverageChartRendered && Object.keys(investment_coverage_graph_data).length === 0) {
      return <ExportPPTPart1 donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} optic_media_mix_graph_data={optic_media_mix_graph_data} />;
    } else if (isExportPPTPart2 && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(investment_growth_graph_data).length > 0) {
      return <ExportPPTGrowth investment_growth_graph_data={investment_growth_graph_data} optic_media_mix_graph_data={optic_media_mix_graph_data} donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} />;
    } else if (isExportPPTPart2 && isGrowthChartRendered && !isCoverageChartRendered && Object.keys(investment_growth_graph_data).length === 0) {
      return <ExportPPTPart1 donutData={donutData} contributionOfEachMedia_data={contributionOfEachMedia_data} optic_media_mix_graph_data={optic_media_mix_graph_data} />;
    }
  };

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step3Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />

        <div className={s.chartsWrapper}>
          <div className={s.investment_growth_chart_wrapper}>
            {/* {isGrowthChartRendered === false && (
              <div style={{ position: 'absolute', top: '45%', left: '45%' }}>
                <StepButton
                  onClick={() => {
                    dispatch(postGrowth());
                    dispatch(setIsGrowthChartRendered(true));
                  }}
                  title={'Запустить расчет'}
                />
              </div>
            )} */}

            <h4 className={s.chartTitle}>Влияние прироста инвестиций в рекламу на медиамикс </h4>
            <h4 className={s.chartTitle}> и совокупный охват</h4>
            <div className={s.toggleSwitchWrapper}>
              <div className={s.toggleSwitch_ruble}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSGrowth(e, 'toggleSwitch_investment_growth_chart_graph_ruble_checked');
                  }}
                  checked={ts_investment_growth_chart_unit_checked}
                  labelStart={'₽'}
                  labelEnd={'%'}
                  id={'investment_growth_chart_ruble_%'}
                />
              </div>
              <div className={s.toggleSwitch_all_group}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSGrowth(e, 'toggleSwitch_investment_growth_chart_graph_all_group_checked');
                  }}
                  checked={ts_investment_growth_chart_grouping_checked}
                  labelStart={'медиа'}
                  labelEnd={'группы медиа'}
                  id={'investment_growth_chart_all_group'}
                />
              </div>
            </div>
            {Object.values(investment_growth_graph_data).length > 0 && <InvestmentGrowthGraph investment_growth_graph_data={investment_growth_graph_data} unitGrowth={unitGrowth} />}
          </div>
          {Object.values(investment_growth_graph_data).length > 0 && (
            <div className={s.knowledgeBlock}>
              <h4 className={`${s.chartTitle} ${s.knowledgeBlockTitle}`}>Влияние совокупного охвата для опций спланированного рекламного размещения на знание креатива </h4>
              <div className={s.knowledgeWrapper}>
                <Knowledge investment_growth_graph_data={investment_growth_graph_data} knowledgeArr={knowledgeArr} />
              </div>
            </div>
          )}

          {/* <div className={s.investment_coverage_chart_wrapper}>
            {isCoverageChartRendered === false && (
              <div style={{ position: 'absolute', top: '45%', left: '45%' }}>
                <StepButton
                  onClick={() => {
                    dispatch(postCoverage());
                    dispatch(setIsCoverageChartRendered(true));
                  }}
                  title={'Запустить расчет'}
                />
              </div>
            )}

            <h4 className={s.chartTitle}>Рекомендуемый объем инвестиций и медиамикс</h4>
            <h4 className={s.chartTitle}>исходя из различных уровней совокупного охвата</h4>
            <div className={s.toggleSwitchWrapper}>
              <div className={s.toggleSwitch_ruble}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSCoverage(e, 'toggleSwitch_investment_coverage_chart_ruble_checked');
                  }}
                  checked={ts_investment_coverage_chart_unit_checked}
                  labelStart={'₽'}
                  labelEnd={'%'}
                  id={'investment_coverage_chart_ruble_%'}
                />
              </div>
              <div className={s.toggleSwitch_all_group}>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClickTSCoverage(e, 'toggleSwitch_investment_coverage_chart_all_group_checked');
                  }}
                  checked={ts_investment_coverage_chart_grouping_checked}
                  labelStart={'медиа'}
                  labelEnd={'группы медиа'}
                  id={'investment_coverage_chart_all_group'}
                />
              </div>
            </div>
            <InvestmentCoverageGraph investment_coverage_graph_data={investment_coverage_graph_data} unitCoverage={unitCoverage} />
          </div> */}
        </div>
        <div>
          <StepButton onClick={onClickNextButton} title={'На главную'} />
          {chooseSaveXLS()}
          {(isGrowthChartRendered || isCoverageChartRendered) && (
            <div className={s.loadingBtn}>
              <StepButton onClick={onClickUnLoadBtn} title={'Выгрузить\n расчеты'}>
                <FontAwesomeIcon icon={faCloudDownloadAlt} className={s.icon} />
              </StepButton>

              {!loadingAccordionCollapsed && (
                <div className={s.loadingBtnsWrapper}>
                  <div className={s.loadingBtns}>
                    {chooseExportXLS()}
                    <LoadingButton onClick={onClickPowerPointBtn} title={'PowerPoint'} />
                    {chooseExportPPT()}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
