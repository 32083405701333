import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step3Page.module.css';
import { Header } from '../../components/Header/Header';
import { Checkbox } from '../../components/Common/Checkbox/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Budget } from '../../components/InputParameters/Settings/Budget/Budget';
import { Frequency } from '../../components/InputParameters/Settings/Frequency/Frequency';
import { StepButton } from '../../components/Common/StepButton/StepButton';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckedMedia } from '../../components/InputParameters/Settings/CheckedMedia/CheckedMedia';
import { postPart1, setInputDataChanged, setIsRestrictionsMediaBudget } from '../../../bll/inputParameters_reducer';
import { setCurrentPathname } from '../../../bll/app_reducer';

export const Step3Page = React.memo(props => {
  const { inputDataChanged } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const onClickNextButton = () => {
    inputDataChanged === true ? dispatch(postPart1('₽', 'all', history)) : history.push('/step4');
    dispatch(setInputDataChanged(false));
  };
  const onClickBackButton = () => {
    history.push('/step2');
  };

  const [budgetIsSelected, setBudgetIsSelected] = useState(false);
  const [frequencyIsSelected, setFrequencyIsSelected] = useState(false);

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  const isRestrictionsMediaBudget = useSelector(state => state.input.isRestrictionsMediaBudget);
  const onChangeisRestrictionsMediaBudget = e => {
    e.target.checked ? dispatch(setIsRestrictionsMediaBudget(1)) : dispatch(setIsRestrictionsMediaBudget(0));
    dispatch(setInputDataChanged(true));
  };
  return (
    <>
      <Header />
      <div className={s.step1Wrapper}>
        <StepButton onClick={onClickBackButton} title={'Вернуться назад'} />
        <div className={s.inputParametersBlock}>
          <h1>Вводные параметры для флайта</h1>

          <div className={s.settingsBlock}>
            <div className={s.iconWrapper}>
              <FontAwesomeIcon icon={faCog} size='3x' className={s.icon} />
            </div>
            <div className={s.settingsBlockContentWrapper}>
              <div className={s.settingsBlockContent}>
                <Budget setBudgetIsSelected={setBudgetIsSelected} />
                <Frequency setFrequencyIsSelected={setFrequencyIsSelected} />
              </div>
              <h3>При необходимости вы можете задать ограничения по рекомендуемому бюджету на медиа: </h3>
              <CheckedMedia />
              <Checkbox
                onChangeChecked={e => {
                  onChangeisRestrictionsMediaBudget(e);
                }}
                checked={isRestrictionsMediaBudget === 1 ? true : false}
                label={'Использовать встроенные ограничения на минимально возможный бюджет.'}
                id={'isRestrictionsMediaBudget'}
              />
            </div>
          </div>
        </div>
        <StepButton onClick={onClickNextButton} title={'Следующий шаг'} isDisabled={budgetIsSelected && frequencyIsSelected ? false : true} />
      </div>
    </>
  );
});
