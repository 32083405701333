import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Geo.module.css';
import { Checkbox } from '../../../Common/Checkbox/Checkbox';
import { setGeo, setMediaIsSelected, setInputDataChanged, setMonth, setIsMonthVisible } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const Geo = React.memo(props => {
  const { setGeoIsSelected } = props;
  const dispatch = useDispatch();
  const geo = useSelector(state => state.input.geo);
  const handleOnChange = selectedName => {
    const updatedGeoState = geo.map(item => {
      const selectedItem = { ...item };
      selectedItem.isChecked = !selectedItem.isChecked;
      const otherItem = { ...item };
      otherItem.isChecked = false;
      return item.name === selectedName ? selectedItem : otherItem;
    });
    dispatch(setGeo(updatedGeoState));
    dispatch(setMediaIsSelected(false));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setMonth('Июнь'));
    dispatch(setIsMonthVisible(1));
  };

  const someGeoSelected = geo.find(item => item.isChecked === true);

  useEffect(() => {
    someGeoSelected && someGeoSelected.isChecked ? setGeoIsSelected(true) : setGeoIsSelected(false);
  }, [geo, setGeoIsSelected, someGeoSelected]);

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>ГЕО</div>
      <div className={s.blockContent}>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('NAT');
            }}
            checked={geo[0].isChecked}
            label={'Россия 0+'}
            id={'national'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Moscow');
            }}
            checked={geo[1].isChecked}
            label={'Москва'}
            id={'Moscow'}
          />
        </div>
        <div className={s.checkboxWrapper}>
          <Checkbox
            onChangeChecked={() => {
              handleOnChange('Kazan');
            }}
            checked={geo[2].isChecked}
            label={'Город\n миллионник'}
            id={'Kazan'}
          />
        </div>
      </div>
    </div>
  );
});
