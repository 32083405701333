import React from 'react';
import s from './LoadingButton.module.css';

export const LoadingButton = React.memo(props => {
  const { onClick, title, isDisabled, children, id } = props;
  return (
    <div>
      <button id={id} className={s.stepButton} onClick={onClick} disabled={isDisabled ? true : false}>
        {children}
        {title}
      </button>
    </div>
  );
});
