import { makeFilteredMedia, makeGroupsNames } from '../helpers/mediaChoiceTable_helpers';
import { setStatus, setError } from './app_reducer';
import { findSelectedMediaAllFlightsWithoutRepeat } from '../helpers/flightTable_helpers';

const SET_FLIGHT_ARR = 'SET_FLIGHT_ARR';
const SET_TARGET_AUDIENCE_FLIGHTS = 'SET_TARGET_AUDIENCE_FLIGHTS';
const SET_FLIGHTS_CAMPAIGN_TYPES_ARR = 'SET_FLIGHTS_CAMPAIGN_TYPES_ARR';
const SET_FLIGHTS_MEDIA_ARR = 'SET_FLIGHTS_MEDIA_ARR';
const SET_AUD_TOTALCURRENTVALUE_FLIGHTS_ARR = 'SET_AUD_TOTALCURRENTVALUE_FLIGHTS_ARR';
const SET_FLIGHTS_GEO = 'SET_FLIGHTS_GEO';
const SET_FLIGHTS_FREQUENCY_ARR = 'SET_FLIGHTS_FREQUENCY_ARR';
const SET_FLIGHTS_BUDGET_COVERAGE_ARR = 'SET_FLIGHTS_BUDGET_COVERAGE_ARR';
const SET_FLIGHTS_MONTH = 'SET_FLIGHTS_MONTH';
const SET_FLIGHTS_PERIOD = 'SET_FLIGHTS_PERIOD';
const SET_FLIGHTS_INPUT_STEP1_DATA_CHANGED = 'SET_FLIGHTS_INPUT_STEP1_DATA_CHANGED';
const SET_FLIGHTS_INPUT_STEP2_DATA_CHANGED = 'SET_FLIGHTS_INPUT_STEP2_DATA_CHANGED';
const SET_FLIGHTS_POST_DATA = 'SET_FLIGHTS_POST_DATA';
const SET_FLIGHTS_IS_RESTRICTIONS = 'SET_FLIGHTS_IS_RESTRICTIONS';
const SET_CPP_ALL_MONTH = 'SET_CPP_ALL_MONTH';
const SET_COPY_CPP_ALL_MONTH = 'SET_COPY_CPP_ALL_MONTH';
const SET_FLIGHTS_ISRESTRICTIONS_MEDIA_BUDGET = 'SET_FLIGHTS_ISRESTRICTIONS_MEDIA_BUDGET';

const initialState = {
  Aud_totalCurrentValueFlightsArr: [],
  flightsMonth: 'Июнь',
  flightArr: [{ flight: 1, id: 3333 }],
  targetAudienceFlights: '',
  flightsCampaignTypesArr: [
    {
      flight: 3333,
      campaignType: [
        { id: 1, name: 'Промо', isChecked: false },
        { id: 2, name: 'Имидж', isChecked: false },
      ],
    },
  ],
  flightsGeo: [
    { id: 1, name: 'NAT', isChecked: false, geo: 'NAT' },
    { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow' },
    { id: 3, name: 'Kazan', isChecked: false, geo: 'Kazan' },
  ],
  flightsMediaArr: [
    {
      flight: 3333,
      mediaIsSelected: false,
      groupCheckedArr: [],
      media: [],
      copyStartMedia: [],
      defaultMediaData: [],
    },
  ],
  flightsFrequencyArr: [
    {
      flight: 3333,
      frequency: '',
    },
  ],
  flightsBudget_CoverageArr: [
    {
      flight: 3333,
      budget_coverage: [
        { id: 1, name: 'budget', value: 0, isChecked: false },
        { id: 2, name: 'coverage', value: 0, isChecked: false },
        { id: 3, name: 'different_budget', value: 0, isChecked: false },
      ],
    },
  ],
  flightsPeriod: [
    {
      flight: 3333,
      period: 0,
      startDate: 0,
      finishDate: 0,
    },
  ],
  flightsIsRestrictions: 1,
  flightsInputStep1DataChanged: false,
  flightsInputStep2DataChanged: false,
  flightsPostData: [],
  CPPAllMonth: [],
  copyCPPAllMonth: [],
  flightsisRestrictionsMediaBudget: 1,
};
export const flights_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLIGHT_ARR:
      return { ...state, flightArr: action.flightArr };
    case SET_FLIGHTS_MONTH:
      return { ...state, flightsMonth: action.flightsMonth };
    case SET_TARGET_AUDIENCE_FLIGHTS:
      return { ...state, targetAudienceFlights: action.targetAudienceFlights };
    case SET_FLIGHTS_CAMPAIGN_TYPES_ARR:
      return { ...state, flightsCampaignTypesArr: action.flightsCampaignTypesArr };
    case SET_FLIGHTS_GEO:
      return { ...state, flightsGeo: action.flightsGeo };
    case SET_FLIGHTS_MEDIA_ARR:
      return { ...state, flightsMediaArr: action.flightsMediaArr };
    case SET_AUD_TOTALCURRENTVALUE_FLIGHTS_ARR:
      return { ...state, Aud_totalCurrentValueFlightsArr: action.Aud_totalCurrentValueFlightsArr };
    case SET_FLIGHTS_FREQUENCY_ARR:
      return { ...state, flightsFrequencyArr: action.flightsFrequencyArr };
    case SET_FLIGHTS_BUDGET_COVERAGE_ARR:
      return { ...state, flightsBudget_CoverageArr: action.flightsBudget_CoverageArr };
    case SET_FLIGHTS_PERIOD:
      return { ...state, flightsPeriod: action.flightsPeriod };
    case SET_FLIGHTS_INPUT_STEP1_DATA_CHANGED:
      return { ...state, flightsInputStep1DataChanged: action.flightsInputStep1DataChanged };
    case SET_FLIGHTS_INPUT_STEP2_DATA_CHANGED:
      return { ...state, flightsInputStep2DataChanged: action.flightsInputStep2DataChanged };
    case SET_FLIGHTS_POST_DATA:
      return { ...state, flightsPostData: action.flightsPostData };
    case SET_FLIGHTS_IS_RESTRICTIONS:
      return { ...state, flightsIsRestrictions: action.flightsIsRestrictions };
    case SET_CPP_ALL_MONTH:
      return { ...state, CPPAllMonth: action.CPPAllMonth };
    case SET_COPY_CPP_ALL_MONTH:
      return { ...state, copyCPPAllMonth: action.copyCPPAllMonth };
    case SET_FLIGHTS_ISRESTRICTIONS_MEDIA_BUDGET:
      return { ...state, flightsisRestrictionsMediaBudget: action.flightsisRestrictionsMediaBudget };
    default:
      return state;
  }
};

// action creators
export const setFlightArr = flightArr => ({ type: SET_FLIGHT_ARR, flightArr });
export const setFlightsMonth = flightsMonth => ({ type: SET_FLIGHTS_MONTH, flightsMonth });
export const setTargetAudienceFlights = targetAudienceFlights => ({ type: SET_TARGET_AUDIENCE_FLIGHTS, targetAudienceFlights });
export const setFlightsCampaignTypesArrArr = flightsCampaignTypesArr => ({ type: SET_FLIGHTS_CAMPAIGN_TYPES_ARR, flightsCampaignTypesArr });
export const setFlightsGeo = flightsGeo => ({ type: SET_FLIGHTS_GEO, flightsGeo });
export const setFlightsMediaArr = flightsMediaArr => ({ type: SET_FLIGHTS_MEDIA_ARR, flightsMediaArr });
export const setAud_totalCurrentValueFlightsArr = Aud_totalCurrentValueFlightsArr => ({ type: SET_AUD_TOTALCURRENTVALUE_FLIGHTS_ARR, Aud_totalCurrentValueFlightsArr });
export const setFlightsFrequencyArr = flightsFrequencyArr => ({ type: SET_FLIGHTS_FREQUENCY_ARR, flightsFrequencyArr });
export const setFlightsBudget_CoverageArr = flightsBudget_CoverageArr => ({ type: SET_FLIGHTS_BUDGET_COVERAGE_ARR, flightsBudget_CoverageArr });
export const setFlightsPeriod = flightsPeriod => ({ type: SET_FLIGHTS_PERIOD, flightsPeriod });
export const setFlightsInputStep1DataChanged = flightsInputStep1DataChanged => ({ type: SET_FLIGHTS_INPUT_STEP1_DATA_CHANGED, flightsInputStep1DataChanged });
export const setFlightsInputStep2DataChanged = flightsInputStep2DataChanged => ({ type: SET_FLIGHTS_INPUT_STEP2_DATA_CHANGED, flightsInputStep2DataChanged });
export const setFlightsPostData = flightsPostData => ({ type: SET_FLIGHTS_POST_DATA, flightsPostData });
export const setFlightsIsRestrictions = flightsIsRestrictions => ({ type: SET_FLIGHTS_IS_RESTRICTIONS, flightsIsRestrictions });
export const setCPPAllMonth = CPPAllMonth => ({ type: SET_CPP_ALL_MONTH, CPPAllMonth });
export const setCopyCPPAllMonth = copyCPPAllMonth => ({ type: SET_COPY_CPP_ALL_MONTH, copyCPPAllMonth });
export const setFlightsisRestrictionsMediaBudget = flightsisRestrictionsMediaBudget => ({ type: SET_FLIGHTS_ISRESTRICTIONS_MEDIA_BUDGET, flightsisRestrictionsMediaBudget });

export const setFlightsSelectedMedia = flight => async (dispatch, getState) => {
  const allMediaFromBack = getState().input.allMediaFromBack;

  const targetAudienceFlights = getState().flights.targetAudienceFlights;
  const flightsCampaignTypesArr = getState().flights.flightsCampaignTypesArr;
  const currentCampaignTypeFlight = flightsCampaignTypesArr.find(item => item.flight === flight) && flightsCampaignTypesArr.find(item => item.flight === flight).campaignType;

  const Aud_total = getState().input.Aud_total;
  const campaignTypePOST = currentCampaignTypeFlight.find(type => type.isChecked) && currentCampaignTypeFlight.find(type => type.isChecked).name;

  const flightsGeo = getState().flights.flightsGeo;
  const geoPOST = flightsGeo.find(geo => geo.isChecked) && flightsGeo.find(geo => geo.isChecked).geo;

  const selectedMedia = allMediaFromBack.filter(media => media.TA === targetAudienceFlights && media.Geo === geoPOST && media.type === campaignTypePOST);
  const Aud_totalCurrentValue = Aud_total.filter(item => item.Aud === targetAudienceFlights && item.Geo === geoPOST)[0] && Aud_total.filter(item => item.Aud === targetAudienceFlights && item.Geo === geoPOST)[0].total;

  const makeGroups = arr => {
    let groups = [];
    arr.map(arrItem => {
      !groups.find(group => group === arrItem.group) && groups.push(arrItem.group);
      return arrItem;
    });
    const groupsArr = groups.map(media => {
      return {
        [media]: false,
      };
    });
    return groupsArr;
  };
  const groups = makeGroups(selectedMedia);

  const selectedMediaTrueShape = selectedMedia.map(media => {
    return {
      media: media.Media,
      CPP: +media.CPP / media.k,
      coeff: +media.coeff * 100,
      group: media.group,
      startBudget: 0,
      finishBudget: 999999999,
      isChecked: false,
      k: media.k,
      campaignType: campaignTypePOST,
    };
  });

  const groupsNames = makeGroupsNames(selectedMediaTrueShape);
  const filteredMedia = makeFilteredMedia(groupsNames, selectedMediaTrueShape);

  const flightsMediaArr = getState().flights.flightsMediaArr;
  const updatedCurrentMediaFlight = {
    flight: flight,
    mediaIsSelected: false,
    groupCheckedArr: groups,
    media: filteredMedia,
    copyStartMedia: filteredMedia,
    defaultMediaData: filteredMedia,
  };

  const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
    const newItem = updatedCurrentMediaFlight;
    return item.flight === flight ? newItem : item;
  });

  try {
    dispatch(setStatus('loading'));
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setAud_totalCurrentValueFlightsArr(Aud_totalCurrentValue));

    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const setRightFlightsSelectedMedia = () => async (dispatch, getState) => {
  const allMediaFromBack = getState().input.allMediaFromBack;
  const flightsMediaArr = getState().flights.flightsMediaArr;
  const flightsCampaignTypesArr = getState().flights.flightsCampaignTypesArr;
  const targetAudienceFlights = getState().flights.targetAudienceFlights;
  const flightsPeriod = getState().flights.flightsPeriod;
  const sortFlightsPeriod = flightsPeriod.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  const Aud_total = getState().input.Aud_total;

  const flightsGeo = getState().flights.flightsGeo;
  const geoPOST = flightsGeo.find(geo => geo.isChecked) && flightsGeo.find(geo => geo.isChecked).geo;

  const Aud_totalCurrentValue = Aud_total.filter(item => item.Aud === targetAudienceFlights && item.Geo === geoPOST)[0] && Aud_total.filter(item => item.Aud === targetAudienceFlights && item.Geo === geoPOST)[0].total;

  const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
    const currentCampaignTypeFlight = flightsCampaignTypesArr.find(i => i.flight === item.flight) && flightsCampaignTypesArr.find(i => i.flight === item.flight).campaignType;
    const campaignTypePOST = currentCampaignTypeFlight.find(type => type.isChecked) && currentCampaignTypeFlight.find(type => type.isChecked).name;
    const selectedMedia = allMediaFromBack.filter(media => media.TA === targetAudienceFlights && media.Geo === geoPOST && media.type === campaignTypePOST);
    const updatedMediaState = item.media.map(el => {
      return { ...el };
    });

    updatedMediaState.map(item => {
      const currentObg = selectedMedia.find(i => i.Media === item.media);
      item.CPP = +currentObg.CPP / currentObg.k;
      item.coeff = +currentObg.coeff * 100;
      item.k = +currentObg.k;

      return item;
    });
    const newItem = { ...item };
    newItem.media = updatedMediaState;
    newItem.copyStartMedia = updatedMediaState;
    newItem.defaultMediaData = updatedMediaState;
    return newItem;
  });

  const newSelectedMediaAllFlights = findSelectedMediaAllFlightsWithoutRepeat(updatedStateFlightsMediaArr);
  try {
    dispatch(setStatus('loading'));
    dispatch(setFlightsPeriod(sortFlightsPeriod));
    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));
    dispatch(setAud_totalCurrentValueFlightsArr(Aud_totalCurrentValue));
    dispatch(setCPPAllMonth(newSelectedMediaAllFlights));
    dispatch(setCopyCPPAllMonth(newSelectedMediaAllFlights));
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};
