import React from 'react';
import s from './ContributionOfEachMedia.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { round } from '../../../../helpers/chart_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { COLORS } from '../../../../assets/styles/colors/colors';
Chart.register(ChartDataLabels);

export const ContributionOfEachMedia = React.memo(props => {
  const { contributionOfEachMedia_data } = props;
  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }
  const contrData = contributionOfEachMedia_data.datasets && contributionOfEachMedia_data.datasets[0].data;
  const maxValue = getMaxOfArray(contrData);
  const visibleMaxValue = 10;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        position: 'top',
        ticks: {
          callback: function (val, index) {
            return round(val, 1);
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 11,
          },
          padding: 2,
        },
      },
    },
    plugins: {
      datalabels: {
        display: 'true',
        color: function (context) {
          if (context.dataset.backgroundColor[context.dataIndex] === COLORS.BannersImage || context.dataset.backgroundColor[context.dataIndex] === COLORS.Bloggers || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHBigFormatsSupersites || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHCityformats || context.dataset.backgroundColor[context.dataIndex] === COLORS.Radio || context.dataset.backgroundColor[context.dataIndex] === COLORS.TV || context.dataset.backgroundColor[context.dataIndex] === COLORS.OOHGroup || context.dataset.backgroundColor[context.dataIndex] === COLORS.RadioGroup || context.dataset.backgroundColor[context.dataIndex] === COLORS.TVGroup) {
            return 'white';
          } else {
            return 'black';
          }
        },
        formatter: function (value) {
          if (value > maxValuePr) {
            const changedValue = round(value, 1);
            return changedValue + ` %`;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '14px',
            },
          },
        },
        anchor: 'centre',
        align: 'centre',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.label + ': ' + round(TooltipItem.parsed.x, 1) + ` %`;
          },
        },
      },
    },
    indexAxis: 'y',
  };
  return (
    <div className={s.wrapper}>
      <Bar data={contributionOfEachMedia_data} options={options} width={300} height={200} />
    </div>
  );
});
