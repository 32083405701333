export const findTAWithOldNames = (TA) => {
    if(TA==='18+') {
        return '18-45 BC'
    } else if (TA==='25-54') {
        return '25-55 BC'
    } else if (TA==='25-44') {
       return '25-45 BC'
    } else if (TA==='25-64') {
       return '25-55 BC'
    } else if (TA==='18-54') {
       return '18-45 BC'
    } else if (TA==='18-24') {
      return '18-45 BC'
    } else if (TA==='18-35') {
      return '18-45 BC'
    } else {
      return TA
    }
}