import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './TargetAudience.module.css';
import { Select } from '../../../Common/Select/Select';
import { setTargetAudience, setMediaIsSelected, setInputDataChanged, setMonth, setIsMonthVisible } from '../../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../../bll/charts_reducer';

export const TargetAudience = React.memo(props => {
  const { setTargetAudienceIsSelected, targetAudienceData } = props;
  const dispatch = useDispatch();
  const targetAudience = useSelector(state => state.input.targetAudience);
  const onChangeTargetAudience = selectItem => {
    dispatch(setTargetAudience(selectItem));
    dispatch(setMediaIsSelected(false));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setMonth('Июнь'));
    dispatch(setIsMonthVisible(1));
  };
  useEffect(() => {
    targetAudience ? setTargetAudienceIsSelected(true) : setTargetAudienceIsSelected(false);
  }, [targetAudience, setTargetAudienceIsSelected]);
  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitle}>
        ЦЕЛЕВАЯ <br /> АУДИТОРИЯ
      </div>
      <div className={s.blockContent}>
        <Select name={'targetAudience'} value={targetAudience} onChange={onChangeTargetAudience} items={targetAudienceData} location={'targetAudience'} />
      </div>
    </div>
  );
});
