import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../Common/Select/Select';
import { setFlightsCampaignTypesArrArr, setFlightsInputStep1DataChanged, setFlightsMediaArr } from '../../../../bll/flights_reducer';

export const FlightsCampaignType = React.memo(props => {
  const { flight, isDisabled } = props;
  const dispatch = useDispatch();
  const flightsCampaignTypesArr = useSelector(state => state.flights.flightsCampaignTypesArr);
  const currentCampaignType = flightsCampaignTypesArr.find(item => item.flight === flight);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);
  const currentMediaArr = flightsMediaArr.find(item => item.flight === flight);
  const media = currentMediaArr.media;
  const currentCampaignTypeName = currentCampaignType.campaignType.find(i => i.isChecked === true) ? currentCampaignType.campaignType.find(i => i.isChecked === true).name : '';

  const handleOnChange = (selectedName, flight) => {
    const updatedFindCurrentCampaignTypesArr = currentCampaignType.campaignType.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.name === selectedName ? selectedItem : otherItem;
    });

    const updatedStateflightsCampaignTypesArr = flightsCampaignTypesArr.map(item => {
      const newItem = { ...item };
      newItem.campaignType = updatedFindCurrentCampaignTypesArr;
      return item.flight === flight ? newItem : item;
    });

    const currentCampaignTypeFlight = updatedStateflightsCampaignTypesArr.find(item => item.flight === flight) && updatedStateflightsCampaignTypesArr.find(item => item.flight === flight).campaignType;
    const campaignTypePOST = currentCampaignTypeFlight.find(type => type.isChecked) && currentCampaignTypeFlight.find(type => type.isChecked).name;

    const updatedCheckedState = media.map(item => {
      return {
        media: item.media,
        CPP: item.CPP === '' ? item.CPP : +item.CPP,
        coeff: item.coeff,
        group: item.group,
        startBudget: item.startBudget,
        finishBudget: item.finishBudget,
        isChecked: item.isChecked,
        k: item.k,
        campaignType: campaignTypePOST,
      };
    });

    const updatedStateFlightsMediaArr = flightsMediaArr.map(item => {
      const updatedCurrentMediaFlight = {
        flight: item.flight,
        mediaIsSelected: item.mediaIsSelected,
        groupCheckedArr: item.groupCheckedArr,
        media: updatedCheckedState,
        copyStartMedia: updatedCheckedState,
        defaultMediaData: item.defaultMediaData,
      };
      const newItem = updatedCurrentMediaFlight;
      return item.flight === flight ? newItem : item;
    });

    dispatch(setFlightsMediaArr(updatedStateFlightsMediaArr));

    dispatch(setFlightsCampaignTypesArrArr(updatedStateflightsCampaignTypesArr));
    dispatch(setFlightsInputStep1DataChanged(true));
  };

  return <Select isDisabled={isDisabled} name={flight} value={currentCampaignTypeName} onChange={handleOnChange} items={['Промо', 'Имидж']} location={'severalFlights'} />;
});
