import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { LoadingButton } from '../Common/LoadingButton/LoadingButton';
import { makeArrayGroup, makeArrayBudgetPrGroupBar, makeArrayBudgetGroupBar, makeArrayCPPMedia, makeSumBudget, makeArrayCPPMediaDataFromXL } from '../../../helpers/chart_helpers';
import { makeArrayBudgetGroupDataFromXLForExelSave, makeArrayBudgetGroupPrDataFromXLForExelSave, makeArrayGroupDataFromXL } from '../../../helpers/chart_helpers_data_from_XL';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExportXLSFlights = props => {
  const flightsChartsData = useSelector(state => state.flights_charts.flightsChartsData);
  const flightsPostData = useSelector(state => state.flights.flightsPostData);
  const dataFromXL_Flights = useSelector(state => state.flights_charts.dataFromXL_Flights);
  const flightsUpload = useSelector(state => state.flights_charts.flightsUpload);
  const flightArr = useSelector(state => state.flights.flightArr);
  const flightsMediaArr = useSelector(state => state.flights.flightsMediaArr);

  const getDataForExportXLSPart1_DataFromXL = () => {
    const DATA_dataFromXL = [];
    const DATA_Sheet2_dataFromXL = [];

    flightsPostData.map(item => {
      const donutMedia = dataFromXL_Flights.filter(item => item.Media1).filter(i => i.flight === String(item.flight));
      const donutMediaGroup = makeArrayGroupDataFromXL(donutMedia);
      const donutBudgetGroup = makeArrayBudgetGroupDataFromXLForExelSave(donutMedia);
      const donutBudgetPercentGroup = makeArrayBudgetGroupPrDataFromXLForExelSave(donutMedia);
      const mainMedia = flightsChartsData.find(i => i.flight === item.flight).mainMedia;
      const FLIGHT = flightArr.find(i => i.id === item.flight) && flightArr.find(i => i.id === item.flight).flight;
      const TA = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).targetAudience;
      const frequency = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).frequency;
      const knowledgeGeneral = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).knowledgeGeneral;

      const checkedMedia = dataFromXL_Flights
        .filter(item => item.media)
        .filter(i => i.flight === String(item.flight))
        .map(item => ({
          media: item.media,
          group: item.group,
          CPP: item.CPP === '' ? item.CPP : +item.CPP,
          coeff: +item.coeff * 100,
          finishBudget: +item.finishBudget,
          startBudget: +item.startBudget,
          isChecked: item.isChecked === 'FALSE' || item.isChecked === 'ЛОЖЬ' ? false : true,
        }))
        .filter(media => media.isChecked === true);

      const CPPGroup = makeArrayCPPMediaDataFromXL(donutMediaGroup, checkedMedia, donutMedia, donutBudgetGroup);
      const contributionData = dataFromXL_Flights
        .filter(item => item.Media2)
        .filter(i => i.flight === String(item.flight))
        .filter(media => media.MainMedia === mainMedia);
      const contrGroupBudget = makeArrayBudgetGroupDataFromXLForExelSave(contributionData);

      const mediaData = dataFromXL_Flights.filter(item => item.media).filter(i => i.flight === String(item.flight));
      const reach = +dataFromXL_Flights.filter(i => i.flight === String(item.flight)).filter(item => item.Reach)[0].Option5;
      const budget = +dataFromXL_Flights
        .filter(item => item.Media3)
        .filter(i => i.flight === String(item.flight))
        .map(item => item.Option5)
        .reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue;
        }, 0);
      const dataset_Total_reach = dataFromXL_Flights.filter(i => i.flight === String(item.flight)).filter(item => item.Total_reach)[0];
      const dataset_Total_cprp = dataFromXL_Flights.filter(i => i.flight === String(item.flight)).filter(item => item.Total_cprp)[0];
      const Total_reach_cprp = [
        [+dataset_Total_reach['1+'], +dataset_Total_reach['2+'], +dataset_Total_reach['3+'], +dataset_Total_reach['4+'], +dataset_Total_reach['5+'], +dataset_Total_reach['6+'], +dataset_Total_reach['7+'], +dataset_Total_reach['8+'], +dataset_Total_reach['9+'], +dataset_Total_reach['10+']],
        [+dataset_Total_cprp['1+'], +dataset_Total_cprp['2+'], +dataset_Total_cprp['3+'], +dataset_Total_cprp['4+'], +dataset_Total_cprp['5+'], +dataset_Total_cprp['6+'], +dataset_Total_cprp['7+'], +dataset_Total_cprp['8+'], +dataset_Total_cprp['9+'], +dataset_Total_cprp['10+']],
      ];
      const dataset_Media_group_reach_DataFromXL = dataFromXL_Flights
        .filter(item => item.media_group_reach)
        .filter(i => i.flight === String(item.flight))
        .map(item => ({
          [item.media_group_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
        }));
      const dataset_Media_reach_DataFromXL = dataFromXL_Flights
        .filter(item => item.media_reach)
        .filter(i => i.flight === String(item.flight))
        .map(item => ({
          [item.media_reach]: [+item['1+'], +item['2+'], +item['3+'], +item['4+'], +item['5+'], +item['6+'], +item['7+'], +item['8+'], +item['9+'], +item['10+']],
        }));

      DATA_dataFromXL.push([
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Бренд',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              title: '',
              width: { wpx: 170 },
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: 'Флайт',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: FLIGHT,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Целевая аудитория',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(TA),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный бюджет',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: budget,

                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупное знание',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: +knowledgeGeneral / 100,

                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный Frequency',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(frequency),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный охват 5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: reach / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Частота',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Совокупный охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPRP',
              font: { bold: true },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: '1+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[0][0] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][0],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '2+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][1] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][1],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '3+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][2] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][2],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '4+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][3] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][3],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][4] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][4],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '6+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][5] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][5],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '7+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][6] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][6],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '8+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][7] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][7],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '9+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][8] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][8],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '10+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][9] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][9],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Медиа',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Уникальный вклад в охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Сумма',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Доля в медиа миксе',
              width: { wpx: 130 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Frequency',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'OTS',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPT',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 1+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 2+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 3+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 4+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 5+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 6+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 7+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 8+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 9+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 10+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: donutMediaGroup.map((item, i) => {
            const contrGroupBudValue = Object.values(contrGroupBudget.find(media => Object.keys(media)[0] === item))[0][0];
            const donutGroupBudValue = Object.values(donutBudgetGroup.find(media => Object.keys(media)[0] === item))[0][0];
            const donutGroupBudPrValue = Object.values(donutBudgetPercentGroup.find(media => Object.keys(media)[0] === item))[0][0];
            const CPPGroupValue = Object.values(CPPGroup.find(media => Object.keys(media)[0] === item))[0][0];
            const ReachGroupVal = Object.values(dataset_Media_group_reach_DataFromXL.find(media => Object.keys(media)[0] === item))[0];
            return [
              {
                value: String(item),
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: contrGroupBudValue / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutGroupBudValue,
                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutGroupBudPrValue / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                style: {
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: CPPGroupValue,
                style: {
                  numFmt: '0',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: ReachGroupVal[0] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[1] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[2] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[3] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[4] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[5] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[6] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[7] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[8] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[9] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              // item, contrGroupBudValue, donutGroupBudValue, donutGroupBudPrValue, '', '', CPPGroupValue, ReachGroupVal[0], ReachGroupVal[1], ReachGroupVal[2], ReachGroupVal[3], ReachGroupVal[4], ReachGroupVal[5], ReachGroupVal[6], ReachGroupVal[7], ReachGroupVal[8], ReachGroupVal[9]
            ];
          }),
        },
      ]);
      DATA_Sheet2_dataFromXL.push([
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Бренд',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              title: '',
              width: { wpx: 170 },
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: 'Флайт',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: FLIGHT,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Целевая аудитория',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(TA),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный бюджет',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: budget,

                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  numFmt: '0₽',
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупное знание',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: +knowledgeGeneral / 100,

                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный Frequency',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(frequency),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный охват 5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: reach / 100,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  numFmt: '0%',
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Частота',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Совокупный охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPRP',
              font: { bold: true },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: '1+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[0][0] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][0],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '2+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][1] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][1],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '3+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][2] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][2],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '4+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][3] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][3],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][4] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][4],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '6+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][5] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][5],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '7+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][6] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][6],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '8+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][7] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][7],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '9+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][8] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][8],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '10+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][9] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][9],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Медиа',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Уникальный вклад в охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Сумма',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Доля в медиа миксе',
              width: { wpx: 130 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Frequency',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'OTS',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPT',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 1+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 2+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 3+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 4+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 5+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 6+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 7+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 8+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 9+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 10+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: donutMedia.map((item, i) => {
            const contrBudVal = contributionData.find(media => media.Media2 === item.Media1) && +contributionData.find(media => media.Media2 === item.Media1).Budget;
            const donutBudVal = donutMedia.find(media => media.Media1 === item.Media1) && +donutMedia.find(media => media.Media1 === item.Media1).Budget;
            const donutBudPrVal = donutMedia.find(media => media.Media1 === item.Media1) && +donutMedia.find(media => media.Media1 === item.Media1).BudgetPr;
            const CPPVal = mediaData.find(media => media.media === item.Media1) && +mediaData.find(media => media.media === item.Media1).CPP;
            const ReachVal = Object.values(dataset_Media_reach_DataFromXL.find(media => Object.keys(media)[0] === item.Media1))[0];
            return [
              {
                value: String(item.Media1),
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: contrBudVal / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutBudVal,
                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutBudPrVal / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                style: {
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: CPPVal,
                style: {
                  numFmt: '0',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: ReachVal[0] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[1] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[2] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[3] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[4] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[5] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[6] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[7] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[8] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[9] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              // item.Media1, contrBudVal, donutBudVal, donutBudPrVal, '', '', CPPVal, ReachVal[0], ReachVal[1], ReachVal[2], ReachVal[3], ReachVal[4], ReachVal[5], ReachVal[6], ReachVal[7], ReachVal[8], ReachVal[9]
            ];
          }),
        },
      ]);
      return item;
    });

    return [DATA_dataFromXL, DATA_Sheet2_dataFromXL];
  };

  const DATA_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[0];
  const DATA_Sheet2_dataFromXL = flightsUpload && getDataForExportXLSPart1_DataFromXL()[1];

  const getDataForExportXLSPart1_DataFromChartsData = () => {
    const DATA = [];
    const DATA_Sheet2 = [];
    flightsChartsData.map(item => {
      const FLIGHT = flightArr.find(i => i.id === item.flight) && flightArr.find(i => i.id === item.flight).flight;
      const TA = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).targetAudience;
      const frequency = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).frequency;
      const knowledgeGeneral = flightsPostData.find(i => i.flight === item.flight) && flightsPostData.find(i => i.flight === item.flight).knowledgeGeneral;

      const MEDIA_MIX_COMPARE = item.chartsData.data[0].Media_mix_compare;
      const reach = MEDIA_MIX_COMPARE[4][0];
      const budget = makeSumBudget(JSON.parse(MEDIA_MIX_COMPARE[4][1]));
      const Total_reach_cprp = item.chartsData.data[0].Total_reach_cprp;

      const BUDGET = JSON.parse(item.chartsData.data[0].Budget);
      const donutMedia = Object.keys(BUDGET);
      const donutMediaGroup = makeArrayGroup(BUDGET);
      const donutGroupBudget = makeArrayBudgetGroupBar(BUDGET);
      const donutGroupBudgetPr = makeArrayBudgetPrGroupBar(BUDGET);

      const mediaPost = flightsMediaArr.find(p => p.flight === item.flight).media;
      const checkedMediaPost = mediaPost.filter(media => media.isChecked === true);
      const mainMedia = item.mainMedia;
      const CPPGroup = makeArrayCPPMedia(donutMediaGroup, checkedMediaPost, BUDGET, donutGroupBudget);
      const INCREACH = JSON.parse(item.chartsData.data[0].Incremental_reach[mainMedia]);
      const contrGroupBudget = makeArrayBudgetGroupBar(INCREACH);
      const Media_group_reach = item.chartsData.data[0].Media_group_reach;
      const Media_reach = item.chartsData.data[0].Media_reach;
      DATA.push([
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Бренд',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              title: '',
              width: { wpx: 170 },
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: 'Флайт',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: FLIGHT,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Целевая аудитория',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(TA),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный бюджет',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: budget,

                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупное знание',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: +knowledgeGeneral / 100,

                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный Frequency',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(frequency),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный охват 5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: reach / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Частота',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Совокупный охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPRP',
              font: { bold: true },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: '1+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[0][0] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][0],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '2+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][1] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][1],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '3+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][2] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][2],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '4+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][3] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][3],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][4] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][4],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '6+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][5] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][5],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '7+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][6] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][6],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '8+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][7] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][7],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '9+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][8] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][8],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '10+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][9] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][9],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Медиа',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Уникальный вклад в охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Сумма',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Доля в медиа миксе',
              width: { wpx: 130 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Frequency',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'OTS',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPT',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 1+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 2+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 3+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 4+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 5+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 6+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 7+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 8+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 9+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 10+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: donutMediaGroup.map((item, i) => {
            const contrGroupBudValue = Object.values(contrGroupBudget.find(media => Object.keys(media)[0] === item))[0][0];
            const donutGroupBudValue = Object.values(donutGroupBudget.find(media => Object.keys(media)[0] === item))[0][0];
            const donutGroupBudPrValue = Object.values(donutGroupBudgetPr.find(media => Object.keys(media)[0] === item))[0][0];
            const CPPGroupValue = Object.values(CPPGroup.find(media => Object.keys(media)[0] === item))[0][0];
            const ReachGroupVal = Media_group_reach[item];
            return [
              {
                value: String(item),
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: contrGroupBudValue / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutGroupBudValue,
                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: donutGroupBudPrValue / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                style: {
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: CPPGroupValue,
                style: {
                  numFmt: '0',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: ReachGroupVal[0] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[1] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[2] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[3] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[4] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[5] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[6] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[7] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[8] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachGroupVal[9] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              // item, contrGroupBudValue, donutGroupBudValue, donutGroupBudPrValue, '', '', CPPGroupValue, ReachGroupVal[0], ReachGroupVal[1], ReachGroupVal[2], ReachGroupVal[3], ReachGroupVal[4], ReachGroupVal[5], ReachGroupVal[6], ReachGroupVal[7], ReachGroupVal[8], ReachGroupVal[9]
            ];
          }),
        },
      ]);

      DATA_Sheet2.push([
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Бренд',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },

            {
              title: '',
              width: { wpx: 170 },
              style: {
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                font: { bold: true },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: 'Флайт',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: FLIGHT,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Целевая аудитория',
                style: {
                  width: { wpx: 120 },
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(TA),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный бюджет',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: budget,

                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  numFmt: '0₽',
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупное знание',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: +knowledgeGeneral / 100,

                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный Frequency',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: String(frequency),
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: 'Совокупный охват 5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: reach / 100,
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  numFmt: '0%',
                  font: { bold: true },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Частота',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Совокупный охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPRP',
              font: { bold: true },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: [
            [
              {
                value: '1+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[0][0] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][0],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '2+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][1] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][1],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '3+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][2] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][2],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '4+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][3] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][3],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '5+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][4] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][4],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '6+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][5] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][5],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '7+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][6] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][6],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '8+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][7] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][7],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '9+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][8] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[1][8],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
            [
              {
                value: '10+',
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: Total_reach_cprp[0][9] / 100,
                style: {
                  numFmt: '0%',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: Total_reach_cprp[1][9],
                style: {
                  numFmt: '0₽',
                  font: { bold: true },
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
            ],
          ],
        },
        {
          xSteps: 0,
          ySteps: 2,
          columns: [
            {
              title: 'Медиа',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Уникальный вклад в охват',
              width: { wpx: 170 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Сумма',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Доля в медиа миксе',
              width: { wpx: 130 },
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Frequency',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'OTS',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'CPT',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 1+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 2+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 3+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 4+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 5+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 6+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 7+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 8+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 9+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
            {
              title: 'Охват 10+',
              style: {
                font: { bold: true },
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
              },
            },
          ],
          data: donutMedia.map((item, i) => {
            const ReachVal = Media_reach[item];
            return [
              {
                value: String(item),
                style: {
                  fill: { patternType: 'solid', fgColor: { rgb: 'ccc9c9' } },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: INCREACH[item].Budget / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: BUDGET[item].Budget,
                style: {
                  numFmt: '0₽',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: BUDGET[item].BudgetPr / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                style: {
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: '',
                alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                style: {
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: mediaPost.find(media => media.media === item).CPP,
                style: {
                  numFmt: '0',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },

              {
                value: ReachVal[0] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[1] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[2] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[3] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[4] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[5] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[6] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[7] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[8] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              {
                value: ReachVal[9] / 100,
                style: {
                  numFmt: '0%',
                  alignment: { wrapText: false, horizontal: 'center', vertical: 'top' },
                  border: { bottom: { style: 'thin', color: { rgb: '000000' } }, top: { style: 'thin', color: { rgb: '000000' } }, left: { style: 'thin', color: { rgb: '000000' } }, right: { style: 'thin', color: { rgb: '000000' } } },
                },
              },
              // item, INCREACH[item].Budget, BUDGET[item].Budget, BUDGET[item].BudgetPr, '', '', mediaPost.find(media => media.media === item).CPP, ReachVal[0], ReachVal[1], ReachVal[2], ReachVal[3], ReachVal[4], ReachVal[5], ReachVal[6], ReachVal[7], ReachVal[8], ReachVal[9]
            ];
          }),
        },
      ]);
      return item;
    });

    return [DATA, DATA_Sheet2];
  };

  const DATA = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[0];
  const DATA_Sheet2 = !flightsUpload && getDataForExportXLSPart1_DataFromChartsData()[1];

  const chooseDATA = () => {
    if (flightsUpload) {
      return DATA_dataFromXL;
    } else if (!flightsUpload) {
      return DATA;
    }
  };
  const chooseDATASheet2 = () => {
    if (flightsUpload) {
      return DATA_Sheet2_dataFromXL;
    } else if (!flightsUpload) {
      return DATA_Sheet2;
    }
  };
  return (
    <div>
      <ExcelFile element={<LoadingButton title={'Excel'} id='loadingButtonExcelFile' />} filename='media_mix_calculator_charts_part1'>
        {chooseDATA().map((d, index) => {
          return (
            // eslint-disable-next-line no-useless-concat
            <ExcelSheet key={index} dataSet={d} name={`Флайт ${d[0].data[0][1].value}` + ` по группам медиа`} />
          );
        })}
        {chooseDATASheet2().map((d2, index) => {
          return (
            // eslint-disable-next-line no-useless-concat
            <ExcelSheet key={index} dataSet={d2} name={`Флайт ${d2[0].data[0][1].value}` + ` развернуто по медиа`} />
          );
        })}
      </ExcelFile>
    </div>
  );
};
