import React from 'react';
import s from './FormButton.module.css';

export const FormButton = React.memo(props => {
  const { onClick, title, buttonColor, type } = props;
  return (
    <div>
      <button type={type === 'submit' ? 'submit' : 'button'} className={buttonColor === 'red' ? s.redFormButton : s.grayFormButton} onClick={onClick}>
        {title}
      </button>
    </div>
  );
});
